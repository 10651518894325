<div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="20px">
    <div fxLayoutAlign="center center">
        <div class="circle">
            <label for="fileInputprofile">
                <mat-icon color="primary" class="size">cloud_upload</mat-icon>
            </label>
            <input class="display-none" id="fileInputprofile" type='file' (change)="onSelectFileprofie($event)">
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <label class="middle">
            <span>Upload a photo</span>
        </label>
    </div>
    <div *ngIf="this.url">
        <img src="{{this.url}}" alt="" width="100" height="100">
    </div>
    <button mat-raised-button color="primary" (click)="doAction()">Upload</button>
</div>