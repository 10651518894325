import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { BlogService } from 'src/app/shared/service/admin/blog.service';
import { CommentService } from 'src/app/shared/service/author/comment.service';
import { GetElementByIDService } from 'src/app/shared/service/author/get-element-by-id.service';
import { BlogYearMonthListService } from 'src/app/shared/service/HomePage/blog-year-month-list.service';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
import * as moment from 'moment';
import { SummaryService } from 'src/app/shared/service/author/summary.service';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { RecentArticlesService } from 'src/app/shared/service/HomePage/recent-articles.service';

@Component({
  selector: 'app-blog-preview',
  templateUrl: './blog-preview.component.html',
  styleUrls: [ './blog-preview.component.css' ]
})
export class BlogPreviewComponent implements OnInit {
  addCommentFormGroup: FormGroup;
  BlogData: any;
  blogAuthor: any;
  blogCategory: any;
  blogContent: any;
  blogCoverPhotoUrl: any;
  blogKeyWords: any;
  blogTitle: any;
  publishDate: any;
  categories: any;
  keywords: any;
  show: boolean = false;
  userinfo: any;
  post_comment = [];
  comment_content = "";
  blogId: string;
  year: any;
  month: any;
  getMonth: any;
  fullBlog: any;
  volumnandissuewisearticle: any;
  volumeIssueList: any;
  listofComment: any;
  comments: any;
  Viewcount: any;
  RecentBlogData: any;
  CategoryBlogData: any;
  blogCategoryGet: string;
  blogTypeName: any;
  blogMedia: any;
  articleId: any;
  routeId: string;
  errorShow: boolean = false;
  constructor(
    private matSnack: SnackbarService,
    private formBuilder: FormBuilder,
    private getDataService: GetElementByIDService,
    private GetBlogService: BlogService,
    private GetCommentService: CommentService,
    public router: Router, private yearWiseBlog: BlogYearMonthListService,
    private getsummaryservice: SummaryService,
    private _sanitizer: DomSanitizer,
    public route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private GetRecentArticles: RecentArticlesService
  ) { }

  ngOnInit(): void {
    // console.log(this.route.snapshot.paramMap.get('id').substring(0, 9))
    this.routeId = this.route.snapshot.paramMap.get('id').substring(0, 9)
    this.GetBlogId(this.routeId)
    // Comment formGroup 
    this.addCommentFormGroup = this.formBuilder.group({
      comment: new FormControl(''),
    })
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    // this.blogId = localStorage.getItem('blogId')
    this.blogCategoryGet = localStorage.getItem('blogCategory')
  }

  get formcontrol() {
    return this.addCommentFormGroup.controls;
  }

  GetBlogId(id) {
    this.spinner.show();
    var blogdisplayId = 'AEBMBLOG-' + id
    this.GetRecentArticles.getArticle<any>(`api/blog/GetBlogIdByPassBlogDisplayId?blogDisplayId=${blogdisplayId}`)
      .subscribe((data: any) => {
        // console.log(data)
        this.blogId = data.message
        this.getBlogData()
        // this.getYearMonthList()
        this.GetBlogCommentById()
        this.RecentBlog()
        this.CategoryBlog()
        this.getViewCount()
        this.spinner.hide();
      },
        error => {
          // prompt('article is not found')
          // console.log(error);
          this.errorShow = true
          this.spinner.hide();
        });
  }

  //Blog comment
  AddBlogComment() {
    var blogId = this.BlogData.blogId
    this.GetBlogService.AddBlogComment(blogId, this.userinfo.id, this.formcontrol.comment.value)
      .pipe(first())
      .subscribe(
        data => {
          this.matSnack.paperShowNotification('Thank you, Your comment is submitted for Review.', "")
        },
        error => {
        }
      );
    // console.log(this.comment_content)
    this.comment_content = "";
  }

  // Comment without login
  AddBlogCommentWithoutuser() {
    var blogId = this.BlogData.blogId
    this.GetBlogService.AddBlogComment(blogId, '', this.formcontrol.comment.value)
      .pipe(first())
      .subscribe(
        data => {
          // this.openSnackBar('Thank you, Your comment is submitted for Review.', '')
          this.matSnack.paperShowNotification('Thank you, Your comment is submitted for Review.', "")
        },
        error => {
        }
      );
    // console.log(this.comment_content)
    this.comment_content = "";
  }
  post() {
    this.show = true;
    this.post_comment.push(this.comment_content);
    this.comment_content = "";
  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  //Get blog data by id
  getBlogData() {
    this.spinner.show();
    // this.userinfoid = this.local_data.userId;
    this.getDataService.getDataByid<any>(`api/blog/getblogByid?bolgId=${this.blogId}`)
      .subscribe((data: any) => {
        // console.log(data)
        this.BlogData = data
        this.blogAuthor = this.BlogData.blogAuthor
        this.blogCategory = this.BlogData.blogCategory
        this.blogContent = this.BlogData.blogContent
        this.blogCoverPhotoUrl = this.BlogData.blogCoverPhotoUrl
        this.blogMedia = this.BlogData.blogMedia
        // this._sanitizer.bypassSecurityTrustHtml(`${this.BlogData.blogMedia}`)
        this.blogKeyWords = this.BlogData.blogKeyWords
        this.blogTitle = this.BlogData.blogTitle
        this.blogTypeName = this.BlogData.blogTypeName
        this.publishDate = this.BlogData.publishDate
        this.categories = this.BlogData.blogCategory.split(',')
        this.keywords = this.BlogData.blogKeyWords.split(',')
        this.spinner.hide();
      })
  }

  //Recent Blog
  RecentBlog() {
    // this.userinfoid = this.local_data.userId;
    this.getDataService.getDataByid<any>(`api/blog/RecentWiseBloglist?pageNo=1&pageSize=3`)
      .subscribe((data: any) => {
        this.RecentBlogData = data
      })
  }

  //Category Blog
  CategoryBlog() {
    // this.userinfoid = this.local_data.userId;
    this.getDataService.getDataByid<any>(`api/blog/CategoryWiseBloglist?Category=${this.blogCategoryGet}&pageNo=1&pageSize=3`)
      .subscribe((data: any) => {
        this.CategoryBlogData = data
      })
  }

  // Get Year Month List
  getYearMonthList() {
    this.yearWiseBlog.getBlogListYearWise<any>(`api/blog/Blogyearmonthlist`)
      .subscribe((data) => {
        // console.log(data)
        this.volumeIssueList = data
        this.getMonth = this.volumeIssueList.month
      });
  }
  // Mobile view for media show
  isMobile() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width < 1025;
  }
  // Onclick Show List
  itemclick(event) {
    this.show = true;
    this.year = event.year
    this.month = event.month
    this.getVolumeandissueList(this.year, this.month)
    // console.log(event,"event data")
  }

  // Blog List 
  getVolumeandissueList(year, month) {
    var monthnumber = moment().month(month).format("M")
    this.yearWiseBlog.getBlogList<any>(`api/blog/yearmonthWiseBloglist?year=${year}&month=${monthnumber}&pageNo=1&pageSize=3`)
      .subscribe((data) => {
        this.fullBlog = data
        // console.log(this.volumnandissuewisearticle,"Article List")
      }
      );
  }

  //Comment By id
  GetBlogCommentById() {
    var articleId = localStorage.getItem('blogId')
    this.GetCommentService.getCommentById<Comment>(`api/blog/BlogAcceptedCommentlist?BlogId=${this.blogId}`)
      .subscribe((data: Comment[]) => {
        this.listofComment = data as Comment[]
        this.comments = this.listofComment.blogAcceptedList
      })
  }

  // onClick Preview Blog
  BlogPreview(element) {
    localStorage.setItem('blogId', element.blogId)
    var routeId = element.blogDisplayId.substring(9) + '-' + element.blogTitle.replace(/\s+/g, '-')
    const host: string = location.origin;
    const url: string = host + '/#' + String(this.router.createUrlTree([ '/blogs', `${routeId}` ], { state: element }));
    window.open(url, '_blank');
  }


  // Get Count
  getViewCount() {
    var blogId = localStorage.getItem('blogId')
    this.getsummaryservice.getArticle<any>(`api/blog/GetBlogViews?blogId=${blogId}`)
      .subscribe((data) => {
        this.Viewcount = data
      }
      );

  }
  // Categories Blog
  getCategoryBlog(item) {
    this.router.navigateByUrl('/blog', { state: item });
    localStorage.setItem('blogTypecategory', item)
    // localStorage.setItem('totalNumberOfArticle', this.categoriesbydata[ 0 ].totalNumberOfArticle)
    localStorage.removeItem('blogTypekeyword')
  }

  // Keywords Blog
  getKeywordBlog(item) {
    this.router.navigateByUrl('/blog', { state: item });
    localStorage.setItem('searchValueinhome', item)
    // localStorage.setItem('totalNumberOfArticle', this.articlebydata[ 0 ].totalNumberOfArticle)
    localStorage.removeItem('blogTypecategory')
  }

  GetArticleId(id) {
    var manuscriptId = 'AEBM-' + id
    this.getsummaryservice.getArticle<any>(`api/Article/GetArticleIdByPassMenuscript?Manuscriptid=${manuscriptId}`)
      .subscribe((data: any) => {
        this.articleId = data.message
        // localStorage.setItem('articleId', data.message)
      })
  }
}
