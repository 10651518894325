<div fxLayoutAlign="center center">
    <p class="title" >Sent Invoice</p>
</div>
<div class="primary-font form-field">
    <form  fxLayout="column"  fxLayoutGap="20px">
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <div fxFlex="5">
                Bcc:
            </div>
            <div fxFlex="95">
              <mat-form-field class="example-chip-list">
                <mat-chip-list #chipList>
                  <mat-chip *ngFor="let keyword of keywords" [selectable]="selectable"
                           [removable]="removable" (removed)="remove(keyword)">
                    {{keyword}}
                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                  </mat-chip>
                  <input placeholder="Enter Email"
                         [matChipInputFor]="chipList"
                         [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                         [matChipInputAddOnBlur]="addOnBlur"
                         (matChipInputTokenEnd)="add($event)" />
                </mat-chip-list>
              </mat-form-field>
            </div>
        </div>

        <!-- Content -->
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <div fxFlex="5" fxHide>
              Invoice:
          </div>
          <div fxFlex="95"></div>
        </div>

        <!-- Button -->
        <div fxLayoutAlign="end end" >
          <button mat-raised-button [mat-dialog-close]="true" color="primary" (click)="SendMail()">Send Invoice</button>
        </div>
    </form>
</div>
