<div class="background padding primary-font">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <div class="card-width" fxLayout="column" fxLayoutGap="10px" fxFlex.lt-md="100%">
            <div class="title" fxLayout="row" fxLayoutAlign="center center">
                <span class="mat-headline title">Edit Comment</span>
            </div>
            <div fxLayout="column" fxLayoutGap="15px">
                <!-- Title -->
                <div fxLayout="row" fxLayoutGap="10px">
                    <p class="color-primary ">Title: </p>
                    <p>{{this.title}}</p>
                </div>
                <form [formGroup]="editCommentForm">
                    <div fxLayout="column" fxLayoutGap="10px">
                        <!-- Comment -->
                        <mat-form-field appearance="outline" class="height example-full-width">
                            <mat-label>Comment </mat-label>
                            <textarea matInput formControlName="comment" [(ngModel)]="this.comment"></textarea>
                        </mat-form-field>
                    </div>
                </form>
                <!-- Button -->
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                    <button color="primary" mat-raised-button mat-dialog-close (click)="changeComment()">Submit</button>
                    <button color="warn" mat-raised-button mat-dialog-close>Cancel</button>
                </div>
            </div>
        </div>
    </div>
</div>