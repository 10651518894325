import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { author } from '../class/index';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { delay } from 'rxjs/operators';

const baseUrl = `http://localhost:3000/authors/`;

@Injectable({ providedIn: 'root' })
export class authorService {
    dialogTitle: string;
  dialogText: string;
    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<author[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<author>(`${baseUrl}/${id}`);
    }
    create(params: any) {
        return this.http.post(baseUrl, params);
    }

    update(id: string, params: any) {
        return this.http.put(`${baseUrl}/${id}`, params);
    }

    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`);
    }
}