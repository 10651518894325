import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  _apiEndPoint: string = '';

  constructor(private _http: HttpClient,) {
    this._apiEndPoint = environment.Url;
  }

  //Add Blog Api
  AddBlog(formdata) {

    return this._http.post<any>(`${environment.apiUrl}/api/blog/createblog`,
      formdata

    )
      .pipe(map(res => {
        return res;
      }));
  }
  // Get Blog Type Api
  getBlogType() {
    return this._http.get(`${environment.apiUrl}/api/blog/BlogTypeList`);
  }

  UpdateBlog(formdata) {

    return this._http.post<any>(`${environment.apiUrl}/api/blog/updateblog`,
      formdata

    )
      .pipe(map(res => {
        return res;
      }));
  }

  //Get Blog list api
  getBlogList<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`,)
  }
  // Delete Blog
  deleteBlog(BlogId: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/blog/deleteBlog`, {
      BlogId

    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Add Blog Comment Api
  AddBlogComment(BlogId: string, BlogAuthorId: string, BlogComment: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/blog/AddBlogComment`, {
      BlogId,
      BlogAuthorId,
      BlogComment

    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Update Article Comment Api
  UpdateArticleComment(ArticleCommentId: string, Comment: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/Article/UpdateCommentByManagingEditor`, {
      ArticleCommentId,
      Comment,

    })
      .pipe(map(res => {
        return res;
      }));
  }
  //Update Blog Comment Api
  UpdateBlogComment(BlodCommentId: string, BlogAuthorId: string, BlogComment: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/blog/UpdateBlogComment`, {
      BlodCommentId,
      BlogAuthorId,
      BlogComment

    })
      .pipe(map(res => {
        return res;
      }));
  }

  //Get Blog list api
  getBlogCommentList<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`,)
  }

  ApproveBlogComment(BlogCommentId: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/blog/BlogCommentAccept`, {
      BlogCommentId

    })
      .pipe(map(res => {
        return res;
      }));
  }

  rejectBlogComment(BlogCommentId: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/blog/BlogCommentreject`, {
      BlogCommentId

    })
      .pipe(map(res => {
        return res;
      }));
  }

  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

}
