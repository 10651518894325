<!-- Sidenav List -->

<section class="padding-left">
  <mat-nav-list>
    <mat-action-list>
      <a mat-list-item routerLink="/submission/Gettingstarted" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">Welcome </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/articletype" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active"> Article Type </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/categories" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">Categories </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/title" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">
        Study Title </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/coverletter" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active"> Cover Letter </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/highlights" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">
        Highlights </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/abstract" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">
        Abstract </a>
    </mat-action-list>



    <mat-action-list>
      <a mat-list-item routerLink="/submission/keywords" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">Keywords</a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/authors" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">Authors</a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/article" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active">Article</a>
    </mat-action-list>

    <!-- <mat-action-list>
    <button id="bt1" mat-list-item (click)="showFiller2 = !showFiller2" routerLinkActive="is-active">
      Article
    </button>
    <mat-action-list *ngIf="showFiller2">
      <a mat-list-item class="sub-display" routerLink="/articleIntro" class="mat-h3 color-hover color-black btn " routerLinkActive="is-active"> Introduction</a>
      <a mat-list-item class="sub-display" routerLink="/method" class="mat-h3 color-hover color-black btn " routerLinkActive="is-active"> Methods</a>
      <a mat-list-item class="sub-display" routerLink="/result" class="mat-h3 color-hover color-black btn " routerLinkActive="is-active"> Results</a>
      <a mat-list-item class="sub-display" routerLink="/discussion" class="mat-h3 color-hover color-black btn " routerLinkActive="is-active"> Discussion</a>
      <a mat-list-item class="sub-display" routerLink="/conclusion" class="mat-h3 color-hover color-black btn " routerLinkActive="is-active"> Conclusion</a>
      <a mat-list-item class="sub-display" routerLink="/appendices" class="mat-h3 color-hover color-black btn" routerLinkActive="is-active"> Supplementary Material </a>
    </mat-action-list>
  </mat-action-list> -->

    <mat-action-list>
      <a mat-list-item routerLink="/submission/references" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active"> References </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/supplementary-material" class="mat-h3 color-hover color-black btn "
        routerLinkActive="is-active"> Supplementary Material </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/acknowledgement" class="mat-h3 color-hover color-black btn"
        routerLinkActive="is-active"> Acknowledgement </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/ethicstatement" class="mat-h3 color-hover color-black btn"
        routerLinkActive="is-active"> Ethics Statement </a>
    </mat-action-list>

    
    <mat-action-list>
      <a mat-list-item routerLink="/submission/disclosure" class="mat-h3 color-hover color-black btn"
        routerLinkActive="is-active">
        COI Disclosure </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/media" class="mat-h3 color-hover color-black btn"
        routerLinkActive="is-active">
        Media/SEO </a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item routerLink="/submission/summary" class="mat-h3 color-hover color-black btn"
        routerLinkActive="is-active">Summary</a>
    </mat-action-list>

    <mat-action-list>
      <a mat-list-item class="color-hover color-black btn" routerLink="/submission/submit"
        class="mat-h3 color-hover color-black btn " routerLinkActive="is-active">Submit</a>
    </mat-action-list>


    

  </mat-nav-list>
</section>