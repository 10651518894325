import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CountdownComponent } from 'ngx-countdown';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../service/author/authentication.service';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: [ './otp-verify.component.css' ]
})
export class OtpVerifyComponent implements OnInit {

  ForgotpasswordFormGroup: FormGroup;
  hide = true;
  error: any;
  otp: string;
  show = true;
  selectedValue: number;
  @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  config = {
    allowNumbersOnly: true,
    length: 5,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      'width': '50px',
      'height': '50px'
    }
  };
  @ViewChild('countdown') counter: CountdownComponent;
  email: string;
  userId: string;

  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private router: Router, private snackBar: MatSnackBar
  ) { }
  // openSnackBar(message: string, action: string) {
  //   this.snackBar.open(message, action, {
  //     duration: 5000,
  //     horizontalPosition: 'center',
  //     verticalPosition: 'top',
  //     panelClass: [ 'redNoMatch' ]

  //   });
  // }
  //otp right snackbar
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [ 'green-snackbar' ]
    });
  }

  //otp wrong snackbar
  opennotSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [ 'red-snackbar' ]
    });
  }
  ngOnInit(): void {
    this.ForgotpasswordFormGroup = this.formBuilder.group({
      email: new FormControl('', Validators.required),
    });
    this.email = localStorage.getItem('forgot-email')
    this.userId = localStorage.getItem('forgotuserid')
  }

  onOtpChange(otp) {
    this.otp = otp;
    if (this.otp.length === 5) {

      this.authenticationService.otpVerify(this.email, this.otp)

        .pipe(first())
        .subscribe(
          data => {
            if (data === true) {
              this.router.navigateByUrl('/pages/changepassword');
              this.openSnackBar('OTP Verify Successfully', '')
            }
            else {
              this.opennotSnackBar("That OTP code isn't valid. You can resend a new one", '')
            }
          },
          error => {
            // this.error = error.error.message;
            // console.log(this.error)
          });

    }
  }

  onEvent($event): void {
    let timeLeft = $event.left;
    this.selectedValue = timeLeft
    if (timeLeft == 0) {
      this.show = false
    }
  }

  public Send() {
    this.counter.restart();
    this.authenticationService.sendOtp(this.email, this.userId)

      .pipe(first())
      .subscribe(
        data => {

        },
        error => {
        });
  }
}
