import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Citation } from '../../model/citation';

@Injectable({
  providedIn: 'root'
})
export class CitationService {
  _apiEndPoint: string = '';
  constructor(private _http: HttpClient) {
    this._apiEndPoint = environment.Url;
  }

  AddCitation(formdata) {

    return this._http.post<Citation>(`${environment.apiUrl}/api/Article/AddCitation`,
      formdata

    )
      .pipe(map(res => {
        return res;
      }));
  }

  getCitation<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  AddFooter(articleId:string,footer:string) {
    return this._http.post<Citation>(`${environment.apiUrl}/api/Article/AddArticleFooter`,{
    articleId,
    footer
  })
      .pipe(map(res => {
        return res;
      }));
  }

  getFooter<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }
}
