<!-- <app-header (toggleSideBarForMe)="sideBarToggler($event)"></app-header>
<mat-drawer-container class="content"  [ngClass.lt-md]="'content2'" [ngClass.lt-sm]="'content1'" >


    <mat-drawer-content>
        <router-outlet></router-outlet>
    </mat-drawer-content>

</mat-drawer-container> -->
<app-responsive-header></app-responsive-header>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="primary" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>