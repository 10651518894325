import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SuggestReviewer } from '../../model/suggest-reviewer';

@Injectable({
  providedIn: 'root'
})
export class SuggestReviewerService {

  _apiEndPoint: string = '';

  constructor(private _http: HttpClient) {
    this._apiEndPoint = environment.Url;
  }

  suggestReviewer(Reason:string,SuggestedReviewerDetails:string) {
    return this._http.post<SuggestReviewer>(`${environment.apiUrl}/api/registration/SuggestedReviewer`, {
      Reason,
      SuggestedReviewerDetails

    })
      .pipe(map(res => {
        return res;
      }));
  }
}
