import { animate, state, style, transition, trigger } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, HostListener, Inject, OnInit, Output } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, map, pairwise, takeUntil, throttleTime } from 'rxjs/operators';

enum VisibilityState {
    Visible = 'visible',
    Hidden = 'hidden'
}

enum Direction {
    Up = 'Up',
    Down = 'Down',
    Original = 'Original',
    None = 'None'
}

@Component({
    selector: 'app-jump-to-top',
    templateUrl: './jump-to-top.component.html',
    styleUrls: [ './jump-to-top.component.css' ],
    animations: [
        trigger('scrollAnimation', [
            state(VisibilityState.Visible, style({
                transform: 'translateY(0)', opacity: 1
            })),
            state(VisibilityState.Hidden, style({
                transform: 'translateY(0)', opacity: 0 // adjust this to the height of your header
            })),
            transition(`${VisibilityState.Visible} => ${VisibilityState.Hidden}`, animate('500ms')),
            transition(`${VisibilityState.Hidden} => ${VisibilityState.Visible}`, animate('250ms'))
        ])
    ]

})
export class JumpToTopComponent implements OnInit {
    selectedIndex: number = 0;
    private destroy$: Subject<boolean> = new Subject<boolean>();
    isHeader2Visible = VisibilityState.Hidden;
    slideHeader2InAtPosition = 200;
    slideHeaderInAtPosition = 30;
    public handleAnchorClick(event) {
        // Prevent opening anchors the default way
        // console.log(event.target.innerHTML.split(''))

        event.preventDefault();
        const anchor = event.target
        // alert(`You are trying to navigate to ${anchor.href}`);
        if (anchor.innerHTML.split('')[ 0 ] == '[') {
            this.selectedIndex = 1
            this.scrollToTop()
        }
    }
    ngOnInit() {
    }
    scrollToTop() {

        // console.log('test')
        document.getElementById('top').scrollIntoView({ behavior: 'smooth' });
    }
    ngAfterViewInit() {
        //  Scrolls to top of Page after page view initialized
        let top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }

        const content = document.querySelector('.content-router');
        const scroll$ = fromEvent(content, 'scroll').pipe( // if the scroll events happen on your window you could use 'window' instead of 'content' here
            throttleTime(10),
            map(() => content.scrollTop), // if you used 'window' above replace 'content.scrollTop' with 'window.pageYOffset'
            pairwise(),
            map(([ y1, y2 ]): Direction => {
                // console.log(this.slideHeader2InAtPosition);
                return (y2 <= this.slideHeader2InAtPosition ? Direction.Up : (y2 > this.slideHeaderInAtPosition ? Direction.Down : Direction.None));
            }),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        );

        // subscribe to the UP / DOWN scroll direction stream and set the header state accordingly
        scroll$.subscribe(dir => {
            if (dir === Direction.Down) {
                this.isHeader2Visible = VisibilityState.Visible;
            } else {
                this.isHeader2Visible = VisibilityState.Hidden;
            }
        });
    }
}