<app-sticky-shared></app-sticky-shared>
<app-jump-to-top></app-jump-to-top>

<div class="primary-font" class="padding-left" [ngClass.lt-md]="'padding-top-sm'" id="top" fxLayout="row"
  *ngIf="this.errorShow == false">
  <div fxFlex.gt-sm="71" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-around stretch">
    <div class="article-minwidth ">
      <mat-tab-group>
        <div>
          <mat-tab label="Article">
            <div id="article_download" #article_download class="left-content">
              <div class="content-padding" fxLayout="column" fxLayoutGap="1%" fxLayout.lt-sm="column"
                fxLayoutAlign.lt-sm="space-around stretch">
                <!-- volume and Issue -->
                <div fxLayout="row" fxLayoutGap="1%">
                  <div class="color-primary primary-font">
                    {{this.Volume}} {{this.volumearticle}}
                  </div>
                  <div class="color-primary primary-font">
                    |
                  </div>
                  <div class="color-primary primary-font">
                    {{this.Issue}} {{this.issuearticle}}
                  </div>
                </div>

                <!-- Date and views -->
                <div fxLayout="row" fxLayoutGap="1%" class="primary-font">
                  <div>
                    <span>Published Date: </span> <span *ngIf="this.userinfo">{{this.articlePublishDate| date: 'MMM d,
                      y'}}</span>
                    <span *ngIf="!this.userinfo">{{this.articlePublishDate| date: 'MMM d, y'}}</span>
                  </div>
                  <div>
                    |
                  </div>
                  <div>
                    <span>{{Views}} </span><span *ngIf="this.userinfo">{{this.Viewcount}}</span>
                    <span *ngIf="!this.userinfo">{{this.Viewcount}}</span>
                  </div>
                  <div>
                    |
                  </div>
                  <div>
                    <span>Downloads: </span><span *ngIf="this.userinfo">{{this.totaldownload}}</span>
                    <span *ngIf="!this.userinfo">{{this.totaldownload}}</span>
                  </div>
                </div>

                <!-- article Type -->
                <div class="color-primary primary-font">
                  <b>{{articletypesummary}}</b>
                </div>

                <!-- Article Title -->
                <div>
                  <p class="mat-h2 article-title text-justify">
                    {{this.articleStudytitlelist}}
                  </p>
                </div>

                <!-- Author Details -->
                <div *ngIf="articleAuthorList!=null">
                  <div class="primary-font">
                    <span class="ow" *ngFor='let author of articleAuthorList ; let i=index;last as isLast;'>
                      <span>{{author.firstName|titlecase}} {{author.lastName|titlecase}}<sup>{{i+1}}</sup>
                        <span *ngIf="!isLast">,</span></span>&nbsp;</span>
                  </div>
                </div>

                <!-- Notes -->
                <div class="note">
                  <p>{{this.authorNotesdata}}</p>
                </div>

                <!-- Author Affiliation -->
                <div>
                  <mat-accordion>
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                      <mat-expansion-panel-header class="mat-expansion-toggle-indicator-before">
                        <mat-panel-title class="color-primary">
                          <p class="color-primary ">{{AuthorAffiliations}}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div fxLayout="column" class="full-padding"
                        *ngFor='let author of articleAuthorList; let i=index;'>
                        <div fxLayout="row" fxLayoutGap="5px" class="primary-font">
                          <p><sup><b>{{i+1}}</b></sup></p>
                          <div fxLayout="column">
                            <div fxLayout="row" fxLayoutGap="4px">
                              <div>
                                {{author.firstName|titlecase}} {{author.lastName|titlecase}}, {{author.degree}}
                              </div>
                              <div fxLayout="row" class="orcid-image cursor-poiner" *ngIf="author.orchidId !=null">
                                <img src="assets/images/orcid.logo.icon.svg" alt=""
                                  (click)="redirectOrcid(author.orchidId)">
                              </div>
                            </div>
                            <div>{{author.department}},
                              {{author.institute}},
                              {{author.city}}, {{author.state}}, {{author.country}}
                            </div>
                            <div fxHide>
                              {{author.email}}
                            </div>
                          </div>
                        </div>
                      </div>
                      <mat-divider></mat-divider>
                      <div class="full-padding1 primary-font" fxLayout="column" *ngIf="this.coFirstname">
                        <b>{{CorrespondingAuthor}}</b>
                        <div fxLayout="column">
                          <div>
                            {{this.coFirstname|titlecase }} {{this.coLastName|titlecase}}, {{this.coDegree}}
                          </div>
                          <div>
                            {{this.coDepartment}}, {{this.coInstitute}},
                            {{this.coCity|titlecase}}, {{this.coState|titlecase}}, {{this.coCountry|titlecase}}
                          </div>
                          <div class="primary-font">
                            {{this.coEmail}}
                          </div>
                        </div>
                      </div>
                      <div *ngIf="this.conceptualizations||this.methodologys||this.softwares||this.validations
                            ||this.analysiss||this.investigations||this.resources||this.curations||
                            this.drafts||this.reviews||this.visualizations||this.supervisions||
                            this.administrations||this.fundings!=null">
                        <mat-divider></mat-divider>
                        <!-- <div class="full-padding1" fxLayout="column" *ngIf="this.coFirstname"> -->
                        <div class="full-padding1 primary-font" fxLayout="column">
                          <b>Author Contributions</b>
                          <p class="nospace-around " *ngIf="this.conceptualizations != null">Conceptualization:
                            {{this.conceptualizations}}</p>
                          <p class="nospace-around " *ngIf="this.methodologys != null">Methodology:
                            {{this.methodologys}}</p>
                          <p class="nospace-around " *ngIf="this.softwares != null">Software: {{this.softwares}}</p>
                          <p class="nospace-around " *ngIf="this.validations != null">Validation: {{this.validations}}
                          </p>
                          <p class="nospace-around " *ngIf="this.analysiss != null">Formal Analysis: {{this.analysiss}}
                          </p>
                          <p class="nospace-around " *ngIf="this.investigations != null">Investigation:
                            {{this.investigations}}</p>
                          <p class="nospace-around " *ngIf="this.resources != null">Resources: {{this.resources}}</p>
                          <p class="nospace-around " *ngIf="this.curations != null">Data Curation: {{this.curations}}
                          </p>
                          <p class="nospace-around " *ngIf="this.drafts != null">Writing - Original Draft Preparation:
                            {{this.drafts}}</p>
                          <p class="nospace-around " *ngIf="this.reviews != null">Writing – Preview and Editing:
                            {{this.reviews}}</p>
                          <p class="nospace-around " *ngIf="this.visualizations != null">Visualization:
                            {{this.visualizations}}</p>
                          <p class="nospace-around " *ngIf="this.supervisions != null">Supervision:
                            {{this.supervisions}}</p>
                          <p class="nospace-around " *ngIf="this.administrations != null">Project Administration:
                            {{this.administrations}}</p>
                          <p class="nospace-around " *ngIf="this.fundings != null">Funding Acquisition:
                            {{this.fundings}}</p>
                        </div>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <!-- Article Information/citation -->
                <div *ngIf="Admin" class="primary-font">
                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header [expandedHeight]="customCollapsedHeight"
                        class="mat-expansion-toggle-indicator-before">
                        <mat-panel-title>
                          <p class="color-primary">{{Citeas}}</p>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <div fxLayout="row" class="primary-font" fxLayoutAlign="end start" class="nospace-around">
                        <div>
                          <button mat-button color="primary" (click)="citationenable()">EDIT</button>
                        </div>
                        <div>
                          <button mat-button color="primary" (click)="addCitation()">SAVE</button>
                        </div>
                      </div>
                      <form [formGroup]="editorForm" class="nospace-around" class="widthSet">
                      </form>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>

                <!-- Cite AS -->
                <div fxLayoutGap="5px" class="content-padding" *ngIf="!Admin ">
                  <div>
                    <mat-accordion>
                      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header class="mat-expansion-toggle-indicator-before">
                          <mat-panel-title class="color-primary">
                            <p class="color-primary">Article Information</p>
                          </mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="full-padding">
                          <p [innerHTML]="this.citation">
                          </p>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>

                <!-- Highlights -->
                <div class="padding-top1" id="highlights" #highlights>
                  <h2 class="article-title color-primary">
                    {{Highlights}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div>
                    <div class="nospace-around primary-font">
                      <div class="nospace-around">
                        <b>{{Highlight1}}</b>
                        <p class="nospace-around text-justify primary-font" [ngClass.lt-sm]="'text-justify margin-left'"
                          [innerHTML]="this.articleHighlightsprior"></p>
                      </div>
                      <div class="nospace-around">
                        <b class="nospace-around"></b>
                        <b class="nospace-around">{{Highlight2}}</b>
                        <p class="nospace-around primary-font text-justify" [ngClass.lt-sm]="'text-justify margin-left'"
                          [innerHTML]="this.articleHighlightsfinding"></p>
                      </div>
                      <div class="nospace-around">
                        <b class="nospace-around">{{Highlight3}}</b>
                        <p class="nospace-around primary-font text-justify" [ngClass.lt-sm]="'text-justify margin-left'"
                          [innerHTML]="this.articleHighlightspracticle">
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Abstract -->
                <div class="padding-top1" id="Abstract" #Abstract>
                  <h2 class="article-title color-primary primary-font">
                    {{abstract}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div class="text-justify primary-font" [innerHTML]="this.articleAbstractlist"></div>
                  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px" class="keywords"
                    fxLayout.lt-sm="column" fxLayoutAlign.lt-sm="space-around stretch">
                    <!-- Keywords -->
                    <b class="color-primary nospace-around primary-font">{{keywords}} </b>
                    <mat-chip-list>
                      <span *ngFor="let item of this.key;let i = index">
                        <mat-chip color="primary" class=" mat-chips" (click)="getKeywordArticle(item)">
                          {{item  |titlecase }}
                        </mat-chip>
                      </span>
                    </mat-chip-list>
                  </div>
                </div>

                <!-- Article -->
                <div class="padding-top1" id="Article" #Article>
                  <h2 class="article-title color-primary">
                    {{article}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div class="text-justify  link-color primary-font" [innerHTML]="content">
                  </div>
                </div>

                <!-- References -->
                <div class="padding-top1" id="References" #References>
                  <h2 class="article-title color-primary">
                    {{references}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div class="nospace-around text-justify link-color primary-font" [ngClass.lt-sm]="'text-justify '"
                    [innerHTML]="this.articleReferencelist"></div>
                </div>

                <!-- Supplementary Material -->
                <div class="padding-top1" id="SM" #SM>
                  <h2 class="article-title color-primary">
                    {{supllementaryMaterial}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div fxLayout="column" fxLayoutGap="10px">
                    <!-- <p>Applicable:</p> -->
                    <div fxLayout="column" fxLayoutGap="10px" class="padding-right" *ngIf="this.publications!=''">
                      <div *ngFor="let item of this.publications">
                        <div
                          *ngIf="item.docType == 'image/jpeg' || item.docType == 'image/jpg'|| item.docType == 'image/png'"
                          fxLayout="row" fxLayoutGap="10px">
                          <img [src]="item.filePath" height="20" width="20" alt=""
                            (click)="downloadDocument(item.filePath)">
                          <p class="color-primary" (click)="Documentopen(item.filePath)">
                            {{item.fileName}}
                          </p>
                        </div>
                        <div *ngIf="item.docType == 'application/pdf'" fxLayout="row" fxLayoutGap="10px">
                          <img src='/assets/images/home/Adobe_Reader.png' height="20" width="20" alt=""
                            (click)="downloadDocument(item.filePath)">
                          <p class="color-primary" (click)="Documentopen(item.filePath)">
                            {{item.fileName}}
                          </p>
                        </div>
                        <div
                          *ngIf="item.docType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.docType == 'application/vnd.ms-excel'"
                          fxLayout="row" fxLayoutGap="10px">
                          <img src='/assets/images/home/excelicon.png' height="20" width="20" alt=""
                            (click)="downloadDocument(item.filePath)">
                          <p class="color-primary" (click)="Documentopen(item.filePath)">
                            {{item.fileName}}
                          </p>
                        </div>
                        <div
                          *ngIf="item.docType != 'application/pdf' && item.docType != 'image/jpeg' && item.docType != 'image/jpg'&& item.docType != 'image/png' && item.docType != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && item.docType != 'application/vnd.ms-excel'"
                          fxLayout="row" fxLayoutGap="10px">
                          <img src='/assets/images/home/download.jpg' height="20" width="20" alt=""
                            (click)="downloadDocument(item.filePath)">
                          <p class="color-primary" (click)="Documentopen(item.filePath)">
                            {{item.fileName}}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="column" *ngIf="this.publications==''">
                      <p class="nospace-around primary-font">None</p>
                    </div>
                  </div>
                </div>

                <!-- Acknowledgement -->
                <div class="padding-top1" id="Acknowledgement" #Acknowledgement>
                  <h2 class="article-title color-primary">
                    {{acknowledgement}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div class="nospace-around">
                    <div *ngIf="this.isAcknowledgement=='No'">
                      <p class="primary-font">{{none}}</p>
                    </div>
                  </div>
                  <div class="nospace-around text-justify ">
                    <div *ngIf="this.isAcknowledgement=='Yes' || this.isAcknowledgement=='yes'">
                      {{this.articleAcknowledgentData}}
                    </div>
                  </div>
                </div>

                <!-- Ethics Statement -->
                <div class="padding-top1" id="EthicsStatement" #EthicsStatement>
                  <h2 class="article-title color-primary primary-font">
                    {{ethicsStatement}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div fxLayout="column" fxLayoutGap="20px" fxLayout.lt-sm="column"
                    fxLayoutAlign.lt-sm="space-around stretch">
                    <div class="nospace-around" fxLayout="column" *ngIf="this.isHumanSubject=='Yes'">
                      <p class="nospace-around primary-font"><b>{{humanSubject}}</b></p>
                      <div fxLayout="row" fxLayoutGap="5px" fxLayout.lt-sm="column"
                        fxLayoutAlign.lt-sm="space-around stretch">
                        <p>{{protocolNumber}}: </p>
                        <p>{{this.articleEthicsStatementReviewBoard}}</p>
                      </div>
                      <div fxLayout="row" fxLayoutGap="5px" fxLayout.lt-sm="column"
                        fxLayoutAlign.lt-sm="space-around stretch">
                        <p>{{approvalNumber}}: </p>
                        <p>{{this.articleEthicsStatementA_Number}}</p>
                      </div>
                      <div fxLayout="row" fxLayoutGap="5px" fxLayout.lt-sm="column"
                        fxLayoutAlign.lt-sm="space-around stretch">
                        <p>{{statement}}: </p>
                        <p>{{this.articleEthicsStatementstate}}</p>
                      </div>
                    </div>
                    <div fxLayout="column" *ngIf="this.isHumanSubject=='No'">
                      <p class="nospace-around primary-font"><b>{{humanSubject}} </b></p>
                      <p class="nospace-around primary-font">{{notApplicable}}</p>
                    </div>
                    <div fxLayout="column" *ngIf="this.isAnimalSubject=='No'">
                      <p class="nospace-around primary-font"><b>{{animalSUbject}} </b></p>
                      <p class="nospace-around primary-font">{{notApplicable}}</p>
                    </div>
                    <div class="nospace-around" *ngIf="this.isAnimalSubject=='Yes'">
                      <p class="nospace-around primary-font"><b>{{animalSUbject}} </b></p>
                      <div fxLayout="row" fxLayoutGap="5px">
                        <p>{{protocolNumber}} </p>
                        <p>{{this.articleEthicsStatementProtocol}}</p>
                      </div>
                      <div fxLayout="row" fxLayoutGap="5px">
                        <p>{{approvalNumber}}: </p>
                        <p>{{this.articleEthicsStatementapprovalNumber}}</p>
                      </div>
                      <div fxLayout="row" fxLayoutGap="5px">
                        <p>{{loclAnimalComitee}}: </p>
                        <p>{{this.articleEthicsStatementlocalAnimalCommitee}}</p>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- COI -->
                <div class="padding-top1" id="COI" #COI>
                  <h2 class="article-title color-primary primary-font">
                    {{coi}}
                    <mat-divider></mat-divider>
                  </h2>
                  <div fxLayout="column" fxLayoutGap="20px">
                    <div fxLayout="column" *ngIf="this.responsibility!=''">
                      <p><b>{{coiresponsibility}} </b>
                      </p>
                      <div fxLayout="column" fxLayoutGap="10px" class="padding-right">
                        <div *ngFor="let item of this.responsibility">
                          <div
                            *ngIf="item.docType == 'image/jpeg' || item.docType == 'image/jpg'|| item.docType == 'image/png'"
                            fxLayout="row" fxLayoutGap="10px">
                            <img [src]="item.filePath" height="20" width="20" alt=""
                              (click)="downloadDocument(item.filePath)">
                            <p class="color-primary" (click)="Documentopen(item.filePath)">
                              {{item.fileName}}
                            </p>
                          </div>
                          <div *ngIf="item.docType == 'application/pdf'" fxLayout="row" fxLayoutGap="10px">
                            <img src='/assets/images/home/Adobe_Reader.png' height="20" width="20" alt=""
                              (click)="downloadDocument(item.filePath)">
                            <p class="color-primary" (click)="Documentopen(item.filePath)">
                              {{item.fileName}}
                            </p>
                          </div>
                          <div
                            *ngIf="item.docType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || item.docType == 'application/vnd.ms-excel'"
                            fxLayout="row" fxLayoutGap="10px">
                            <img src='/assets/images/home/excelicon.png' height="20" width="20" alt=""
                              (click)="downloadDocument(item.filePath)">
                            <p class="color-primary" (click)="Documentopen(item.filePath)">
                              {{item.fileName}}
                            </p>
                          </div>
                          <div
                            *ngIf="item.docType != 'application/pdf' && item.docType != 'image/jpeg' && item.docType != 'image/jpg'&& item.docType != 'image/png' && item.docType != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && item.docType != 'application/vnd.ms-excel'"
                            fxLayout="row" fxLayoutGap="10px">
                            <img src='/assets/images/home/download.jpg' height="20" width="20" alt=""
                              (click)="downloadDocument(item.filePath)">
                            <p class="color-primary" (click)="Documentopen(item.filePath)">
                              {{item.fileName}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="nospace-around primary-font" *ngIf="this.responsibility==''" fxLayout="column">
                      <b class="nospace-around ">{{coiresponsibility}} </b> {{notApplicable}}
                    </div>

                    <div fxLayout="column" class="nospace-around"
                      *ngIf="this.isPayment=='yes' || this.isPayment == 'Yes'" fxLayout="column">
                      <b class="nospace-around">{{coipayment}}</b>
                      {{this.articleCoipaymentData}}
                      <div *ngIf="this.isAnimalSubject=='No'">
                        <p class="nospace-around primary-font"><b>{{animalSUbject}} </b></p>
                        <p class="nospace-around primary-font">{{notApplicable}}</p>
                      </div>
                    </div>
                    <div class="nospace-around" *ngIf="this.isPayment=='No'" fxLayout="column">
                      <p class="nospace-around primary-font"><b>{{coipayment}}</b></p>
                      <p class="nospace-around primary-font">{{notApplicable}}</p>
                    </div>
                    <div class="nospace-around" *ngIf="this.isFAavailable=='Yes'" fxLayout="column">
                      <b class="nospace-around primary-font">{{coifinancial}}</b>
                      {{this.articleCoifinancialActivity}}
                    </div>
                    <div class="nospace-around" *ngIf="this.isFAavailable=='No'" fxLayout="column">
                      <p class="nospace-around primary-font"><b>{{coifinancial}}</b></p>
                      <p class="nospace-around primary-font">{{notApplicable}}</p>
                    </div>
                    <div class="nospace-around primary-font"
                      *ngIf="this.isORavailable=='Yes' ||this.isORavailable=='yes' " fxLayout="column">
                      <b class="nospace-around primary-font">{{coiother}}</b>
                      {{this.articleCoiOtherRelationships}}
                    </div>
                    <div class="nospace-around" *ngIf="this.isORavailable=='No'">
                      <p class="nospace-around primary-font"><b>{{coiother}}</b></p>
                      <p class="nospace-around primary-font">{{notApplicable}}</p>
                    </div>
                  </div>
                </div>

                <!-- Article Information -->
                <div class="padding-top1" id="information" #information>
                  <h2 class="article-title color-primary ">
                    {{articleInformation}}
                    <mat-divider></mat-divider>
                  </h2>
                  <p class="primary-font" [innerHTML]="this.citation"></p>
                </div>
              </div>
            </div>

            <!-- Comment -->
            <div class="left-content" >
              <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 100px;">
                <div class="divider">
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                  <h2 class="text article-title font-weight mat-h3 color-primary">
                    {{Comments| uppercase }}
                  </h2>
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                </div>
              </div>

              <!-- Comment Content -->
              <div fxLayout="column" fxHide>
                <form [formGroup]="addCommentFormGroup">
                  <div fxLayoutAlign="start center">
                    <mat-form-field appearance="outline" class="comment-textarea">
                      <mat-label>Leave comment Here</mat-label>
                      <textarea disabled={disabled} [(ngModel)]="comment_content" formControlName="comment" matInput
                        placeholder="Leave comment Here" class="comment-textarea" maxlength="250"></textarea>
                    </mat-form-field>
                  </div>
                </form>

                <div fxLayoutAlign="end start">
                  <button mat-raised-button (click)="AddComment()" disabled color="primary" *ngIf="this.userinfo">SUBMIT</button>
                  <button mat-raised-button (click)="AddCommentWithoutuser()" disabled *ngIf="!this.userinfo"
                    color="primary" >SUBMIT</button>
                </div>

              </div>

              <div class="chat-bubble chat-bubble--left" *ngFor="let post of this.ls">
                <div fxLayout="row" fxLayoutGap="10">
                  <p class="color-primary">
                    {{post.name|titlecase}}
                  </p>
                  <p class="date">
                    {{post.date | date:'MMM d, y'}}
                  </p>
                </div>
                {{post.comment}}
              </div>
            </div>


            <!-- Similar Articles -->
            <div fxLayout="column" class="content-padding article-padding left-content">
              <div fxLayout="row" fxLayoutAlign="center center">
                <div class="divider">
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                  <h2 class="text article-title font-weight mat-h3 color-primary">
                    {{similarArticles |uppercase}}
                  </h2>
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                </div>
              </div>
            </div>

            <!-- Trending  Articles -->
            <div fxLayout="column" class="content-padding article-padding left-content">
              <div fxLayout="row" fxLayoutAlign="center center">
                <div class="divider">
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                  <h2 class="text article-title font-weight mat-h3 color-primary">
                    {{trendingArticles |uppercase}}
                  </h2>
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                </div>
              </div>
            </div>
          </mat-tab>

          <!-- Media tab for Mobile screen -->
          <mat-tab label="Media" *ngIf="isMobile()">
            <div class="nospace-around text-justify" style="min-width: 400px;padding-left:0px;margin-left: 0px;"
              [innerHTML]="this.articleMediaSeolist"></div>
          </mat-tab>
        </div>
      </mat-tab-group>
    </div>
  </div>

  <!-- Right Content -->
  <img id="logo" src="assets/images/home/pdflogo.png" fxHide>
  <div class="background-grey" class="left" fxFlex="29" fxHide.lt-md>
    <mat-tab-group [selectedIndex]="selectedIndex">
      <mat-tab label="Content">
        <div fxLayout="column" fxLayoutGap="10px">
          <div class="right-content content-padding" fxLayout="column" id="myDIV">
            <!-- <div fxLayout="row" class="padding-bottom cursor-poiner" fxLayoutAlign="start center">
              <img class="image" src="../../../../assets/images/home/Adobe_Reader.png" alt="">
              <a class="font-primary color-hover-secondary color-black btn right-content-font"
                (click)="generatePdf_new(article_download)" disabled>Download PDF</a>
            </div> -->
            <a class="font-primary color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="highlights" (click)="toScroll()">
              {{Highlights| titlecase}}</a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="Abstract" (click)="toScroll()">
              {{abstract| titlecase}}</a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="Article" (click)="toScroll()">
              {{article| titlecase}}</a>
            <a class="mat-h3 tablecontent color-hover color-hover-secondary color-black btn right-content-font "
              (click)="test($event)" [innerHtml]="toc">
            </a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="References" (click)="toScroll()">
              {{references| titlecase}}</a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="SM" (click)="toScroll()">
              {{supllementaryMaterial| titlecase}}</a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="Acknowledgement" (click)="toScroll()">
              {{acknowledgement| titlecase}}</a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="EthicsStatement" (click)="toScroll()">
              {{ethicsStatement| titlecase}}</a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="COI" (click)="toScroll()">
              {{coileft}}</a>
            <a class="mat-h3 color-hover-secondary color-black btn right-content-font" routerLink="/article-preview"
              fragment="information" (click)="toScroll()">
              {{articleInformationLeft}}</a>
          </div>
          <!-- Image -->
          <div>
            <mat-tab-group>
              <mat-tab label="Media">
                <div class="nospace-around text-justify" [innerHTML]="this.articleMediaSeolist"></div>
              </mat-tab>
            </mat-tab-group>
          </div>
          <!-- Categories Keywords -->
          <div>
            <mat-tab-group>
              <mat-tab label="Categories">
                <div class="keyword-tab">
                  <mat-chip-list>
                    <span *ngFor="let item of this.articleCategorylist;let i = index">
                      <mat-chip color="primary" class="mat-chips" (click)="getCategoryArticle(item)">{{item}}
                      </mat-chip>
                    </span>
                  </mat-chip-list>
                </div>
              </mat-tab>
              <mat-tab label="Keywords">
                <div class="keyword-tab">
                  <mat-chip-list>
                    <span *ngFor="let item of this.key;let i = index">
                      <mat-chip color="primary" class="mat-chips" (click)="getKeywordArticle(item)">{{item |
                        titlecase}}
                      </mat-chip>
                    </span>
                  </mat-chip-list>
                </div>
              </mat-tab>
            </mat-tab-group>
          </div>
        </div>
      </mat-tab>
      <!-- References -->
      <mat-tab label="References">
        <div class="overflow-height ">
          <div class="link-color primary-font" [innerHTML]="this.articleReferencelist"></div>
        </div>
      </mat-tab>
      <!-- Reports -->
      <!-- <mat-tab label="Reports" id="reviewReport" #reviewReport *ngIf="this.userinfo" fxHide>
        <div fxLayout="column" fxLayoutGap="10px" class="padding-side1">
          <div>
            <button mat-raised-button color="primary" (click)="fileInput.click()" class="btn btn-success">
              Upload Document
            </button>
            <span
              style="visibility: hidden; position: absolute; overflow: hidden; width: 0px; height:0px;border:none;margin:0; padding:0;">
              <input type="file" #fileInput ng2FileSelect [uploader]="uploader" />
            </span>
          </div>
          <div *ngIf="uploader.queue.length >= 1">

            <table class="table">

              <tr>
                <th>
                  <h3>File Name</h3>
                </th>

                <th>
                  <h3>Actions</h3>
                </th>
              </tr>

              <tr *ngFor="let item of uploader.queue">
                <td><strong>{{ item?.file?.name}}</strong></td>


                <td>
                  <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                    <button mat-raised-button color="primary" (click)="addUloadEvalution(item)"
                      [disabled]="item.isReady || item.isUploading || item.isSuccess">
                      <mat-icon class="material-icons-sharp">
                        ios_share
                      </mat-icon>

                    </button>
                    <button class="mrgin_left" mat-raised-button color="warn" (click)="item.remove()">
                      <mat-icon class="material-icons-sharp">
                        delete_outline
                      </mat-icon>
                    </button>
                  </div>

                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="overflow-height">
          <mat-table [dataSource]="this.articleEvalution" class="mat-elevation-z8">
            <!-- Version Column -->
            <!-- <ng-container matColumnDef="version">
              <mat-header-cell *matHeaderCellDef> {{Versions}} </mat-header-cell>
              <mat-cell *matCellDef="let element">
                <p class="color-primary" *ngIf="element.reviewerId != null">{{element.reviewerNumber}}</p>
                <p class="color-primary" *ngIf="element.reviewerId == null"> {{element.version}}</p>
              </mat-cell> -->
            <!-- </ng-container> -->

            <!-- Symbol Column -->
            <!-- <ng-container matColumnDef="symbol">
              <mat-header-cell *matHeaderCellDef> {{Documents}} </mat-header-cell>
              <mat-cell *matCellDef="let element" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">

                <div
                  *ngIf="element.documentType == 'image/jpeg' || element.documentType == 'image/jpg'|| element.documentType == 'image/png'"
                  fxLayout="row" fxLayoutGap="10px">
                  <img [src]="element.documentPath" height="20" width="20" alt=""
                    (click)="downloadDocument(element.documentPath)">

                </div>
                <div *ngIf="element.documentType == 'application/pdf'" fxLayout="row" fxLayoutGap="10px">
                  <img src='/assets/images/home/Adobe_Reader.png' height="20" width="20" alt=""
                    (click)="downloadDocument(element.documentPath)">

                </div>
                <div
                  *ngIf="element.documentType == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || element.documentType == 'application/vnd.ms-excel'"
                  fxLayout="row" fxLayoutGap="10px">
                  <img src='/assets/images/home/excelicon.png' height="20" width="20" alt=""
                    (click)="downloadDocument(element.documentPath)">

                </div>
                <div
                  *ngIf="element.documentType != 'application/pdf' && element.documentType != 'image/jpeg' && element.documentType != 'image/jpg'&& element.documentType != 'image/png' && element.documentType != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && element.documentType != 'application/vnd.ms-excel'"
                  fxLayout="row" fxLayoutGap="10px">
                  <img src='/assets/images/home/download.jpg' height="20" width="20" alt=""
                    (click)="downloadDocument(element.documentPath)">

                </div>

              </mat-cell>
            </ng-container> -->

            <!-- date Column -->
            <!-- <ng-container matColumnDef="date">
              <mat-header-cell *matHeaderCellDef> {{date}} </mat-header-cell>
              <mat-cell *matCellDef="let element"> {{element.documentDate | date: 'MMM d, y'}} </mat-cell>
            </ng-container> -->


            <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>
      </mat-tab> --> 

    </mat-tab-group>
  </div>
</div>

<!-- Article not found show -->
<div class="padding-center" [ngClass.lt-md]="'padding-top-sm'" id="top" fxLayout="column" fxLayoutAlign="center center"
  fxLayoutGap="10px" *ngIf="this.errorShow == true">
  <p class="mat-headline color-primary">Article Not Found</p>
  <p class="color-primary" routerLink="/home">Go to Home Page</p>
</div>
<app-footer></app-footer>