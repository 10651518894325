import { MapsAPILoader } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { first } from 'rxjs/operators';
import { InvoiceService } from '../../service/admin/invoice.service';
import { DonationService } from '../../service/HomePage/donation.service';
import { SnackbarService } from '../../service/snackbar.service';
declare var SqPaymentForm: any;
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-invoice-by-colleague',
  templateUrl: './invoice-by-colleague.component.html',
  styleUrls: ['./invoice-by-colleague.component.css']
})
export class InvoiceByColleagueComponent implements OnInit {
  showInvoice:boolean = false
  manuscriptform:FormGroup
  manuscriptDisplayId:any;
  manscriptData: any;
  articleTransactionId: any;
  sqPaymentForm
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild("search") public searchElementRef: ElementRef;
  public selectedAddress: PlaceResult;
  public street_number: string;
  public route2: string;
  public locality: string;
  public sublocality: string;
  public country: string;
  public city: string;
  public postalCode: any;
  public address1: string;
  invoiceinfo: any;
  firstName: any;
  lastName: any;
  amount: any;
  department: any;
  institute:any;
  transactionDetails:any;
  city_author:any;
  state_author:any;
  volume:any;
  issue:any;
  manuscript:any;
  PromoCodeAmount:any;
  datePublish:any;
  dateInvoiced:any;
  Tax: any;
  authorEmail:any
  totalPrice: any;
  country_author: any;
  promoCode: any;
  authorDegree: any;
  authorName: any;
  Name: any;
  manuscripttitle: any;
  promocCodeId: any;
  promoCodeApply: boolean;
  constructor( private fb: FormBuilder, private getArticleId: InvoiceService,private getInvoiceListService: InvoiceService,
    private formBuilder: FormBuilder, private donationService: DonationService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,private matSnack: SnackbarService,) { }

  ngOnInit(): void {
    this.manuscriptform = this.fb.group({
      manuscriptID: new FormControl(''),
      authorname: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      degree: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      phone: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      Department: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      Institute: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      City_author: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      State_author: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      Country_author: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      manuscriptId: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      manuscriptTitle: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      ManuscriptpublishDate: [ new Date(), Validators.required ],
      dateInvoiced: [ new Date(), Validators.required ],
      Volume: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      Issue: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      email: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      tax: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      total: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      amount: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      promoCodeAmount: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      PromoCode: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
      card_name: new FormControl('', Validators.required),
      billing_department: new FormControl('', Validators.required),
      billing_Institution: new FormControl('', Validators.required),
      // cardl_name: new FormControl('', Validators.required),
      CardNumber: new FormControl('', Validators.required),
      SecurityCode: new FormControl('', Validators.required),
      PostalCode: new FormControl('', Validators.required),
      ExpirationDate: new FormControl('', Validators.required),
      Street1: new FormControl('', Validators.required),
      City: new FormControl('', Validators.required),
      State: new FormControl('', Validators.required),
      Country: new FormControl('', Validators.required),
    })
  
  if(this.promocCodeId!= null){
    this.promoCodeApply= true;
  }
  else{
    this.promoCodeApply= false;
  }
  const taxRate = 3;
  // this.tax = this.amount * taxRate / 100
  // this.tax = Math.round(this.tax)
  // this.totalPrice = this.amount + this.tax;

  // console.log(Math.round(this.tax))
  // console.log(this.totalPrice)

  var applicationId = "sq0idp-uotOKbOyMmS4lbh0UaY5tg";

  // Set the location ID
  var locationId = "LATEGCMM3CAKT";
  this.sqPaymentForm = new SqPaymentForm({
    // Initialize the payment form elements

    //TODO: Replace with your sandbox application ID
    applicationId: applicationId,
    locationId: locationId,
    inputClass: 'sq-input',
    autoBuild: false,
    // Customize the CSS for SqPaymentForm iframe elements
    inputStyles: [ {
      fontSize: '14px',
      lineHeight: '24px',
      padding: '14px',
      // placeholderColor: '#a0a0a0',
      backgroundColor: 'transparent',
      placeholderFontWeight: 300
    } ],
    // Initialize the credit card placeholders
    cardNumber: {
      elementId: 'sq-card-number',
      placeholder: 'Card Number'
    },
    cvv: {
      elementId: 'sq-cvv',
      placeholder: 'CVV'
    },
    expirationDate: {
      elementId: 'sq-expiration-date',
      placeholder: 'MM/YY'
    },
    postalCode: {
      elementId: 'sq-postal-code',
      placeholder: 'Postal'
    },
    // SqPaymentForm callback functions
    callbacks: {
      /*
      * callback function: cardNonceResponseReceived
      * Triggered when: SqPaymentForm completes a card nonce request
      */
      cardNonceResponseReceived: function(errors, nonce, cardData) {
        // console.log(cardData)
        localStorage.setItem('cardData', JSON.stringify(cardData))
        localStorage.setItem('nonce', JSON.stringify(nonce))
        const errorCard = document.getElementById("error_card");
        const errorCVV = document.getElementById("error_cvv");
        const errorExp = document.getElementById("error_exp");
        const errorPostal = document.getElementById("error_postal");
        if (errors) {
          let error_html = "";
          for (var i = 0; i < errors.length; i++) {
            error_html += errors[ i ].message + " <br>";
          }
          // console.log(error_html.split(' <br>'))
          var error_array = error_html.split(' <br>')
          var error_card = error_array.find(e => e === 'Credit card number is not valid') ? error_array.find(e => e === 'Credit card number is not valid') : '';
          var error_cvv = error_array.find(e => e === 'CVV is not valid') ? error_array.find(e => e === 'CVV is not valid') : '';
          var error_exp = error_array.find(e => e === 'Expiration date is not valid') ? error_array.find(e => e === 'Expiration date is not valid') : '';
          var error_postal = error_array.find(e => e === 'Postal code is not valid') ? error_array.find(e => e === 'Postal code is not valid') : '';
          // console.log(error_card)
          // console.log(error_cvv)
          // console.log(error_exp)
          // console.log(error_postal)
          if (error_card != 'undefined') {
            errorCard.innerHTML = error_card;
            errorCard.style.display = 'inline-block';
          }
          if (error_cvv != 'undefined') {
            errorCVV.innerHTML = error_cvv;
            errorCVV.style.display = 'inline-block';
          }
          if (error_exp != 'undefined') {
            errorExp.innerHTML = error_exp;
            errorExp.style.display = 'inline-block';
          }
          if (error_postal != 'undefined') {
            errorPostal.innerHTML = error_postal;
            errorPostal.style.display = 'inline-block';
          }
          return;
        }
        errorCard.style.display = 'none';
        errorCard.innerHTML = "";
        errorCVV.style.display = 'none';
        errorCVV.innerHTML = "";
        errorExp.style.display = 'none';
        errorExp.innerHTML = "";
        errorPostal.style.display = 'none';
        errorPostal.innerHTML = "";
        // alert(`The generated nonce is:\n${nonce}`);
        //TODO: Replace alert with code in step 2.1
      }
    },
  });

  //TODO: paste code from step 1.1.4
  this.sqPaymentForm.build();

  //load Places Autocomplete
  this.mapsAPILoader.load().then(() => {
    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      // componentRestrictions: { country: 'US' },
      types: [ '(cities)' ]  // geocode (cities) (regions) establishment address 
    });

    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        this.getAddressComponentByPlace(place);
      });
    });
  });
}
ngAfterViewInit() {
  //  Scrolls to top of Page after page view initialized
  let top = document.getElementById('top');
  if (top !== null) {
    top.scrollIntoView();
    top = null;
  }

}
get f() { return this.manuscriptform.controls; }

requestCardNonce(event) {
  // Don't submit the form until SqPaymentForm returns with a nonce
  event.preventDefault();
  // console.log(this.sqPaymentForm)
  // Request a nonce from the SqPaymentForm object
  this.sqPaymentForm.requestCardNonce();
  // console.log(this.sqPaymentForm.clientController)
  setTimeout(() => {
    this.cutPayment()
  }, 2000);
}

cutPayment() {
  var cardData = JSON.parse(localStorage.getItem('cardData'))
  var nonce = JSON.parse(localStorage.getItem('nonce'))
  var exp_month = cardData.exp_month.toString();
  var exp_year = cardData.exp_year.toString();
  var amount = this.f.amount.value.toString();
  var tax = this.f.tax.value.toString();
  var total = this.f.total.value.toString();
  
  this.donationService.AddInvoice(
    amount,this.f.promoCodeAmount.value,this.promocCodeId,this.promoCodeApply,
    this.f. tax.value, total, nonce,
    cardData.card_brand, cardData.last_4, exp_month,
    exp_year, this.f.email.value,
    this.f.card_name.value, this.f.f_name.value,
    this.f.l_name.value,
    cardData.billing_postal_code,
    'Pay', this.f.Street1.value,
    this.f.City.value, this.f.State.value, this.f.Country.value, this.invoiceinfo.articleTransactionId,
    this.f.billing_department.value,this.f.billing_department.value,true
  )
    .pipe(first())
    .subscribe(
      data => {
        // console.log(data, "donation data")
        localStorage.removeItem('cardData')
        localStorage.removeItem('nonce')
        // window.location.reload()
        this.manuscriptform.reset()
        this.sqPaymentForm.reset()
      },
      error => {
      }
    );
}


getAddressComponentByPlace(place) {
  let components = place.address_components;
  let city = null;
  let postalCode = null;
  let route2 = null;
  let locality = null;
  let street_number = null;
  let address1 = null;
  let country = null

  for (var i = 0, component; component = components[ i ]; i++) {

    if (component.types[ 0 ] == 'street_number') {
      street_number = component[ 'long_name' ];
    }
    if (component.types[ 0 ] == 'route') {
      route2 = component[ 'long_name' ];
    }
    address1 = street_number + ' ' + route2;
    if (component.types[ 0 ] == 'locality') {
      locality = component[ 'short_name' ];
    }
    if (component.types[ 0 ] == 'administrative_area_level_1') {
      city = component[ 'long_name' ];
    }

    if (component.types[ 0 ] == 'country') {
      country = component[ 'short_name' ];
    }
    if (component.types[ 0 ] == 'postal_code') {
      postalCode = component[ 'short_name' ];
    }
  }

  this.route2 = route2;
  this.street_number = street_number;
  this.locality = locality;
  this.city = city;
  this.country = country;
  this.postalCode = postalCode;
  this.address1 = address1;

  }


  searchByManuscript(){
    // const sameCaseArray = array.map(value => value.toLowerCase());

    // var n = sameCaseArray.includes(this.f.email.value);
    this.manuscriptDisplayId=this.f.manuscriptID.value;
    // console.log(this.manuscriptDisplayId,"manuscriptID")
    this.getAuthorInvoice()
  }
        // Get Invoice List api Call
        getAuthorInvoice() {  
          this.getArticleId.getArticleTransactionId<any>(`api/article/ManuscriptWiseArticleTransactionId?manuscriptId=${this.manuscriptDisplayId}`)
          .pipe(first())
            .subscribe((data) => { 
              this.manscriptData= data 
                // this.matSnack.withoutButton("Please check Invoice ID", "OK")
                this.articleTransactionId= this.manscriptData.articleTransactionId
                this.getAuthorInvoiceList() 
              if(this.articleTransactionId == null){
                this.matSnack.withoutButton("Please check Invoice Number", "OK")
              }


            }),
            error => {
                this.matSnack.withoutButton("Please check Invoice Number", "OK")

            }
          }
          getAuthorInvoiceList() {
            this.getInvoiceListService.getInvoiceData<any>(`api/article/PaymentInvoice?articleTransactionId=${this.articleTransactionId}`)
              .subscribe((data) => {
                this.showInvoice= true;
                this.transactionDetails = data;
                this.authorEmail = this.transactionDetails.authorEmail
                this.Name = this.transactionDetails.authorName
                this.authorDegree = this.transactionDetails.degree
                this.department = this.transactionDetails.department
                this.institute = this.transactionDetails.institute
                this.city_author = this.transactionDetails.city
                this.state_author = this.transactionDetails.state
                this.country_author = this.transactionDetails.country
                this.manuscript = this.transactionDetails.articleDisplayId
                this.manuscripttitle = this.transactionDetails.title
                this.datePublish = this.transactionDetails.publishDate
                this.volume = this.transactionDetails.volume
                this.issue = this.transactionDetails.issue
                this.dateInvoiced = this.transactionDetails.dateofInvoice
                this.PromoCodeAmount = this.transactionDetails.PromoCodeAmount
                this.promoCode = this.transactionDetails.promoCode
                this.Tax = this.transactionDetails.tax
                this.totalPrice = this.transactionDetails.total
                this.promocCodeId = this.transactionDetails.PromoCodeId

                // console.log( this.authorEmail,"tranasction Details")
              })        
          }
}
