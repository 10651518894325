import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, Optional, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { InvoiceService } from 'src/app/shared/service/admin/invoice.service';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-share-invoice',
  templateUrl: './share-invoice.component.html',
  styleUrls: ['./share-invoice.component.css']
})
export class ShareInvoiceComponent implements OnInit {

  visible: boolean = true;
  selectable: boolean = true;
  removable: boolean = true;
  addOnBlur: boolean = true;
  local_data: any;
  userinfo: any;
  userinfoid: any;
  articleTransactionId: any;
  constructor(private invoiceService:InvoiceService, 
    private matSnack: SnackbarService,
    public dialogRef: MatDialogRef<ShareInvoiceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ShareInvoiceComponent,) { 
      this.local_data = { ...data };

    }
  ngOnInit(): void {
    
  }
  // Enter, comma
  separatorKeysCodes = [ENTER, COMMA];

  keywords = [];


  add(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;

    // Add our keyword
    if ((value || '').trim()) {
      this.keywords.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(keyword: any): void {
    let index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }

  joinsymptomps(arrayValue) {
    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function(item) {
      return item;
    }).join(";");
  }

  SendMail() {
    this.articleTransactionId = this.local_data.articleTransactionId
    // console.log(this.articleTransactionId)
    // this.userinfoid = this.userinfo.id;
    this.invoiceService.sendInvoice(this.articleTransactionId,'support@annalsebm.org',null,null,this.joinsymptomps(this.keywords))
      .pipe(first())
      .subscribe(
        data => {
          this.matSnack.withoutButton("Invoice Send", "")
        }
      );
  }
}
