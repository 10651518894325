import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screen-size',
  templateUrl: './screen-size.component.html',
  styleUrls: ['./screen-size.component.css']
})
export class ScreenSizeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

}
