export const ArticleEditor = {


  toolbar: [ 'undo', 'redo', '|', 'bold', 'italic', 'superscript', '|', 'heading', 'FontColor', '|', 'bulletedList', 'numberedList',
    '|',
    'imageUpload', 'insertTable', 'link','wordcount'
  ],
  image: {
    toolbar: [
      'imageTextAlternative',
      'imageStyle:full',
      'imageStyle:side'
    ],
  },
 
  fontColor: {
    colors: [
      {
        color: 'hsl(0, 75%, 60%)',
        label: 'Red'
      }
    ]
  },
  table: {
    contentToolbar: [
      'tableColumn',
      'tableRow',
      'mergeTableCells',
      
    ]
  },
  heading: {
    options: [
      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
      { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1' },
      { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2' }
    ]
  }
}

