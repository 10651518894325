<div class="background padding-header" [ngClass.lt-md]="'padding-header-sm'" [ngClass.md]="' padding-header-md'">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-card [ng-class.gt-md]="'card-width'">
            <mat-card-content fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="5%">
                <h2 class="title">Suggest Reviewer </h2>

                <div style="width: 100%;">
                    <form [formGroup]="SuggestReviewerFormGroup" fxLayout="column" fxLayoutGap="15px">

                        <!-- Reason -->
                        <div class="padding-header" fxLayout="column">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Reason and Month of Unavailability</mat-label>
                                <textarea matInput formControlName="reason"></textarea>
                            </mat-form-field>
                        </div>

                        <!-- Reviewer Name -->
                        <div class="padding-header" fxLayout="column">
                            <mat-form-field class="example-full-width" appearance="outline">
                                <mat-label>Suggest Reviewers' Details</mat-label>
                                <textarea matInput formControlName="reviewerDetails"></textarea>
                            </mat-form-field>
                        </div>

                        <!-- <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Suggest Reviewer First Name</mat-label>
                                <input formControlName="firstName" matInput placeholder="Suggest Reviewer First Name"
                                    >
                                <mat-error>Suggest Reviewer First Name Required
                                </mat-error>
                            </mat-form-field>

                        </div> -->
                        <!-- <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Suggest Reviewer Last Name</mat-label>
                                <input formControlName="lastName" matInput placeholder="Suggest Reviewer Last Name"
                                    >
                                <mat-error>Suggest Reviewer Last Name Required
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                        <!-- <div>
                            <mat-form-field appearance="outline" fxFlex="100">
                                <mat-label>Suggest Reviewer Email</mat-label>
                                <input formControlName="suggestemail" matInput placeholder="Suggest Reviewer Email"
                                    >
                                <mat-error>Suggest Reviewer Email Required
                                </mat-error>
                            </mat-form-field>
                        </div> -->



                        <!-- send button -->
                        <div fxLayoutAlign="center" [style.margin-top]="'20px'">
                            <button mat-button mat-raised-button color="primary" fxFlex="50"
                                (click)="Send()">Send</button>
                        </div>
                    </form>
                </div>
            </mat-card-content>

        </mat-card>
    </div>
</div>