import { Injectable } from '@angular/core';

import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { AuthenticationService } from '../../shared/service/author/authentication.service';

@Injectable()


export class HttpConfigInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authenticationService.currentUserValue;

        if (currentUser) {
            if (currentUser && currentUser.authdata) {

                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.authdata}`
                    }
                });
            }

        }

        return next.handle(request);
    }
}