import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RestmanagerService } from '../../utils/restmanagerService';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommentService {
  private restmanagerService: RestmanagerService;
  _apiEndPoint: string = '';
  constructor(private _http: HttpClient, restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }

  AddComment(ArticleId: string, UserId: string, Comment: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/Article/AddComment`, {
      ArticleId,
      UserId,
      Comment

    })
      .pipe(map(res => {
        return res;
      }));
  }


  getAllComment<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }
  getCommentById<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }

  ApproveComment(ArticleCommentId: string, EditorId: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/Article/CommentAccepted`, {
      ArticleCommentId,
      EditorId

    })
      .pipe(map(res => {
        return res;
      }));
  }

  rejectComment(ArticleCommentId: string, EditorId: string) {
    return this._http.post<any>(`${environment.apiUrl}/api/Article/CommentRejected`, {
      ArticleCommentId,
      EditorId

    })
      .pipe(map(res => {
        return res;
      }));
  }
}
