import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sticky-shared',
  templateUrl: './sticky-shared.component.html',
  styleUrls: [ './sticky-shared.component.css' ]
})
export class StickySharedComponent implements OnInit {
  postTitle: string;
  postUrl: string;

  constructor() { }

  ngOnInit(): void {
    const facebookBtn = document.getElementById("facebook-btn");
    const twitterBtn = document.getElementById("twitter-btn");
    // const pinterestBtn = document.getElementById("pinterest-btn");
    const linkedinBtn = document.getElementById("linkedin-btn");
    const whatsappBtn = document.getElementById("whatsapp-btn");
    // const pinterestImg = document.getElementById("pinterest-img");

    this.postUrl = encodeURI(document.location.href);
    this.postTitle = encodeURI("Hi, please check this out: ");

    // facebookBtn.setAttribute(
    //   "href",
    //   `https://www.facebook.com/sharer.php?u=${postUrl}`
    // );

    // twitterBtn.setAttribute(
    //   "href",
    //   `https://twitter.com/share?url=${postUrl}&text=${postTitle}`
    // );

    // // pinterestBtn.setAttribute(
    // //   "href",
    // //   `https://pinterest.com/pin/create/bookmarklet/?media=${postImg}&url=${postUrl}&description=${postTitle}`
    // // );

    // linkedinBtn.setAttribute(
    //   "href",
    //   `https://www.linkedin.com/shareArticle?url=${postUrl}&title=${postTitle}`
    // );

    // whatsappBtn.setAttribute(
    //   "href",
    //   `https://wa.me/?text=${postTitle} ${postUrl}`
    // );

  }

}
