import { Component, ElementRef, HostListener, Inject, NgZone, OnInit, PLATFORM_ID, Renderer2, ViewChild } from '@angular/core';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import * as htmlToImage from 'html-to-image';
import jsPDF from 'jspdf';
import Editor from '../../PaperSubmission/ckeditor/build/ckeditor'
import { ActivatedRoute, Router } from '@angular/router';
import { SummaryService } from 'src/app/shared/service/author/summary.service';
import { Articletype } from 'src/app/shared/model/articletype';
import { Articletitle } from 'src/app/shared/model/articletitle';
import { Articleheighlights } from 'src/app/shared/model/articleheighlights';
import { Articleabstract } from 'src/app/shared/model/articleabstract';
import { Articlekeyword } from 'src/app/shared/model/articlekeyword';
import { Articlecontent } from 'src/app/shared/model/articlecontent';
import { Articlereferences } from 'src/app/shared/model/articlereferences';
import { Articleacknowledgement } from 'src/app/shared/model/articleacknowledgement';
import { Coidisclosure } from 'src/app/shared/model/coidisclosure';
import { Articleethicsstatement } from 'src/app/shared/model/articleethicsstatement';
import { Articlecategories } from 'src/app/shared/model/articlecategories';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { config, fromEvent, Subject } from 'rxjs';
import { throttleTime, map, pairwise, distinctUntilChanged, takeUntil, first } from 'rxjs/operators';
import { ArticleauthorlistService } from 'src/app/shared/service/author/articleauthorlist.service';
import { Articleauthorlist } from 'src/app/shared/model/articleauthorlist';
import { CONSTANT } from 'src/app/shared/utils/constant';
import { FileUploader } from 'ng2-file-upload';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RecentArticle } from 'src/app/shared/model/Home-Page/recentArticle';
import { RecentArticlesService } from 'src/app/shared/service/HomePage/recent-articles.service';
import { UploadevalutionService } from 'src/app/shared/service/author/uploadevalution.service';
import { ArticleByCategoriesService } from '../../../shared/service/HomePage/article-by-categories.service'
import { CommentService } from '../../../shared/service/author/comment.service';
import { UploadService } from '../../../shared/service/author/upload.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { element } from 'protractor';
import { AbstractEditor } from 'src/app/shared/service/class/abstractEditor';
import { articlePreviewEditor } from 'src/app/shared/service/class/articlePreviewEditor';
import { ReferenceEditor } from 'src/app/shared/service/class/referenceEditor';
import { ArticlecontributionsService } from 'src/app/shared/service/author/articlecontributions.service';
import { CitationService } from 'src/app/shared/service/admin/citation.service';
import { Mediaorseo } from 'src/app/shared/model/mediaorseo';
import { ViewcountService } from 'src/app/shared/service/HomePage/viewcount.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ReportUserService } from 'src/app/shared/service/HomePage/report-user.service';

enum VisibilityState {
    Visible = 'visible',
    Hidden = 'hidden'
}

enum Direction {
    Up = 'Up',
    Down = 'Down',
    Original = 'Original',
    None = 'None'
}

/*
*Articles component
*/
@Component({
    selector: 'app-articles',
    templateUrl: './articles.component.html',
    styleUrls: [ './articles.component.css' ],
    animations: [
        trigger('scrollAnimation', [
            state(VisibilityState.Visible, style({
                transform: 'translateY(0)', opacity: 1
            })),
            state(VisibilityState.Hidden, style({
                transform: 'translateY(0)', opacity: 0 // adjust this to the height of your header
            })),
            transition(`${VisibilityState.Visible} => ${VisibilityState.Hidden}`, animate('500ms')),
            transition(`${VisibilityState.Hidden} => ${VisibilityState.Visible}`, animate('250ms'))
        ])
    ]
})

/*
*class: Articles
*Reference link: 
*/
export class ArticlesComponent implements OnInit {
customCollapsedHeight: string = '20px';
htmlContent = "";
name = 'Angular';
public Editor = Editor;
editorButton = articlePreviewEditor;
// mybutton = document.getElementById("myBtn");
Volume = (CONSTANT.Volume);
Issue = (CONSTANT.Issue);
Views = (CONSTANT.Views);
Date = (CONSTANT.Date);
AuthorAffiliations = (CONSTANT.authorAffiliations);
CorrespondingAuthor = (CONSTANT.CorrespondingAuthor);
Citeas = (CONSTANT.Citeas);
Highlights = (CONSTANT.Highlights);
Highlight1 = (CONSTANT.Highlight1);
Highlight2 = (CONSTANT.Highlight2);
Highlight3 = (CONSTANT.Highlight3);
abstract = (CONSTANT.Abstract);
keywords = (CONSTANT.keywords);
article = (CONSTANT.article);
references = (CONSTANT.references);
supllementaryMaterial = (CONSTANT.supllementaryMaterial);
acknowledgement = (CONSTANT.Acknowledgement);
coi = (CONSTANT.coi);
coileft = (CONSTANT.coileftSide);
ethicsStatement = (CONSTANT.ethicsStatement);
coiresponsibility = (CONSTANT.coiresponsibility);
coipayment = (CONSTANT.coipayment);
coifinancial = (CONSTANT.coifinancial);
coiother = (CONSTANT.coiother);
humanSubject = (CONSTANT.humanSubject);
protocolNumber = (CONSTANT.protocolNumber);
approvalNumber = (CONSTANT.approvalNumber);
none = (CONSTANT.none);
statement = (CONSTANT.statement);
animalSUbject = (CONSTANT.animalSUbject);
notApplicable = (CONSTANT.notApplicable);
loclAnimalComitee = (CONSTANT.loclAnimalComitee);
articleInformation = (CONSTANT.ArticleInformation);
articleInformationLeft = (CONSTANT.ArticleInformationLeft);
PublicationHistory = (CONSTANT.publicationHistory);
peerReviewBegan = (CONSTANT.peerReviewBegan);
peerReviewConducted = (CONSTANT.peerReviewConducted);
published = (CONSTANT.published);
Copyright = (CONSTANT.Copyright);
Comments = (CONSTANT.comments);
similarArticles = (CONSTANT.similarArticles);
trendingArticles = (CONSTANT.trendingArticles);
Versions = (CONSTANT.Versions);
Documents = (CONSTANT.Documents);
date = (CONSTANT.date);
currentDate = new Date();
comment_content = "";
post_comment = [];
show = false;
toc: SafeHtml | String = '';
content: SafeHtml | String = '';
panelOpenState = false;
background: 'primary';
showFiller = false;
showFiller1 = false;
openreply = true;
fragment;
key: any;
routerScroll: any;
hideme = {};
hideme1 = {};
articleStudytitle;
articleStudytitlelist: any;
articlesummary;
articletypesummary: any;
articleAuthorList;
articleHighlights;
articleHighlightsprior;
articleHighlightsfinding;
articleHighlightspracticle;
articleAbstract;
articleAbstractlist;
articleKeywords;
articleKeywordslist;
articleArticleData;
articleArticleDatalist;
articleReference;
articleReferencelist;
articleAcknowledgement;
articleAcknowledgentData;
articleEthicsStatement;
articleEthicsStatementProtocol: any;
articleEthicsStatementapprovalNumber: any;
articleEthicsStatementlocalAnimalCommitee: any;
articleEthicsStatementReviewBoard: any;
articleEthicsStatementA_Number: any;
articleEthicsStatementstate: any;
articleCoi;
articleCoipaymentData: any;
articleCoifinancialActivity: any;
articleCoiOtherRelationships: any;
isORavailable: string;
isFAavailable: string;
isPayment: string;
ethicStatement;
latestArticle;
isAcknowledgement: any;
isHumanSubject: any;
isAnimalSubject: any;
articleCategory;
articleCategorylist;
publications: any;
windowScrolled: boolean;
private isVisible = true;
correspondingAuthordata: any;
dataByid: any;
coFirstname: any;
coLastName: any;
coDegree: any;
coDepartment: any;
coCity: any;
coState: any;
coCountry: any;
coEmail: any;
coInstitute: any;
addSMFormGroup: FormGroup
userinfo: any;
fullarticle: any;
Viewcount: any;
materialdata: any;
articleEvalution: any;
coFullName: any;
articleType: any;
categoriesbydata: any;
articlePerpageItem: any;
addresponse: string;
selectedIndex: number = 0;
addCommentFormGroup: FormGroup
listofComment: any;
ls: any;
Viewcountdata: any;
nonpublications: any;
articleEthics: any;
isSupplymantryMaterialAdded: any;
responsibility: any;
ViewCountanddate: any;
reviewerAssignDate: any;
reviewerConcludeDate: any;
articlebydata: any;
similarArticle: any;
issuearticle: any;
volumearticle: any;
checkfiletype: any;
conceptualizations: any;
methodologys: any;
softwares: any;
validations: any;
analysiss: any;
investigations: any;
resources: any;
curations: any;
drafts: any;
reviews: any;
visualizations: any;
supervisions: any;
administrations: any;
fundings: any;
authorName: any;
Admin: any;
articleFIgurecount: number;
articleabstract: any;
authorNotesdata: any;
citation: any;
articleMediaorSeo: any;
articleMediaSeolist: any;
articlePublishDate: any;
routeid: string;
articleId: any;
errorShow: boolean = false;
routeidandTitle: string;
availableReport: boolean;
    footer: any;
    totaldownload: any;
get toggle(): VisibilityState {
        return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
}
editorForm: FormGroup
private destroy$: Subject<boolean> = new Subject<boolean>();
isHeader2Visible = VisibilityState.Hidden;
slideHeader2InAtPosition = 200;
slideHeaderInAtPosition = 30;
citationdisable: boolean = false
displayedColumns: string[] = [ 'version', 'symbol', 'date' ];

constructor(public route: ActivatedRoute, private zone: NgZone,
    public router: Router, private getsummaryservice: SummaryService,
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object, private GetRecentArticles: RecentArticlesService,
    private GetArticleAuthorListService: ArticleauthorlistService,
    private AddUploadEvalutionService: UploadevalutionService,
    private GetArticlesCategory: ArticleByCategoriesService,
    private GetCommentService: CommentService,
    private formBuilder: FormBuilder,
    private uploadService: UploadService,
    private _sanitizer: DomSanitizer,
    private Getcountarticle: ViewcountService,
    private renderer: Renderer2, private elementRef: ElementRef,
    private snackBar: MatSnackBar,
    private matSnack: SnackbarService,
    private domSanitizer: DomSanitizer,
    private GetContributionsService: ArticlecontributionsService,
    private GetCitaionService: CitationService,
    private spinner: NgxSpinnerService,
    private GetReport:ReportUserService) 
    {
        Window[ "myComponent" ] = this;
        this.hideme = {};
        this.hideme1 = {};
        this.uploader = new FileUploader({
            // url: URL,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {

                return new Promise((resolve, reject) => {
                    resolve({
                        name: item._file.name,
                        length: item._file.size,
                        contentType: item._file.type,
                        date: new Date()
                    });
                    // console.log(item)
                });
            }
        });
        this.hasBaseDropZoneOver = false;
        this.hasAnotherDropZoneOver = false;
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);
    }
    @ViewChild('Article') inputTxt: ElementRef;

    public removeEventListener: () => void;

    ngOnInit() {
 
        this.editorForm = this.formBuilder.group({
            editorName: new FormControl(''),
            footerDetails: new FormControl(''),
        })
        this.addCommentFormGroup = this.formBuilder.group({
            comment: new FormControl(''),

        })
        this.routeidandTitle = this.route.snapshot.paramMap.get('id')
        this.routeid = this.route.snapshot.paramMap.get('id').substring(0, 9);

        // this.routeId = this.route.snapshot.paramMap.get('id').substring(0, 9)
        this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
        this.GetArticleId(this.routeid)
        this.addresponse = localStorage.getItem('addresponse')
        this.getReport()

        // this.removeEventListener = this.renderer.listen(this.inputTxt.nativeElement, 'click', (event) => {
        //     console.log(this.inputTxt.nativeElement)
        //     // console.log(event.target.split('>')[ 0 ])
        //     // if (event.target instanceof HTMLAnchorElement) {
        //     //     // Your custom anchor click event handler
        //     //     console.log(event)
        //     //     this.handleAnchorClick(event);
        //     // }
        //     if (event.target) {
        //         this.handleAnchorClick(event)
        //     }
        // });

        localStorage.removeItem('addresponse')
    }

    // Get  Full ARticle
    GetArticleId(id) {
        this.spinner.show();
        var manuscriptId = 'AEBM-' + id
        this.GetRecentArticles.getArticle<any>(`api/Article/GetArticleIdByPassMenuscript?Manuscriptid=${manuscriptId}`)
            .subscribe((data: any) => {
                this.articleId = data.message
                localStorage.setItem('articleId', data.message)
                if (this.articleId != null) {
                    if (this.userinfo) {
                        this.isAdmin();
                        this.getViewCountanddate()
                        this.getArticleType();
                        this.getArticleStudyTitle();
                        this.getArticleAuthorList();
                        this.getCorrespodingAuthor();
                        this.getAuthorNotes();
                        this.getContributions()
                        this.getCitation()
                        this.getFooter()
                        this.getMediaorSEO()
                        this.getArticleHighlights();
                        this.getArticleAbstract();
                        this.getArticleKeywords();
                        this.getArticleData();
                        this.getArticleReference();
                        this.getArticleAcknowledgement();
                        this.getArticleEthicsStatement();
                        this.getSupplement();
                        this.getnonSupplement();
                        this.getArticleCOI();
                        this.getCOIresponsibility();
                        this.getArticleCategories();
                        // console.log(this.articleId,"ArticleId")
                        this.getUploadEvalution()
                        if (this.addresponse) {
                            this.selectedIndex = 2
                        }
                        this.GetCommentById()
                        this.getArticle();
                    }

                    if (!this.userinfo) {
                        // this.GetArticleId(this.routeid)
                        this.getfullarticleinfo()
                        this.getArticle();
                        this.GetCommentById()
                        this.Viewcount = localStorage.getItem('Viewcount')
                        this.getViewCountanddate()
                    }
                }
                this.spinner.hide();
            },
                error => {
                    // prompt('article is not found')
                    // console.log(error);
                    this.errorShow = true
                    this.spinner.hide();
                });

    }

    // Mobile size check for media
    isMobile() {
        const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
        return width < 1025;
    }

    // Citation 
    get Citationformcontrol() {
        return this.editorForm.controls;
    }
    citationenable() {
        this.citationdisable = true
    }

    // Footer
    footerEnable() {
        this.citationdisable = true
    }

    // Is Admin 
    isAdmin() {
        if (this.userinfo.role[ 0 ] == "Administrator") { this.Admin = true; }
    }

    // public ngOnDestroy() {
    //     // Cleanup by removing the event listener on destroy
    //     this.removeEventListener();
    // }

    // Anchor select 
    public handleAnchorClick(event) {
        // Prevent opening anchors the default way
        // console.log(event.target)

        event.preventDefault();
        const anchor = event.target
        // console.log(anchor.split('')[ 0 ])
        var element = event.target as HTMLElement;
        if (element.tagName === "SUP") {
            // console.log(element.tagName)
            this.selectedIndex = 1
            this.scrollToTop()
        }
        // console.log(element)
        // alert(`You are trying to navigate to ${anchor.href}`);
        if (anchor.innerHTML.split('')[ 0 ] == '[') {
            this.selectedIndex = 1
            this.scrollToTop()
        }
        if (this.isMobile() === true) {
            if (element.tagName === "SUP") {
                document.getElementById('References').scrollIntoView();
            }
            if (anchor.innerHTML.split('')[ 0 ] == '[') {
                document.getElementById('References').scrollIntoView();
            }
        }
    }

    //Form Control Value 
    get formcontrol() {
        return this.addCommentFormGroup.controls;
    }

    // Scroll to top
    scrollToTop() {
        // console.log('test')
        document.getElementById('top').scrollIntoView();
    }

    ngAfterViewInit() {
        //  Scrolls to top of Page after page view initialized
        let top = document.getElementById('top');
        if (top !== null) {
            top.scrollIntoView();
            top = null;
        }
        const content = document.querySelector('.content-router');
        const scroll$ = fromEvent(content, 'scroll').pipe( // if the scroll events happen on your window you could use 'window' instead of 'content' here
            throttleTime(10),
            map(() => content.scrollTop), // if you used 'window' above replace 'content.scrollTop' with 'window.pageYOffset'
            pairwise(),
            map(([ y1, y2 ]): Direction => {
                // console.log(this.slideHeader2InAtPosition);
                return (y2 <= this.slideHeader2InAtPosition ? Direction.Up : (y2 > this.slideHeaderInAtPosition ? Direction.Down : Direction.None));
            }),
            distinctUntilChanged(),
            takeUntil(this.destroy$)
        );

        // subscribe to the UP / DOWN scroll direction stream and set the header state accordingly
        scroll$.subscribe(dir => {
            if (dir === Direction.Down) {
                this.isHeader2Visible = VisibilityState.Visible;
            } else {
                this.isHeader2Visible = VisibilityState.Hidden;
            }
        });
    }

    //scroll to services
    ngAfterViewChecked(): void {
        try {
            if (this.fragment) {
                document.querySelector('#' + this.fragment).scrollIntoView();
            }
        } catch (e) { }
    }
    toScroll() {
        setTimeout(() => {
            this.router.navigateByUrl(`/articles/${this.routeid}`)
        }, 1);

    }

    scrollComment() {

        document.querySelector('#comment').scrollIntoView({ behavior: 'smooth' });
    }
    openreply1(index) {
        if (index == 0) {
            this.openreply = !this.openreply
        }
        // console.log(index)
    }


    Screen() {
        var data = document.getElementById('content');
        html2canvas(data).then(canvas => {
            // Few necessary setting options  
            var imgWidth = 208;
            var pageHeight = 500;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;

            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
            pdf.save('MYPdf.pdf'); // Generated PDF   
        });

        let doc = new jspdf('p', 'pt', 'a4');
        html2canvas(document.getElementById('content')).then(function(canvas) {
            let width = doc.internal.pageSize.getWidth();
            let height = doc.internal.pageSize.getHeight();
            let dataURL = canvas.toDataURL('image/jpeg', 1.0);
            doc.addImage(dataURL, 'jpg', 0, 0, width, height, 'none');
            doc.save('mypdf.pdf');
        });
    }

    // Article Type
    getArticleType() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articletype>(`api/Article/GetArticleType?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articlesummary = data as Articletype[];
                this.articletypesummary = this.articlesummary.articleType
            }
            );
    }

    // Article Title
    getArticleStudyTitle() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articletitle>(`api/Article/GetArticleStudyTitle?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleStudytitle = data as Articletitle[];
                this.articleStudytitlelist = this.articleStudytitle.studyTitle
                // console.log(this.articleStudytitle, "data");
            }
            );
    }

    // Author Details
    getArticleAuthorList() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle(`api/Article/GetArticleAuthorsList?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleAuthorList = data;
                // console.log(this.articleAuthorList, "data");
            }
            );
    }

    // HighLights
    getArticleHighlights() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articleheighlights>(`api/Article/GetHighlightsOfStudy?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleHighlights = data as Articleheighlights[];
                this.articleHighlightsprior = this.articleHighlights.priorKnowldege
                this.articleHighlightsfinding = this.articleHighlights.findingOfStudy
                this.articleHighlightspracticle = this.articleHighlights.impactOnPractice
                // console.log(this.articleHighlights, "data");
            }
            );
    }

    // Abstract
    getArticleAbstract() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articleabstract>(`api/Article/GetArticleAbstract?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleAbstract = data as Articleabstract[];
                this.articleAbstractlist = this._sanitizer.bypassSecurityTrustHtml(`${this.articleAbstract.abstract}`);

                // console.log(this.articleAbstractlist, "main ")
                // document.querySelectorAll('oembed[url]').forEach(element => {
                //     console.log(element, "anchor")

                //     // Create the <a href="..." class="embedly-card"></a> element that Embedly uses
                //     // to discover the media.
                //     const anchor = document.createElement('a');

                //     anchor.setAttribute('href', element.getAttribute('url'));
                //     anchor.className = 'embedly-card';

                //     element.appendChild(anchor);
                // });
            });

    }

    // keywords
    getArticleKeywords() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle(`api/Article/GetArticleKeyWords?articleId=${this.articleId}`)
            .subscribe((data) => {

                this.articleKeywords = data;
                if (this.articleKeywords != null || this.articleKeywords != '' || this.articleKeywords != "" && this.articleKeywordslist.lenght > 0) {
                    this.articleKeywordslist = this.articleKeywords.keywords.replace(/,/g, ", ");
                    this.key = this.articleKeywordslist.split(', ');
                    // console.log(this.key, "keyword");

                    this.getsimilararticle(this.key)
                }

            }
            );
    }

    // Article
    getArticleData() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articlecontent>(`api/Article/GetArticleData?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleArticleData = data as Articlecontent[];
                // this.articleArticleDatalist = this._sanitizer.bypassSecurityTrustHtml(`${this.articleArticleData.articleData}`);
                this.articleArticleDatalist = this.articleArticleData.articleData;
                // console.log(this.articleArticleDatalist.document.getElementsByTagName("h2"))
                // const article_id = document.getElementById('article_content');
                // var article_tag = article_id.getElementsByTagName('h2');
                this.content = this.articleArticleDatalist;
                if (isPlatformBrowser(this.platformId)) {
                    // Client only code.
                    this.toc = this.getToc(this.content);
                }
                //    console.log("content = " + this.content.changingThisBreaksApplicationSecurity);
                this.removeEventListener = this.renderer.listen(this.inputTxt.nativeElement, 'click', (event) => {
                    if (event.target) {
                        this.handleAnchorClick(event)
                    }
                });

            }
            );
    }

    // Right Side Content subpoint
    getToc(content) {
        var contentdiv = document.createElement("div");
        contentdiv.innerHTML = content;
        var myArrayOfNodes = [].slice.call(contentdiv.querySelectorAll("h2"));
        let toc = document.createElement("ul");
        var pointer = toc;
        var myArrayOfNodes = [].slice.call(contentdiv.querySelectorAll("h2"));
        var toc1 = myArrayOfNodes[ 0 ];
        // console.log(toc)
        var id_suffix = 0;

        // loop through the array of headlines
        for (let i = 0; i < myArrayOfNodes.length; i++) {
            var anchor = document.createElement('a');
            anchor.setAttribute('name', 'toc' + i);
            anchor.setAttribute('id', 'toc' + i);
            var link = document.createElement('a');
            link.setAttribute('id', 'toc' + i);
            link.setAttribute('class', 'tablecontent');
            link.setAttribute('href', `#/articles/${this.routeid}#toc` + (i + 1));
            link.setAttribute('routerlink', `/articles`);
            link.setAttribute('ng-reflect-router-link', `/articles`);
            link.setAttribute('ng-reflect-fragment', `toc` + (i + 1));
            link.setAttribute('fragment', `toc` + (i + 1));
            link.textContent = myArrayOfNodes[ i ].innerText;
            let div = document.createElement('div');
            // console.log(myArrayOfNodes[ i ].tagName + ": " + myArrayOfNodes[ i ].innerHTML);
            // if we have detected a top level headline:
            if ("H2" == myArrayOfNodes[ i ].tagName) {
                // reset the pointer to top level:
                pointer = toc;
            }
            // if headline has no id, add a unique id
            if ("" == myArrayOfNodes[ i ].id) {
                myArrayOfNodes[ i ].id = "toc" + ++id_suffix;

            }
            div.appendChild(link);
            toc.appendChild(div);

            myArrayOfNodes[ i ].parentNode.insertBefore(anchor, myArrayOfNodes[ i ]);
            // console.log(div, "div")
        }
        this.content = this._sanitizer.bypassSecurityTrustHtml(contentdiv.innerHTML);
        return this._sanitizer.bypassSecurityTrustHtml(toc.innerHTML);
    }

    // Jump Leftside on Rightside subpoint 
    test(test:any) {
        this.zone.run(() => {
            // this.router.navigate([ '' ], { skipLocationChange: true });
                   console.log('click', test)
            setTimeout(() => {
                document.querySelector(`#${test.srcElement.href.split('#')[2]}`).scrollIntoView({ behavior: 'auto' });
                // this.router.navigate([ `/article-preview` ]);
                this.router.navigateByUrl(`/articles/${this.routeid}`)
            }, 1000);
        });
        // setTimeout(() => this.router.navigate(['/app-articles']));
    }

    // References
    getArticleReference() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articlereferences>(`api/Article/GetArticleRefrences?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleReference = data as Articlereferences[];
                this.articleReferencelist = this.articleReference.refrence
                // console.log(this.articleReferencelist, "references");
            }
            );
    }

    // report check for user 
    getReport(){
        var userId= this.userinfo.id;
        // console.log(userId,"userId")
        // var userId=localStorage.getItem('userId')
        var articleId = localStorage.getItem('articleId')
        this.GetReport.getReport<any>(`api/Article/VarificationOfUserForViewReport?userId=${userId}&articleId=${articleId}`)
        .subscribe((data) => {
            this.availableReport=data as any
            // console.log(this.availableReport,"Report data")
            // console.log(this.articleReferencelist, "references");
        }
        );
    }

    // Acknowledgement
    getArticleAcknowledgement() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articleacknowledgement>(`api/Article/GetAcknowledgement?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleAcknowledgement = data as Articleacknowledgement[];
                this.articleAcknowledgentData = this.articleAcknowledgement.acknowledgement
                this.isAcknowledgement = this.articleAcknowledgement.isAcknowledgement

                // console.log(this.articleAcknowledgement, "acknowlegement");
            }
            );
    }

    // EThics Statement
    getArticleEthicsStatement() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articleethicsstatement>(`api/Article/GetEthicsStatments?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleEthicsStatement = data as Articleethicsstatement[];
                this.isHumanSubject = this.articleEthicsStatement.isHumanSubject
                this.isAnimalSubject = this.articleEthicsStatement.isAnimalSubject
                if (this.articleEthicsStatement.humanSubject != null) {
                    this.articleEthicsStatementReviewBoard = this.articleEthicsStatement.humanSubject.institutionalReviewBoard
                    this.articleEthicsStatementA_Number = this.articleEthicsStatement.humanSubject.approvalNumber
                    this.articleEthicsStatementstate = this.articleEthicsStatement.humanSubject.statement
                }
                if (this.articleEthicsStatement.animalSubject != null) {
                    this.articleEthicsStatementProtocol = this.articleEthicsStatement.animalSubject.protocolNumber,
                        this.articleEthicsStatementapprovalNumber = this.articleEthicsStatement.animalSubject.approvalNumber
                    this.articleEthicsStatementlocalAnimalCommitee = this.articleEthicsStatement.animalSubject.localAnimalCommitee
                }
                // console.log(this.articleEthicsStatementapprovalNumber, "approvalNumber");
                // console.log(this.articleEthicsStatementProtocol, "protocol number");
            }
            );
    }

    // Supplymentry material 
    getSupplement() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<any>(`api/Article/GetSupplymentrymaterialforPublications?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.publications = data;
            }
            );

    }

    getnonSupplement() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<any>(`api/Article/GetSupplymentrymaterialforNonPublications?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.nonpublications = data;
            }
            );
    }

    getArticleSupplymentary() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<any>(`api/Article/IsSupplementaryMaterialAdded?articleId=${this.articleId}`)
            .subscribe((data) => {

                this.articleEthics = data as any[];
                this.isSupplymantryMaterialAdded = this.articleEthics.isSupplymantryMaterialAdded
                // console.log(this.articleEthicsStatementProtocol, "protocol number");
            }
            );
    }

    // Document Open
    Documentopen(documentPath) {
        window.open(documentPath, '_blank');
    }

    // COI Responsibility Option
    getCOIresponsibility() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<any>(`api/Article/GetCoiFiles?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.responsibility = data;
            }
            );

    }

    // COI
    getArticleCOI() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Coidisclosure>(`api/Article/GetCoiData?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleCoi = data as Coidisclosure[];
                this.articleCoipaymentData = this.articleCoi.paymentData
                this.articleCoifinancialActivity = this.articleCoi.financialActivity
                this.articleCoiOtherRelationships = this.articleCoi.otherRelationships
                this.isPayment = this.articleCoi.isPaymentAdded
                this.isFAavailable = this.articleCoi.isFinancialActivity
                this.isORavailable = this.articleCoi.isOtherRelationShip
            }
            );
    }

    // categories
    getArticleCategories() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Articlecategories>(`api/Article/GetArticleCategories?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleCategory = data as Articlecategories[];
                this.articleCategorylist = this.articleCategory.categories.split(',');
            }
            );
    }

    click(i) {
        // console.log(i)
    }

    // Coresponding Author
    getCorrespodingAuthor() {
        var articleId = localStorage.getItem('articleId')
        this.GetArticleAuthorListService.getCorrespondingAuthor<Articleauthorlist>(`api/Article/GetCorrospondingAuthor?articleId=${this.articleId}`)
            .subscribe((data) => {

                this.correspondingAuthordata = data

                if (this.correspondingAuthordata.corospondingAuthorId != null) {
                    this.getDataByid(this.correspondingAuthordata.corospondingAuthorId)
                }
            })

    }

    // Get Author Notes
    getAuthorNotes() {
        var articleId = localStorage.getItem('articleId')
        this.GetContributionsService
            .getAuthorNotes<any>(`api/Article/getnote?articleId=${this.articleId}`)
            .subscribe((data: any) => {
                this.authorNotesdata = data.modelNote
            });
    }

    // Article's coauthor
    getDataByid(authorId) {
        // var authorId = this.correspondingAuthordata.corospondingAuthorId
        this.GetArticleAuthorListService
            .getDatabyid<Articleauthorlist>(`api/Article/GetAuthorDatabyId?id=${authorId}`)
            .subscribe((data: any) => {
                this.dataByid = data as Articleauthorlist[]
                this.coFirstname = this.dataByid.firstName
                this.coLastName = this.dataByid.lastName
                this.coDegree = this.dataByid.degree
                this.coDepartment = this.dataByid.department
                this.coCity = this.dataByid.city
                this.coState = this.dataByid.state
                this.coCountry = this.dataByid.country
                this.coEmail = this.dataByid.email
                this.coInstitute = this.dataByid.institutionName
            });
    }

    afuConfig = {
        multiple: false,
        formatsAllowed: ".pdf",
        maxSize: "1",
        uploadAPI: {
            url: "https://example-file-upload-api",
            method: "POST",
            params: {
                'page': '1'
            },
            responseType: 'blob',
        },
        theme: "dragNDrop",
        hideProgressBar: false,
        hideResetBtn: false,
        hideSelectBtn: false,
        fileNameIndex: true,
        replaceTexts: {
            selectFileBtn: 'Select Files',
            resetBtn: 'Reset',
            uploadBtn: 'Upload',
            dragNDropBox: 'Drop file here',
            attachPinBtn: 'Attach Files...',
            afterUploadMsg_success: 'Successfully Uploaded !',
            afterUploadMsg_error: 'Upload Failed !',
            sizeLimit: 'Size Limit'
        }
    };
    // file uploader new
    uploader: FileUploader;
    hasBaseDropZoneOver: boolean;
    hasAnotherDropZoneOver: boolean;
    response: string;
    // file uploader new2
    uploader1: FileUploader;
    hasBaseDropZoneOver1: boolean;
    hasAnotherDropZoneOver1: boolean;
    response1: string;
    fileDescriptionData: any;
    public fileOverBase(e: any): void {
        this.hasBaseDropZoneOver = e;
    }

    public fileOverAnother(e: any): void {
        this.hasAnotherDropZoneOver = e;
    }

    // comment
    post() {
        this.show = true;
        this.post_comment.push(this.comment_content);
        this.comment_content = "";
    }

    // latest article
    getfullarticleinfo() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<any>(`api/Article/homearticledata?id=${this.articleId}`)
            .subscribe((data) => {
                this.fullarticle = data as any[];
                // console.log(this.fullarticle.categories)
                this.articletypesummary = this.fullarticle.articletypename
                this.articleReferencelist = this.fullarticle.refrence
                this.articleStudytitlelist = this.fullarticle.study_title
                this.articleAuthorList = this.fullarticle.author_list;
                this.articleHighlightsprior = this.fullarticle.priorKnowledge
                this.articleHighlightsfinding = this.fullarticle.findingOfStudy
                this.articleHighlightspracticle = this.fullarticle.impactOfStudy
                this.articleAbstractlist = this.fullarticle.article_abstract
                this.articleKeywordslist = this.fullarticle.keywords.replace(/,/g, ", ");
                this.key = this.articleKeywordslist.split(', ');
                this.content = this._sanitizer.bypassSecurityTrustHtml(`${this.fullarticle.article_data}`);
                // this.fullarticle.article_data
                this.articleAcknowledgentData = this.fullarticle.acknowledgement
                this.isAcknowledgement = this.fullarticle.isack
                this.articleCategorylist = this.fullarticle.categories.split(',');
                this.articleEthicsStatementProtocol = this.fullarticle.animal_protocol_number
                this.articleEthicsStatementapprovalNumber = this.fullarticle.animal_approval_number
                this.articleEthicsStatementlocalAnimalCommitee = this.fullarticle.animal_ethic_committee
                this.articleEthicsStatementReviewBoard = this.fullarticle.human_review_board_name
                this.articleEthicsStatementA_Number = this.fullarticle.human_approval_number
                this.articleEthicsStatementstate = this.fullarticle.human_statement
                this.isHumanSubject = this.fullarticle.ishuman
                this.isAnimalSubject = this.fullarticle.isanimal
                this.articleCoipaymentData = this.fullarticle.coi_payment_description
                this.articleCoifinancialActivity = this.fullarticle.coi_financial_activity_description
                this.articleCoiOtherRelationships = this.fullarticle.coi_other_relationships
                this.isPayment = this.fullarticle.isPayment
                this.isFAavailable = this.fullarticle.isFinance
                this.isORavailable = this.fullarticle.isRelation
                this.coFullName = this.fullarticle.coresponding_author_fullname.split(" ");
                this.coFirstname = this.coFullName[ 0 ]
                this.coLastName = this.coFullName[ 1 ]
                this.coDegree = this.fullarticle.coresponding_author_degree
                this.coDepartment = this.fullarticle.coresponding_author_department
                this.coCity = this.fullarticle.coresponding_author_city
                this.coState = this.fullarticle.coresponding_author_state
                this.coCountry = this.fullarticle.coresponding_author_country
                this.coEmail = this.fullarticle.coresponding_author_email
                this.coInstitute = this.fullarticle.coresponding_author_institute
                this.publications = this.fullarticle.suplist;
                this.nonpublications = this.fullarticle.supnonlist;
                this.responsibility = this.fullarticle.coidocumentdata;
                this.articleMediaSeolist = this._sanitizer.bypassSecurityTrustHtml(`${this.fullarticle.articleMedia}`)
                this.getsimilararticle(this.key)
            }
            );
    }

    // Full article
    fullArticle(item) {
        var manuscriptId = item.articleDisplayId.substring(5) + '-' + item.articleTitle.replace(/\s+/g, '-')
        localStorage.setItem('articleId', item.articleId)
        const host: string = location.origin;
        const url: string = host + '/#' + String(this.router.createUrlTree([ '/articles', `${manuscriptId}` ], { state: item }));
        window.open(url, '_blank');
        // console.log(item)
    }

    //Add view count api
    countData(articleId) {
        this.Getcountarticle.Addcountarticle()
            .pipe(first())
            .subscribe(
                data => {
                    const host: string = location.origin;
                    const url: string = host + '/#' + String(this.router.createUrlTree([ '/articles' ], { state: data }));
                    window.open(url, '_blank');
                    // this.router.navigateByUrl('/articles', { state: data });
                    localStorage.setItem('Viewcount', data.noofview)
                },
                error => {
                }
            );
    }

    addUloadEvalution(item) {
        // console.log(item)
        this.materialdata = item._file.type
        var articleId = localStorage.getItem('articleId')
        var s3path = CONSTANT.s3path

        this.uploadService.uploadEvolutionS3(item._file);

        setTimeout(() => {
            var selectedFilenew = localStorage.getItem("currentTimeInSeconds")
            var data = `${s3path}/evolution/${this.articleId}/${selectedFilenew}`
            var reviewerId= this.userinfo.id;

            // var reviewerId = reviewer ? reviewer : null

            this.AddUploadEvalutionService.AddUploadevalution(this.articleId, reviewerId, this.materialdata, this.userinfo.fullName, data)
                .subscribe(
                    data => {
                        // console.log(data,"reviewerId")
                        // console.log(reviewer,"reviewer")
                        // console.log(reviewerId,"reviewerId")
                        this.getUploadEvalution()
                        item.remove()
                    },
                    error => {
                    }
                );
        }, 1000);
    }

    downloadDocument(item: string) {
        // console.log(item)
        // FileSaver.saveAs(item);
        window.open(item, '_blank');
    }

    // Get Upload Evalution
    getUploadEvalution() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle(`api/Article/GetReports?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleEvalution = data;
                this.articleEvalution.sort((a, b) => new Date(b.documentDate).getTime() - new Date(a.documentDate).getTime());
            }
            );
    }

    // Get Article Category
    getCategoryArticle(item) {
        this.GetArticlesCategory.getCategoryArticle<RecentArticle>(`api/Article/GetArticleByCategory?category=${item}&pageNo=1&pageSize=4`)
            .subscribe((data) => {
                this.categoriesbydata = data as RecentArticle[]
                this.articlePerpageItem = data.length
                this.categotyData(item)
            })
    }

    // Article Keywords
    getKeywordArticle(item) {
        this.GetArticlesCategory.getKeywordArticle<RecentArticle>(`api/Article/GetKeywordWiseArticle?keyword=${item}&pageNo=1&pageSize=4`)
            .subscribe((data) => {
                this.articlebydata = data as RecentArticle[]
                this.articlePerpageItem = data.length
                this.keywordData(item)
            })
    }

    // Add comment
    AddComment() {
        var articleId = localStorage.getItem('articleId')
        this.GetCommentService.AddComment(this.articleId, this.userinfo.id, this.formcontrol.comment.value)
            .pipe(first())
            .subscribe(
                data => {
                    this.matSnack.paperShowNotification('Thank you, Your comment is submitted for Review.', "")
                },
                error => {
                }
            );
        this.comment_content = "";
    }

    // Snackbar show
    openSnackBar(message: string, action: string) {
        this.snackBar.open(message, action, {
            duration: 5000,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            panelClass: [ 'green-snackbar' ]
        });
        this.comment_content = "";

    }

    // Comment without login
    AddCommentWithoutuser() {
        var articleId = localStorage.getItem('articleId')
        this.GetCommentService.AddComment(this.articleId, '', this.formcontrol.comment.value)
            .pipe(first())
            .subscribe(
                data => {
                    // this.openSnackBar('Thank you, Your comment is submitted for Review.', '')
                    this.matSnack.paperShowNotification('Thank you, Your comment is submitted for Review.', "")
                },
                error => {
                }
            );
    }

    // Get Comment article by ID
    GetCommentById() {
        var articleId = localStorage.getItem('articleId')
        this.GetCommentService.getCommentById<Comment>(`api/Article/CommentDisplayForArticle?articleId=${this.articleId}`)
            .subscribe((data: Comment[]) => {
                // this.openSnackBar('Thank you, Your comment is submitted for Review.', '')

                this.listofComment = data as Comment[]
                this.ls = this.listofComment.listOfComment
            })
    }

    // Get contribution
    getContributions() {
        var articleId = localStorage.getItem('articleId')
        this.GetContributionsService
            .getContributions<any>(`api/Article/GetArticleContributions?articleId=${this.articleId}`)
            .subscribe((data: any) => {
                if (data || data != null) {
                    // this.conceptualizations = data.conceptualization.replace(/,/g, ", ");
                    // this.methodologys = data.methodology.replace(/,/g, ", ");
                    // this.softwares = data.Software.replace(/,/g, ", ");
                    // this.validations = data.validation.replace(/,/g, ", ");
                    // this.analysiss = data.formalAnalysis.replace(/,/g, ", ");
                    // this.investigations = data.investigation.replace(/,/g, ", ");
                    // this.resources = data.resources.replace(/,/g, ", ");
                    // this.curations = data.dataCuration
                    // this.drafts = data.originalDraftPreparation
                    // this.reviews = data.reviewAndEditing.replace(/,/g, ", ");
                    // this.visualizations = data.visualization.replace(/,/g, ", ");
                    // this.supervisions = data.supervision.replace(/,/g, ", ");
                    // this.administrations = data.projectAdministration.replace(/,/g, ", ");
                    // this.fundings = data.fundingAcquisition.replace(/,/g, ", ");
                    if (data.conceptualization != null) {
                        this.conceptualizations = data.conceptualization.split(', ')
                        this.conceptualizations = data.conceptualization.replace(/,/g, ", ");
                    }
                    if (data.methodology != null) {
                        this.methodologys = data.methodology.split(', ')
                        this.methodologys = data.methodology.replace(/,/g, ", ");
                    }
                    if (data.software != null) {
                        this.softwares = data.software.split(', ')
                        this.softwares = data.Software.replace(/,/g, ", ");
                    }
                    if (data.validation != null) {
                        this.validations = data.validation.split(', ')
                        this.validations = data.validation.replace(/,/g, ", ");
                    }
                    if (data.formalAnalysis != null) {
                        this.analysiss = data.formalAnalysis.split(', ')
                        this.analysiss = data.formalAnalysis.replace(/,/g, ", ");
                    }
                    if (data.investigation != null) {
                        this.investigations = data.investigation.split(', ')
                        this.investigations = data.investigation.replace(/,/g, ", ");
                    }
                    if (data.resources != null) {
                        this.resources = data.resources.split(', ')
                        this.resources = data.resources.replace(/,/g, ", ");
                    }
                    if (data.dataCuration != null) {
                        this.curations = data.dataCuration.split(', ')
                        this.curations = data.dataCuration.replace(/,/g, ", ");
                    }
                    if (data.originalDraftPreparation != null) {
                        this.drafts = data.originalDraftPreparation.split(', ')
                        this.drafts = data.originalDraftPreparation.replace(/,/g, ", ");
                    }
                    if (data.reviewAndEditing != null) {
                        this.reviews = data.reviewAndEditing.split(', ')
                        this.reviews = data.reviewAndEditing.replace(/,/g, ", ");
                    }
                    if (data.visualization != null) {
                        this.visualizations = data.visualization.split(', ')
                        this.visualizations = data.visualization.replace(/,/g, ", ");
                    }
                    if (data.supervision != null) {
                        this.supervisions = data.supervision.split(',')
                        this.supervisions = data.supervision.replace(/,/g, ", ");
                    }
                    if (data.projectAdministration != null) {
                        this.administrations = data.projectAdministration.split(', ')
                        this.administrations = data.projectAdministration.replace(/,/g, ", ");
                    }
                    if (data.fundingAcquisition != null) {
                        this.fundings = data.fundingAcquisition.split(', ')
                        this.fundings = data.fundingAcquisition.replace(/,/g, ", ");
                    }
                }
                else {
                }
            });
    }

    // Add citation
    addCitation() {
        var articleId = localStorage.getItem('articleId')
        var formData: any = new FormData();
        formData.append("ArticleId", this.articleId);
        formData.append("Citation", this.editorForm.get('editorName').value);

        this.GetCitaionService.AddCitation(formData)
            .pipe(first())
            .subscribe(
                data => {
                    this.matSnack.paperShowNotification('Article Information/Citation Added', "")
                    this.getCitation()
                },
                error => {
                    // console.log(error);
                }
            );
    }

    // Get citation
    getCitation() {
        var articleId = localStorage.getItem('articleId')
        this.GetCitaionService
            .getCitation<any>(`api/Article/GetCitation?articleId=${this.articleId}`)
            .subscribe((data: any) => {
                this.citation = data.citation
            });
    }

    // Add citation
    addFooter() {
        var articleId = localStorage.getItem('articleId')
        var formData: any = new FormData();
        formData.append("ArticleId", this.articleId);
        formData.append("Citation", this.editorForm.get('editorName').value);
        this.GetCitaionService.AddFooter(articleId,this.editorForm.get('footerDetails').value)
            .pipe(first())
            .subscribe(
                data => {
                    // console.log(data, "footer Add")
                    this.matSnack.paperShowNotification('Footer Information Added', "")
                    this.getFooter()
                },
                error => {
                    // console.log(error);
                }
            );
    }

    // Get citation
    getFooter() {
        var articleId = localStorage.getItem('articleId')
        this.GetCitaionService
            .getFooter<any>(`api/Article/getArticleFooter?articleId=${this.articleId}`)
            .subscribe((data: any) => {
                // console.log(data, "footer Get")
                this.footer = data.footer
            });
    }

    // Get Media SEO
    getMediaorSEO() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<Mediaorseo>(`api/Article/getmedia?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.articleMediaorSeo = data as Mediaorseo[];
                this.articleMediaSeolist = this._sanitizer.bypassSecurityTrustHtml(`${this.articleMediaorSeo.mediaSeo}`)
            }
            );
    }

    // Get Count
    getViewCountanddate() {
        var articleId = localStorage.getItem('articleId')
        this.getsummaryservice.getArticle<any>(`api/Article/GetViewsAndReviewerData?articleId=${this.articleId}`)
            .subscribe((data) => {
                this.ViewCountanddate = data;
                // console.log(this.ViewCountanddate, "view Data")
                this.Viewcount = this.ViewCountanddate.totalViews
                this.totaldownload = this.ViewCountanddate.totaldownload
                this.reviewerAssignDate = this.ViewCountanddate.reviewerAssignDate
                this.reviewerConcludeDate = this.ViewCountanddate.reviewerConcludeDate
                this.volumearticle = this.ViewCountanddate.volume
                this.issuearticle = this.ViewCountanddate.issue
                this.articlePublishDate = this.ViewCountanddate.articlePublishDate
            }
            );

    }
    // Categories Article
    categotyData(item) {
        // this.router.navigateByUrl('/archives', { state: item });
        localStorage.setItem('articleTypecategory', item)
        // localStorage.setItem('totalNumberOfArticle', this.categoriesbydata[ 0 ].totalNumberOfArticle)
        localStorage.removeItem('articleTypekeyword')
        const host: string = location.origin;
        const url: string = host + '/#' + String(this.router.createUrlTree([ '/archives' ]));
        window.open(url, '_blank');
    }

    // Keywords Article
    keywordData(item) {
        // this.router.navigateByUrl('/archives', { state: item });
        localStorage.setItem('searchValueinhome', item)
        // localStorage.setItem('totalNumberOfArticle', this.articlebydata[ 0 ].totalNumberOfArticle)
        localStorage.removeItem('articleTypecategory')
        const host: string = location.origin;
        const url: string = host + '/#' + String(this.router.createUrlTree([ '/archives' ]));
        window.open(url, '_blank');

    }
    
    //API recent top articles 
    getArticle() {
        this.GetRecentArticles.getArticle<RecentArticle>(`api/Article/GetRecentArticles?pageNo=1&pageSize=4`)
            .subscribe((data) => {
                this.latestArticle = data as RecentArticle[];
                this.authorName = this.latestArticle.authorName
                // console.log(this.latestArticle)

            }
            );
    }

    // Similar Article
    getsimilararticle(articleKeyword) {
        articleKeyword = articleKeyword[ 0 ]
        // console.log(articleKeyword)
        this.GetArticlesCategory.getKeywordArticle<RecentArticle>(`api/Article/GetKeywordWiseArticle?keyword=${articleKeyword}&pageNo=1&pageSize=4`)
            .subscribe((data) => {

                this.similarArticle = data as RecentArticle[]
            })
    }
    togglePanel() {
        this.panelOpenState = !this.panelOpenState
    }

    // Blog click
    BlogPreview(element) {
        localStorage.setItem('blogId', element.blogId)
        var routeId = element.blogDisplayId.substring(9) + '-' + element.blogTitle.replace(/\s+/g, '-')
        const host: string = location.origin;
        const url: string = host + '/#' + String(this.router.createUrlTree([ '/blogs', `${routeId}` ], { state: element }));
        window.open(url, '_blank');
    }

    redirectOrcid(element) {
        // console.log('https://orcid.org/' + element)
        // window.location.href = 'https://orcid.org/' + element;
        window.open('https://orcid.org/' + element, '_blank');
    }

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    // generatePdf_new(data) {

    //     html2canvas(data, { allowTaint: true }).then(canvas => {
    //         var imgWidth = 210;
    //         let HTML_Width = canvas.width;
    //         let HTML_Height = canvas.height;
    //         var imgHeight = canvas.height * imgWidth / canvas.width;
    //         let top_left_margin = 15;
    //         let PDF_Width = HTML_Width + (top_left_margin * 2);
    //         let PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
    //         let canvas_image_width = HTML_Width;
    //         let canvas_image_height = HTML_Height;
    //         let totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
    //         canvas.getContext('2d');
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         let pdf = new jsPDF('p', 'pt', [ PDF_Width, PDF_Height ]);
    //         pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);
    //         for (let i = 1; i <= totalPDFPages; i++) {

    //             pdf.addPage([ PDF_Width, PDF_Height ], 'p');
    //             pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
    //         }
    //         pdf.save("Article.pdf");
    //     });
    // }
    generatePdf_new_final() {
        // this.spinner.show();
        const host: string = location.origin;
        const url: string = host + '/#' + String(this.router.createUrlTree([ '/download-pdf' ], { skipLocationChange: false }));
        // window.open(url, '_blank');
        var win = window.open(url,'width=500,height=200,left=375,top=330');
        // setTimeout(() => {
        //     win.close();
    
        //   }, 5000);

        // this.router.navigate([ `/download-pdf`]);
  //5s
        // setTimeout(() => {
        //     this.router.navigate([ `/pages/download-pdf`], { skipLocationChange: true });
        // }, 1000);
        // this.DownloadPdfComponent.generatePdf_new();
        // var element = document.getElementById("page");
        // html2canvas(data, {
        //     logging: false,
        //     scale: 3
        // }).then(function(canvas) {
        //     var pdf = new jspdf('p', 'mm', 'letter');//A4 paper, portrait
        //     var ctx = canvas.getContext('2d'),
        //         a4w = 195.9, a4h = 244.4,//A4 size, 215.9mm x 279.4mm, 10 mm margin on each side, display area 190x277
        //         imgHeight = Math.floor(a4h * canvas.width / a4w),//Convert pixel height of one page image to A4 display scale
        //         renderedHeight = 0;

        //     var logo = document.getElementById("logo");//Icon placed in header
        //     while (renderedHeight < canvas.height) {
        //         var page = document.createElement("canvas");
        //         page.width = canvas.width;
        //         page.height = Math.min(imgHeight, canvas.height - renderedHeight);//Maybe less than one page

        //         //Trim the specified area with getImageData and draw it into the canvas object created earlier
        //         page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
        //         //Add an image to the page with a 10 mm / 20 mm margin
        //         pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 20, a4w, Math.min(a4h, a4w * page.height / page.width));
        //         //Add header logo
        //         pdf.setTextColor(16, 5, 166);
        //         pdf.setFontSize(14)
        //         // pdf.text(10, 10, 'Research Update Journal of Medicine');
        //         pdf.addImage(logo, 'PNG', 5, 5);
        //         const addFooters = doc => {
        //             const pageCount = doc.internal.getNumberOfPages()

        //             for (var i = 1; i <= pageCount; i++) {
        //                 doc.setFontSize(8)
        //                 doc.setPage(i)
        //                 doc.text('Page No.' + String(i), 210, 274, {
        //                     align: 'right'
        //                 })
        //             }
        //         }
        //         renderedHeight += imgHeight;
        //         if (renderedHeight < canvas.height) {
        //             pdf.addPage();//Add an empty page if there is more to follow

        //         }

        //         addFooters(pdf)
        //         //   delete page;
        //     }

        //     pdf.save('Article.pdf');
        // });
    }



}

