import { Component, OnInit } from '@angular/core';

/*
*Footer component
*/
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

/*
*class: Footer
*Reference link: 
*/
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
