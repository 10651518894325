import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlogYearMonthListService {
  api: string = '';
  _apiEndPoint: string = '';

  constructor(private _http: HttpClient,) {
    this._apiEndPoint = environment.Url;


  }

  getBlogList<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
  }


  getBlogListYearWise<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
  }
}
