<div class="background" [ngClass.lt-md]="'padding-top-sm'" [ngClass.md]="'padding-top-sm'">
    <div fxLayout="row" fxLayoutAlign="space-around center" class="padding-header" >
        <mat-card class="card-width" fxFlex.gt-md="25%" fxFlex.md="50%" fxFlex.lt-md="90%">
            <mat-card-content fxLayout="column" fxLayoutAlign="space-around center" fxLayoutGap="5%">
                <h2 class="title">Change Password </h2>

                <div style="width: 100%;">
                    <form [formGroup]="ChangepasswordFormGroup" fxLayout="column" fxLayoutGap="15px">

                        <!-- Current password field -->
                        <!-- <mat-form-field appearance="outline">
                            <mat-label>Current Password</mat-label>
                            <input formControlName="password" [type]="hideold ? 'password' : 'text'"
                                id="currentpassword" matInput required>
                            <mat-icon matPrefix>lock</mat-icon>
                            <mat-icon class="color-secondary" matSuffix (click)="hideold = !hideold">
                                {{hideold ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                            <mat-error>Current Password Required</mat-error>
                        </mat-form-field> -->

                        <!-- New password field -->
                        <mat-form-field appearance="outline">
                            <mat-label>New Password</mat-label>
                            <input formControlName="newpassword" [type]="hide ? 'password' : 'text'" id="password"
                                matInput required>
                            <mat-icon matPrefix>lock</mat-icon>
                            <mat-icon class="color-secondary" matSuffix (click)="hide = !hide">
                                {{hide ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                            <!-- <mat-error>New Password Required</mat-error> -->
                            <mat-error *ngIf="txtPassword.errors?.required" class="font-small">New Password required
                            </mat-error>
                            <mat-error *ngIf="txtPassword.errors?.pattern" class="font-small">At least 5 characters
                                in length Lowercase and Uppercase letters Numbers Special characters
                            </mat-error>
                        </mat-form-field>

                        <!-- Re-enter New password field -->
                        <mat-form-field appearance="outline">
                            <mat-label>Re-enter New Password</mat-label>
                            <input formControlName="renewpassword" [type]="hidecon ? 'password' : 'text'"
                                id="renewpassword" matInput required>
                            <mat-icon matPrefix>lock</mat-icon>
                            <mat-icon class="color-secondary" matSuffix (click)="hidecon = !hidecon">
                                {{hidecon ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                            <mat-error *ngIf="txtC_password.errors?.required">Re-enter New Password Required</mat-error>
                            <mat-error *ngIf="ChangepasswordFormGroup.get('renewpassword').hasError('mismatch')">
                                Password doesn't match
                            </mat-error>
                        </mat-form-field>


                        <!-- Change button -->
                        <div fxLayoutAlign="center" fxLayoutGap="12px" [style.margin-top]="'20px'">
                            <button mat-button mat-raised-button color="primary"
                                [disabled]="ChangepasswordFormGroup.invalid" fxFlex="100"
                                (click)="ChangePassword()">Change Password</button>
                        </div>
                    </form>
                </div>
            </mat-card-content>

        </mat-card>
    </div>
</div>