<div class="icon-bar" [ngClass.lt-sm]="'icon-barsm'">
  <!-- <p>
    <a href="#" class="facebook-hover"><i class="fa fa-facebook"></i></a>
  </p> -->
  <a href="https://www.facebook.com/sharer.php?u={{this.postUrl}}" class="facebook-hover" id="facebook-btn"
    target="_blank"><i class="fa fa-facebook"></i></a>
  <a href="https://www.linkedin.com/shareArticle?url={{this.postUrl}}&title={{this.postTitle}}" class="linkedin"
    id="linkedin-btn" target="_blank"><i class="fa fa-linkedin"></i></a>
  <a href="https://twitter.com/share?url={{this.postUrl}}&text={{this.postTitle}}" class="twitter" id="twitter-btn"
    target="_blank"><i class="fa fa-twitter"></i></a>
  <a href="mailto: support@annalsebm.org" class="google" target="_blank"><i class="fa fa-envelope"></i></a>
  <a href="https://wa.me/?text={{this.postTitle}} {{this.postUrl}}" class="youtube" id="whatsapp-btn" target="_blank"><i
      class="fa fa-whatsapp"></i></a>
</div>
<!-- <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://rujm.org&title={{this.postTitle}}"
    class="linkedin" id="linkedin-btn" target="_blank"><i class="fa fa-linkedin"></i></a> -->