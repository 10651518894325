<!-- <ckeditor [editor]="Editor" [config]="optionsNews"></ckeditor> -->

<angular-editor id="editor1" [config]="editorConfig" width='100%' height='500px'>
</angular-editor>
<!-- <quill-editor class="content-editor" [placeholder]="''" [modules]="quillConfiguration"
	[styles]="{'height': '500px','min-width': '100%'}">

</quill-editor> -->
<!-- <div>

	<div fxLayoutGap="20px" class="editor-button">
		<button mat-button (click)="execCom('bold')"><i class="fa fa-bold"></i></button>
		<button mat-button (click)="execCom('italic')"><i class="fa fa-italic"></i></button>
		<button mat-button (click)="execCom('justifyLeft')"><i class="fa fa-align-left"></i></button>
		<button mat-button (click)="execCom('justifyCenter')"><i class="fa fa-align-center"></i></button>
		<button mat-button (click)="execCom('justifyRight')"><i class="fa fa-align-right"></i></button>
		<button mat-button (click)="execCom('justifyFull')"><i class="fa fa-align-justify"></i></button>
		<button mat-button (click)="execComFormateh1('formatBlock', $event)">H1</button>
		<button mat-button (click)="execComFormateh2('formatBlock', $event)">H2</button>
		<button mat-button (click)="execCom('indent')"><i class="fa fa-indent"></i></button>
		<button mat-button (click)="execCom('outdent')"><i class="fa fa-dedent"></i></button>
		<button mat-button (click)="execCom('subscript')"><i class="fa fa-subscript"></i></button>
		<button mat-button (click)="execCom('superscript')"><i class="fa fa-superscript"></i></button>
		<button mat-button (click)="execCom('undo')"><i class="fa fa-undo"></i></button>
		<button mat-button (click)="execCom('redo')"><i class="fa fa-repeat"></i></button>
		<button mat-button (click)="execCom('insertUnorderedList')"><i class="fa fa-list-ul"></i></button>
		<button mat-button (click)="execCom('insertOrderedList')"><i class="fa fa-list-ol"></i></button>
		<button mat-button (click)="execCom('insertParagraph')"><i class="fa fa-paragraph"></i></button>
		<button mat-button (click)="execCom('insertHorizontalRule')">HR</button>
		<button mat-button (click)="execCommandWithArgLink('createLink', $event)"><i class="fa fa-link"></i></button>
		<button mat-button (click)="execCom('unlink')"><i class="fa fa-unlink"></i></button>
		<button mat-button (click)="execCommandWithArg('insertImage', $event)"><i
				class="fa fa-file-image-o"></i></button>

		<button mat-button (click)="execCom('selectAll')">Select All</button>
	</div>
	<div contenteditable="true" (input)="onInput($event.target.innerHTML)" class="editor" #editor>
		<img src="{{this.img}}" alt="" *ngIf="this.img" width="100" height="100">
	</div>

</div> -->