import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Summary } from '../../model/summary';
import { RestmanagerService } from '../../utils/restmanagerService';

@Injectable({
  providedIn: 'root'
})
export class SummaryService {
  api: string = '';
  _apiEndPoint: string = '';
  private restmanagerService: RestmanagerService;


  constructor(private _http: HttpClient, restmanagerService: RestmanagerService) {
    this._apiEndPoint = environment.Url;
    this.restmanagerService = restmanagerService;
  }

  getArticle<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
  }

}
