import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { first } from 'rxjs/operators';
import { ArticleauthorlistService } from 'src/app/shared/service/author/articleauthorlist.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: [ './delete.component.css' ]
})
export class DeleteComponent implements OnInit {

  local_data: any;
  action: any;
  authors: any;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: DeleteComponent,
    private GetArticleAuthorListService: ArticleauthorlistService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<DeleteComponent>,
  ) {

    this.local_data = { ...data };
  }

  ngOnInit(): void {
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [ 'redNoMatch' ]

    });
  }
  deleteAuthor() {
    var articleId = localStorage.getItem('articleId')
    var authorId = this.local_data.authorId
    this.GetArticleAuthorListService.deleteAuthorfromlist(articleId, authorId)
      .pipe(first())
      .subscribe(
        data => {
          // this.getauthorlist()
          // this.authors.splice(index, 1);
          this.openSnackBar('Author delete successfully', '')
          this.dialogRef.close({ data: this.local_data });
          window.location.reload()
        },
        error => {
        }
      );
  }
}
