<div >
    
    <div fxLayout="row" fxLayoutAlign="end" style="margin-bottom: 10px;">
        <!-- <img id="logo" src="assets/images/home/pdflogo.png"> -->
        <button mat-raised-button color="primary" (click)="download()">Download</button>
        <!-- (click)="download()" -->
    </div>
    <div class="box" id="contentToConvert" #contentToConvert>
        <div style="padding-left:10px">
            <h3 style="font-size: 20px; margin: 0; line-height:24px ; font-weight: 900; text-align: left; color:#006064;">
              Annals of Evidence-
             </h3>
             <h1
             style="font-size: 36px; line-height: 36px; margin: 0; font-weight: 600;text-align: left;color: #006064;">
            Based Medicine
             </h1>
        </div>
        <div>
            <h3
            style="font-size: 20px; text-transform: uppercase; line-height:24px ; margin: 0; font-weight: 400; text-align: left; color:#fff; background: #006064; padding: 5px 10px;">
            Online MenuScript Submission
            </h3>            
        </div>
        <div>
          <div fxLayoutAlign="center center" style="padding: top 10px;">
              <h2 class="black-color">Payment Receipt </h2>
          </div>  
        </div>

      <div class="hero" *ngIf='this.BillingTransacationId == null'>
            <!-- Author Details -->
        <div style="padding-left:10px;padding-bottom: 10px;">
            <b class="black-color">Author's Details</b> 
            <div fxLayout="column" >
                <span>{{this.Name}}, {{this.authorDegree}}, {{this.authorEmail}},</span>
                <span>{{this.department}}, </span>
                <span>{{ this.institute}}, {{this.city_author}}, {{this.state_author}}, {{this.country_author}}</span>
            </div>
         </div>
         
         <div>
             <mat-divider></mat-divider>
         </div>
         <!-- Manuscript Details -->
         <div style="padding-left:10px;padding-top: 10px;padding-bottom: 10px;" >
            <b class="black-color">Manuscript's Details</b> 
            <div fxLayout="column" >
               <span fxLayout="row">
                   <!-- Manuscript Id -->
                 <span fxFlex="20">Manuscript Number:</span>
                 <span>{{this.manuscript}}</span>
               </span>
               <span fxLayout="row">
                   <!-- Manuscript Id -->
                 <span fxFlex="20">Manuscript Title:</span>
                 <span>{{this.manuscripttitle}}</span>
               </span>
               <!-- Date Publish -->
               <span fxLayout="row">
                 <p fxFlex="20">Date Publish:</p>
                 <p>{{this.datePublish|date: 'MMM d, y'}}</p>
               </span>
               <!-- Volume -->
               <span fxLayout="row" fxLayoutGap="10px">
                <span fxLayout="row" fxLayoutGap="10px">
                  <p>Volume:</p>
                  <p>{{this.volume}},</p>
                </span>
                <!-- Issue -->
                <span fxLayout="row" fxLayoutGap="10px">
                  <p >Issue:</p>
                  <p>{{this.issue}}</p>
                </span>
               </span>
            </div>
         </div>
         <div>
             <mat-divider></mat-divider>
         </div>
         <!-- Invoice Details -->
         <div style="padding-left:10px;padding-top:10px;padding-bottom: 10px;">
             <b class="black-color">Billing Details</b> 
             <div fxLayout="column" >
                 <!-- Invoice Id -->
               <span fxLayout="row">
                 <p fxFlex="20">Invoice Number:</p>
                 <p>{{this.manuscript}}</p>
               </span>
                 <!-- Date Invoice -->
               <span fxLayout="row">
                 <p fxFlex="20">Date Invoice:</p>
                 <p>{{this.dateInvoiced|date: 'MMM d, y'}}</p>
               </span>
               <!-- Amount -->
               <span fxLayout="row">
                 <p fxFlex="20">Amount:</p>
                 <p>${{this.amount}}</p>
               </span>
               <!-- Promo Code -->
               <span fxLayout="row">
                 <p fxFlex="20">Promo Code:</p>
                 <p *ngIf ="this.PromoCodeAmount != null">${{this.PromoCodeAmount}} ({{this.promoCode}})</p>
                 <p *ngIf ="this.PromoCodeAmount == null">NA</p>
               </span>
               <!-- Tax -->
               <span fxLayout="row">
                 <p fxFlex="20">Tax:</p>
                 <p>${{this.Tax}} (3% Tax)</p>
               </span>
               <!-- Total -->
               <span fxLayout="row">
                 <p fxFlex="20">Total:</p>
                 <p>${{this.totalPrice}}</p>
               </span>
               <!-- status -->
               <span fxLayout="row">
                 <p fxFlex="20">Status:</p>
                 <p>{{this.status}}</p>
               </span>
             </div>
          </div> 
          <div>
              <mat-divider></mat-divider>
          </div> 
          
         <!-- Billing  Details -->
         <div style="padding-left:10px;padding-top:10px;padding-bottom:10px;">
             <b class="black-color">Credit/Debit Card Details</b> 
             <div fxLayout="column" *ngIf='this.BillingTransacationId != null'>
                 <span *ngIf='this.BillingName == null'>{{this.BillingName}}</span>
                 <span *ngIf='this.BillingName == null'>{{this.BillingDepartment}},  </span>
                 <span *ngIf='this.BillingName == null'>{{this.BillingInstitute}},</span>
                 <span *ngIf='this.BillingName == null'>{{this.BillingAddress}}, {{this.BillingCity}}, {{this.BillingState}}, {{this.BillingCountry}}</span>
                 <span *ngIf='this.BillingName == null'>{{this.BillingCardNumber}}</span>
                 <span>{{this.BillingMM}}/{{this.BillingYY}},</span>
                 <span>{{this.BillingPostal}},</span>
                 <span>TRANSACTION ID: {{BillingTransacationId}}</span>
             </div>
             <div fxLayout="column" *ngIf='this.BillingTransacationId == null' class="pending">
                <h2 fxLayoutAlign="start center">PENDING</h2>
             </div>
          </div>
          <!-- <div>
              <mat-divider></mat-divider>
          </div> -->

      </div>
      <div class="hero_paid" *ngIf='this.BillingTransacationId != null'>
            <!-- Author Details -->
        <div style="padding-left:10px;padding-bottom: 10px;">
            <b class="black-color">Author's Details</b> 
            <div fxLayout="column" >
                <span>{{this.Name}}, {{this.authorDegree}}, {{this.authorEmail}},</span>
                <span>{{this.department}}, </span>
                <span>{{ this.institute}}, {{this.city_author}}, {{this.state_author}}, {{this.country_author}}</span>
            </div>
         </div>
         
         <div>
             <mat-divider></mat-divider>
         </div>
         <!-- Manuscript Details -->
         <div style="padding-left:10px;padding-top: 10px;padding-bottom: 10px;" >
            <b class="black-color">Manuscript's Details</b> 
            <div fxLayout="column" >
               <span fxLayout="row">
                   <!-- Manuscript Id -->
                 <span fxFlex="20">Manuscript Number:</span>
                 <span>{{this.manuscript}}</span>
               </span>
               <span fxLayout="row">
                   <!-- Manuscript Id -->
                 <span fxFlex="20">Manuscript Title:</span>
                 <span>{{this.manuscripttitle}}</span>
               </span>
               <!-- Date Publish -->
               <span fxLayout="row">
                 <p fxFlex="20">Date Publish:</p>
                 <p>{{this.datePublish|date: 'MMM d, y'}}</p>
               </span>
               <!-- Volume -->
               <span fxLayout="row" fxLayoutGap="10px">
                <span fxLayout="row" fxLayoutGap="10px">
                  <p>Volume:</p>
                  <p>{{this.volume}},</p>
                </span>
                <!-- Issue -->
                <span fxLayout="row" fxLayoutGap="10px">
                  <p >Issue:</p>
                  <p>{{this.issue}}</p>
                </span>
               </span>
            </div>
         </div>
         <div>
             <mat-divider></mat-divider>
         </div>
         <!-- Invoice Details -->
         <div style="padding-left:10px;padding-top:10px;padding-bottom: 10px;">
             <b class="black-color">Billing Details</b> 
             <div fxLayout="column" >
                 <!-- Invoice Id -->
               <span fxLayout="row">
                 <p fxFlex="20">Invoice Number:</p>
                 <p>{{this.manuscript}}</p>
               </span>
                 <!-- Date Invoiced -->
               <span fxLayout="row">
                 <p fxFlex="20">Date Invoice:</p>
                 <p>{{this.dateInvoiced|date: 'MMM d, y'}}</p>
               </span>
               <!-- Amount -->
               <span fxLayout="row">
                 <p fxFlex="20">Amount:</p>
                 <p>${{this.amount}}</p>
               </span>
               <!-- Promo Code -->
               <span fxLayout="row">
                 <p fxFlex="20">Promo Code:</p>
                 <p *ngIf ="this.PromoCodeAmount != null">${{this.PromoCodeAmount}} ({{this.promoCode}})</p>
                 <p *ngIf ="this.PromoCodeAmount == null">NA</p>
               </span>
               <!-- Tax -->
               <span fxLayout="row">
                 <p fxFlex="20">Tax:</p>
                 <p>${{this.Tax}} (3% Tax)</p>
               </span>
               <!-- Total -->
               <span fxLayout="row">
                 <p fxFlex="20">Total:</p>
                 <p>${{this.totalPrice}}</p>
               </span>
               <!-- status -->
               <span fxLayout="row">
                 <p fxFlex="20">Status:</p>
                 <p>{{this.status}}</p>
               </span>
             </div>
          </div> 
          <div>
              <mat-divider></mat-divider>
          </div> 
          
         <!-- Billing  Details -->
         <div style="padding-left:10px;padding-top:10px;padding-bottom:10px;">
             <b class="black-color">Credit/Debit Card Details</b> 
             <div fxLayout="column" *ngIf='this.BillingTransacationId != null'>
                 <span *ngIf='this.BillingName != null'>{{this.BillingName}},</span>
                 <span *ngIf='this.BillingName != null'>{{this.BillingDepartment}},  </span>
                 <span *ngIf='this.BillingName != null'>{{this.BillingInstitute}},</span>
                 <span *ngIf='this.BillingName != null'>{{this.BillingAddress}}, {{this.BillingCity}}, {{this.BillingState}}, {{this.BillingCountry}}</span>
                 <span *ngIf='this.BillingName != null'>XXXX-XXXX-XXXX-{{this.BillingCardNumber}},</span>
                 <!-- <span>{{this.BillingMM}}/{{this.BillingYY}},</span> -->
                 <span>{{this.BillingPostal}},</span>
                 <span>TRANSACTION ID: {{BillingTransacationId}}</span>
             </div>
             <div fxLayout="column" *ngIf='this.BillingTransacationId == null'>
                <h2 fxLayoutAlign="start center">PENDING</h2>
             </div>
          </div>
          <!-- <div>
              <mat-divider></mat-divider>
          </div> -->

      </div>
      <div>
        <mat-divider></mat-divider>
      </div> 
    
    <div style="padding-top:10px;padding-bottom:10px;" fxLayout="column" fxLayoutAlign="space-around center">
      <p>Customer Support</p>
      <p style="color: #006064;">support@annalsebm.org</p>
      <p>Annals of Evidence Based Medicine</p>
      <p>Copyright &#169;
          By Research Update Organization. All Rights Reserved.</p>
  </div>
</div>
</div>