
<div class="padding" [ngClass.lt-md]="'padding-top-sm'">
<mat-card>
    <mat-card-header>
        <mat-card-title class="font-georgia mat-headline">Feature is available on Laptop Only.</mat-card-title>
       
    </mat-card-header>

</mat-card>


</div>

