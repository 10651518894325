<div class="footer" fxLayout="column">
    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start start" fxLayoutAlign.lt-md="start center"
        class="padding-top" fxLayoutGap="20px" fxFlex="grow">
        <div fxLayout="column" fxLayoutGap="0px" fxLayoutAlign="center center" fxFlex="33">

            <!-- Website Logo -->
            <div class="footer-logo">
                <img src="assets/images/home/logo_footer.png" alt="">
            </div>

            <div style="padding-top:2%">
                <p class="mat-headline font-georgia">Get Connected</p>
            </div>

            <!-- Social Links -->
            <div fxLayout="row">
                <a class="fa fa-facebook-square color-white f-hover" href="https://www.facebook.com/TheRUJM/" target="_blank" aria-hidden="true"></a>
                <a class="fa fa-youtube-play color-white y-hover" href="https://www.youtube.com/channel/UCJ2hC8xvQhqAlyh0X7Uwl1A" target="_blank" aria-hidden="true"></a>
                <a class="fa fa-linkedin-square color-white l-hover" aria-hidden="true" href="www.linkedin.com/in/The-RUJM"
                target="_blank"></a>
                <a class="fa fa-twitter-square color-white t-hover" href="https://twitter.com/The_RUJM" target="_blank" aria-hidden="true"></a>
                <a class="fa fa-envelope color-white e-hover"   href="mailto: support@annalsebm.org" target="_blank" aria-hidden="true"></a>
            </div>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="start " fxLayout.lt-lt="column"
             fxFlex="65" fxLayoutGap.lt-md="25px">
            <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="20px" fxFlex="50">
                <!-- Quick Links -->
                <div fxLayout="row">
                    <p class="mat-headline font-georgia">Quick Links</p>
                </div>
                <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="5px">
                    <a class="mat-title color-hover no-margin" [routerLink]="['/submission/Gettingstarted']">
                        Submit Article
                    </a>
                    <a class="mat-title color-hover no-margin" [routerLink]="['/login']">
                        Sign In/Sign Up
                    </a>
                    <a class="mat-title color-hover no-margin" [routerLink]="['/about-this-journal']">
                        About Journal
                    </a>
                    <a class="mat-title color-hover no-margin" [routerLink]="['/archives']">
                        Archives
                    </a>
                    <a href="https://researchupdate.org/" target="_blank" class="mat-title no-margin text-center ">
                        Research Update Organization
                    </a>
                </div>

            </div>
            <div fxLayout="column" fxLayoutAlign="start center" fxFlex="50" fxLayoutGap="20px">
                <div fxLayout="column" fxLayoutAlign="start" fxLayoutGap="20px">

                    <div fxLayout="row" fxLayoutAlign="center" fxLayoutAlign.lt-md="center">
                        <p class="mat-headline font-georgia">Need Support?</p>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="5px"
                        fxLayoutAlign.lt-md=" center">
                        <!-- Customer Support -->
                        <p class="mat-title">Customer Support</p>
                        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <i class="fa fa-envelope" aria-hidden="true"></i>
                            <p class="mat-title">support@annalsebm.org</p>
                        </div>
                        <!-- <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
                            <i class="fa fa-whatsapp" aria-hidden="true"></i>
                            <p class="mat-title">+1 (201) 936-6715</p>
                        </div> -->
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center center"
                        class="support-work" fxLayoutGap="10px">
                        <!-- Donation -->
                        <p class="mat-headline font-georgia" [ngClass.lt-md]="'text-center'">Support Our Work</p>
                        <p class="mat-title color-hover" [routerLink]="['/donation']">Donation</p>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutAlign.lt-md="center center"
                        class="support-work" fxLayoutGap="10px">
                        <!-- Donation -->
                        <p class="mat-headline font-georgia" [ngClass.lt-md]="'text-center'">Location</p>
                        <p class="mat-title text-center" [ngClass.lt-md]="'text-center'">Texas Medical Center, Houston, <br> TX 77030, USA</p>
                    
                    </div>
                </div>

                <p class="mat-title"></p>

            </div>
        </div>
    </div>

    <!-- rights and Privacy Policy -->
    <div fxLayout="row" class="privacy-policy" [ngClass.lt-md]="{'privacy-policy':false}" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayout.lt-sm="column" fxLayoutAlign="center center" fxLayoutAlign.lt-sm="center center" fxLayoutGap="5px">
            <p  fxLayoutAlign.lt-sm="center center" class="mat-title " [ngClass.lt-sm]="'text-center'">
                © 2023 Annals of Evidence-Based Medicine. <br fxHide fxShow.lt-sm>All Rights Reserved.
            </p>
            <a class="mat-title  color-hover " [routerLink]="['/privacy-policy']">
                Privacy Policy
            </a>
        </div>
    </div>
</div>