<div class="background" fxLayout.lt-md="column" fxLayoutAlign.md="space-around center"
    fxLayoutAlign.lt-sm="space-around stretch" ngClass.lt-md="padding-header-responsive"
    ngClass.md="padding-header-responsive">
    <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutAlign.md="space-around center"
        ngClass.gt-md="padding-header">
        <mat-card fxFlex.gt-md="30%" fxFlex.md="50%" fxFlex.lt-md="90%">
            <mat-card-content fxLayout.gt-md="column" fxLayout.lt-md="column" fxLayoutGap="25px">
                <h2 class="title" fxLayoutAlign="center center">OTP VERIFY </h2>

                <span class="aligned-with-icon-relative">
                    We send OTP on Your email <span class="color-primary font-bold">{{this.email}}</span>
                </span>
                <div class="full-width" fxLayout="column" fxLayoutAlign="center center">

                    <ng-otp-input inputmode="tel" #ngOtpInput (onInputChange)="onOtpChange($event)"
                        [config]="{length:5,allowNumbersOnly: true,isPasswordInput: false,disableAutoFocus: false}">
                    </ng-otp-input>

                    <span fxLayoutAlign="center" class="spacediv otp-box color-primary countdown-color">
                        <countdown #countdown (event)="onEvent($event)" [config]="{ leftTime: 30, format: 'mm:ss' }">
                        </countdown>
                    </span>

                </div>

                <div fxLayout="row" fxLayoutAlign="center">
                    <button mat-raised-button color="primary" fxFlex="90" (click)="Send()"
                        [disabled]="this.selectedValue != 0">Resend</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>