import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WebsiteLayoutComponent } from './components/website-sidenav/website-layout/website-layout.component';
import { ArticlesComponent } from './components/website/articles/articles.component';
import { BlogPreviewComponent } from './components/website/blog-preview/blog-preview.component';
import { HomeComponent } from './components/website/home/home.component';
import { UpdateProfileComponent } from './components/website/updateProfile/updateProfile.component';
import { AuthorInvoiceComponent } from './shared/component/author-invoice/author-invoice.component';
import { ChangePasswordComponent } from './shared/component/change-password/change-password.component';
import { ForgotPasswordChangeComponent } from './shared/component/forgot-password-change/forgot-password-change.component';
import { ForgotPasswordComponent } from './shared/component/forgot-password/forgot-password.component';
import { OtpVerifyComponent } from './shared/component/otp-verify/otp-verify.component';
import { ScreenSizeComponent } from './shared/component/screen-size/screen-size.component';
import { SessionExpireComponent } from './shared/component/session-expire/session-expire.component';
import { SuggestedreviewerComponent } from './shared/component/suggestedreviewer/suggestedreviewer.component';
import { AuthGuard } from './shared/helpers/auth.guard';
import { BlankLayoutComponent } from './shared/layout/blank-layout/blank-layout.component';
import { ArticlePreviewComponent } from './components/PaperSubmission/article-preview/article-preview.component';
import { DownloadPdfComponent } from './components/website/articles/download-pdf/download-pdf.component';
import { InvoiceByColleagueComponent } from './shared/component/invoice-by-colleague/invoice-by-colleague.component';
import { PayInvoiceComponent } from './shared/component/pay-invoice/pay-invoice.component';

const routes: Routes = [];
@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        { path: '', redirectTo: '/home', pathMatch: 'full' },

        { path: '', loadChildren: () => import('./components/website/website.module').then(m => m.WebsiteModule) },
        { path: 'admin', loadChildren: () => import('./components/Admin/admin.module').then(m => m.AdminModule) },
        { path: 'dashboard', loadChildren: () => import('./components/Dashboard/dashboard.module').then(m => m.DashboardModule) },
        { path: 'submission', loadChildren: () => import('./components/PaperSubmission/papersubmission.module').then(m => m.PapersubmissionModule) },
        {
          path: '', component: BlankLayoutComponent, children: [
            { path: 'myprofile', component: UpdateProfileComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
            { path: 'change-password', component: ChangePasswordComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
            { path: 'invoice', component: AuthorInvoiceComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
            { path: 'payInvoice', component: PayInvoiceComponent, pathMatch: 'full', canActivate: [ AuthGuard ] },
            { path: 'articles/:id', component: ArticlesComponent, pathMatch: 'full' },
            { path: 'blogs/:id', component: BlogPreviewComponent, pathMatch: 'full' },
            { path: 'article-preview', component: ArticlePreviewComponent, pathMatch: 'full' },


          ]
        },
        {
          path: 'pages', component: WebsiteLayoutComponent, children: [
            { path: 'forgot-password', component: ForgotPasswordComponent, pathMatch: 'full' },
            { path: 'otp-verify', component: OtpVerifyComponent, pathMatch: 'full' },
            { path: 'changepassword', component: ForgotPasswordChangeComponent, pathMatch: 'full' },
            { path: 'suggest-reviewer', component: SuggestedreviewerComponent, pathMatch: 'full' },
            { path: 'session-Expired', component: SessionExpireComponent, pathMatch: 'full' },
            { path: 'error', component: ScreenSizeComponent, pathMatch: 'full' },
            { path: 'SharedInvoice', component: InvoiceByColleagueComponent, pathMatch: 'full' },

          ]
        }
      ],
      { useHash: true, anchorScrolling: 'enabled' }
    ),
  ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }