<div class="background top-padding primary-font">
    <div fxLayout="row" class="padding-header" fxLayoutAlign="space-around center">
        <mat-card class="card-width" fxFlex.gt-md="50%" fxFlex.lt-md="100%">
            <mat-card-title class="title">
                <span fxLayoutAlign="center">Invoice</span>
            </mat-card-title>
            <mat-card-content>

                <div class="top-padding">
                    <div class="list">
                        <table style="width: 100%" mat-table [dataSource]="dataSource" class="mat-elevation-z8 demo-table">

                            <!-- date_invoice Column -->
                            <ng-container matColumnDef="date_invoice">
                                <th mat-header-cell *matHeaderCellDef>Date Invoice</th>
                                <td mat-cell *matCellDef="let element">{{element.dateofInvoice| date: 'MMM d, y'}}</td>
                            </ng-container>

                            <!-- date_publish Column -->
                            <ng-container matColumnDef="date_publish">
                                <th mat-header-cell *matHeaderCellDef>Date Published</th>
                                <td mat-cell *matCellDef="let element">{{element.publishDate| date: 'MMM d, y'}}</td>
                            </ng-container>

                            <!-- volume Column -->
                            <ng-container matColumnDef="volume">
                                <th mat-header-cell *matHeaderCellDef>Volume</th>
                                <td mat-cell *matCellDef="let element">{{element.volume}}</td>
                            </ng-container>

                            <!-- issue Column -->
                            <ng-container matColumnDef="issue">
                                <th mat-header-cell *matHeaderCellDef>Issue</th>
                                <td mat-cell *matCellDef="let element">{{element.issue}}</td>
                            </ng-container>

                            <!-- manuscriptID Column -->
                            <ng-container matColumnDef="manuscriptID">
                                <th mat-header-cell *matHeaderCellDef [ngClass]="'w-25'">Manuscript /Invoice Number
                                </th>
                                <td mat-cell *matCellDef="let element" [ngClass]="'w-25'">{{element.articleDisplayId}}</td>
                            </ng-container>

                            <!-- title Column -->
                            <ng-container matColumnDef="title">
                                <th mat-header-cell *matHeaderCellDef [ngClass]="'w-100 '">Title</th>
                                <td mat-cell *matCellDef="let element" [ngClass]="'w-100  title-justify'">
                                    <a class="color-blue title-hover ellips-line" target="_blank"
                                        (click)="preview(element)">
                                        {{element.title}}
                                    </a>
                                </td>
                            </ng-container>

                            <!-- Cor_author Column -->
                            <ng-container matColumnDef="Cor_author">
                                <th mat-header-cell *matHeaderCellDef [ngClass]="'w-110'">Corresponding Author
                                    <br>(Email)
                                </th>
                                <td mat-cell *matCellDef="let element">{{element.authorName}}</td>
                            </ng-container>

                            <!-- amount Column -->
                            <ng-container matColumnDef="amount">
                                <th mat-header-cell *matHeaderCellDef>Amount</th>
                                <td mat-cell *matCellDef="let element">$ {{element.amount}}</td>
                            </ng-container>

                            <!-- paymentStatus Column -->
                            <ng-container matColumnDef="paymentStatus">
                                <th mat-header-cell *matHeaderCellDef>Payment Status</th>
                                <td mat-cell *matCellDef="let element">{{element.status}}</td>
                            </ng-container>

                            <!-- Receipt Column -->
                            <ng-container matColumnDef="receipt">
                                <th mat-header-cell *matHeaderCellDef>Receipt</th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button aria-label="Download" (click)="download(element)"
                                        [disabled]="element.isManagingEditorUploadReceipt === false">
                                        <mat-icon>download</mat-icon>
                                    </button>
                                </td>
                            </ng-container>


                            <!-- Action Column -->
                            <ng-container matColumnDef="action">
                                <th mat-header-cell *matHeaderCellDef>Action</th>
                                <td mat-cell *matCellDef="let element">
                                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
                                        <button color="warn" mat-raised-button (click)="pay(element)"
                                        [disabled]="element.status== 'Paid'" [disabled]="true">Pay</button>
                                    </div>
                                </td>
                            </ng-container>

                            <!-- Action Column -->
                            <ng-container matColumnDef="share">
                                <th mat-header-cell *matHeaderCellDef>Share Invoice/ Payment Receipt</th>
                                <td mat-cell *matCellDef="let element">
                                    <button mat-icon-button aria-label="Download" (click)="share(element)"
                                    [disabled]="element.isManagingEditorUploadReceipt === false" [disabled]="true">
                                    <mat-icon>share</mat-icon>
                                </button>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>

                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>