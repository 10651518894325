<div class="background" fxLayout.lt-md="column" fxLayoutAlign.md="space-around center"
    fxLayoutAlign.lt-sm="space-around stretch" ngClass.lt-md="padding-header-responsive"
    ngClass.md="padding-header-responsive">
    <div fxLayout="row" fxLayoutAlign="space-around center" 
        ngClass.gt-md="padding-header">
        <mat-card fxFlex.gt-md="25%" fxFlex.md="90%" fxFlex.lt-md="90%">
            <mat-card-content fxLayout.gt-md="column" fxLayout.lt-md="column" fxLayoutAlign.gt-md="space-around center"
                fxLayoutGap="5%">
                <h2 class="title">Forgot Password </h2>

                <div class="full-width">
                    <form [formGroup]="ForgotpasswordFormGroup" fxLayout="column">

                        <!-- EMail field -->
                        <mat-form-field appearance="outline">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" type="text" id="email" placeholder="Enter email"
                                required>
                            <mat-icon matPrefix>email</mat-icon>
                            <mat-error> Email required
                            </mat-error>
                        </mat-form-field>



                        <!-- Send Otp button -->
                        <div fxLayoutAlign="center" fxLayoutGap="12px" [style.margin-top]="'20px'">
                            <button mat-button mat-raised-button color="primary"
                                [disabled]="ForgotpasswordFormGroup.invalid" fxFlex="100"
                                (click)="forgotPassword()">Send Password</button>
                        </div>



                    </form>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>