<h1 mat-dialog-title *ngIf="isAddMode" style="text-align: center;font-family: Verdana, Geneva, Tahoma, sans-serif;color: #006064; ">
    <b>{{addauthorheading}}</b>
</h1>
<h1 mat-dialog-title *ngIf="!isAddMode" style="text-align: center;font-family: Verdana, Geneva, Tahoma, sans-serif;color: #006064; ">
    <b>{{addauthorheading}}</b>
</h1>
<section>
    <form [formGroup]="form">
        <!-- Dialog Content -->

        <mat-dialog-content>
            <!-- First Name,Last Name -->
            <div fxLayout="row" fxLayoutGap="5px">
                <!-- First Name -->
                <div fxLayoutAlign.lt-sm="start stretch" fxFlex>
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{firstnamelabel}}</mat-label>
                        <input matInput type="text" required formControlName="f_name" class="form-control"
                            [(ngModel)]="this.local_data.firstName"
                            [ngClass]="{ 'is-invalid': submitted && f.f_name.errors }" maxlength="256">
                        <mat-error>{{firstnamerequired}}</mat-error>
                    </mat-form-field>
                </div>

                <!-- Last Name -->
                <div fxLayoutAlign.lt-sm="start stretch" fxFlex>
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{lastnamelabel}}</mat-label>
                        <input matInput required type="text" formControlName="l_name" class="form-control"
                            [(ngModel)]="this.local_data.lastName"
                            [ngClass]="{ 'is-invalid': submitted && f.l_name.errors }" maxlength="256">
                        <mat-error>{{lastnamerequired}}</mat-error>
                    </mat-form-field>
                </div>
            </div>

            <div fxLayout="row" fxLayoutGap="5px">
                <!-- Degree -->
                <div fxLayoutAlign.lt-sm="start stretch" fxFlex>
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{degreelabel}}</mat-label>
                        <input placeholder="Degree 1, Degree 2" matInput required type="text" formControlName="degree"
                            [(ngModel)]="this.local_data.degree" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.l_name.errors }" maxlength="256">
                        <mat-hint>{{degreehint}}</mat-hint>
                        <mat-error>{{degreerequired}}</mat-error>
                    </mat-form-field>
                </div>

                <!-- Orcid-->
                <div fxLayoutAlign.lt-sm="start stretch" fxFlex>
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>ORCID</mat-label>
                        <input placeholder="Enter ORCID here" matInput type="text" formControlName="orcid"
                            [(ngModel)]="this.local_data.orcid" class="form-control"
                             maxlength="256">
                        <!-- <mat-hint>{{degreehint}}</mat-hint> -->
                        <!-- <mat-error>{{degreerequired}}</mat-error> -->
                    </mat-form-field>
                </div>
            </div>

            <!--Email  -->
            <div fxLayoutAlign.lt-sm="start stretch">
                <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{emaillabel}}</mat-label>
                    <input matInput type="email" formControlName="email" class="form-control" required
                        [(ngModel)]="this.local_data.email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                        maxlength="256">
                    <mat-error *ngIf="txtEmail.errors?.required"> Email required
                    </mat-error>
                    <mat-error *ngIf="txtEmail.errors?.pattern">Invalid Email Id
                    </mat-error>
                </mat-form-field>
            </div>

            <!--Affiliation/Academic Institution  -->
            <div fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="start stretch">
                <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{institutelabel}}</mat-label>
                    <input matInput type="text" formControlName="affiliation" class="form-control"
                        [(ngModel)]="this.local_data.institute"
                        [ngClass]="{ 'is-invalid': submitted && f.affiliation.errors }" maxlength="256" required>
                    <mat-error>{{instituterequired}}</mat-error>
                </mat-form-field>
            </div>

            <!-- Department Autocomplete Chip -->
            <div fxLayoutAlign="space-around center" fxLayoutAlign.lt-sm="start stretch">
                <mat-form-field appearance="outline" fxFlex="100%">
                    <mat-label>{{departmentlabel}}</mat-label>
                    <input matInput [uppercase]="this.local_data.department" type="text" formControlName="department"
                        class="form-control" [(ngModel)]="this.local_data.department"
                        [ngClass]="{ 'is-invalid': submitted && f.department.errors }" maxlength="256" required>
                    <mat-hint> Ex. : write in full - <b>Department of Internal Medicine and Neurology</b> (Do not Write
                        Division)</mat-hint>
                    <mat-error>{{departmentrequired}}</mat-error>
                </mat-form-field>
            </div>


            <!-- {{this.local_data.department|json  }} -->



            <!-- city,state and country -->
            <div fxLayout="row" fxLayoutGap="4px">

                <!--city  -->
                <div *ngIf="this.locality == null" fxLayoutAlign.lt-sm="start stretch" fxFlex="32">
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{citylabel}}</mat-label>
                        <input matInput type="text" formControlName="city" class="form-control" #search
                            [(ngModel)]="local_data.city" [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                            required>
                        <mat-error>{{cityrequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="this.locality != null" fxLayoutAlign.lt-sm="start stretch" fxFlex="32">
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{citylabel}}</mat-label>
                        <input matInput type="text" formControlName="city" class="form-control" #search
                            [(ngModel)]="locality" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" required>
                        <mat-error>{{cityrequired}}</mat-error>
                    </mat-form-field>
                </div>
                <!-- State -->
                <div *ngIf="this.locality == null" fxLayoutAlign.lt-sm="start stretch" fxFlex="32">
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{statelabel}}</mat-label>
                        <input matInput type="text" formControlName="state" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.state.errors }" [(ngModel)]="local_data.state"
                            required>
                        <mat-error>{{staterequired}}</mat-error>
                    </mat-form-field>
                </div>
                <div *ngIf="this.locality != null" fxLayoutAlign.lt-sm="start stretch" fxFlex="32">
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{statelabel}}</mat-label>
                        <input matInput type="text" formControlName="state" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.state.errors }" [(ngModel)]="city" required>
                        <mat-error>{{staterequired}}</mat-error>
                    </mat-form-field>
                </div>

                <!-- country -->

                <div fxFlex *ngIf="this.locality == null">
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{countrylabel}}</mat-label>
                        <input matInput type="text" formControlName="country" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.state.errors }" [(ngModel)]="local_data.country"
                            required>
                        <mat-error>{{countryrequired}}</mat-error>
                        <!-- <mat-select [formControl]="selectFormControl" [errorStateMatcher]="matcher" required>
                            <mat-option value="valid">Valid option</mat-option>
                        </mat-select> -->
                        <mat-error *ngIf="selectFormControl.hasError('required')">You must make a selection</mat-error>
                    </mat-form-field>
                </div>
                <div fxFlex *ngIf="this.locality != null">
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>{{countrylabel}}</mat-label>
                        <input matInput type="text" formControlName="country" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.state.errors }" [(ngModel)]="country" required>
                        <mat-error>{{countryrequired}}</mat-error>
                        <!-- <mat-select [formControl]="selectFormControl" [errorStateMatcher]="matcher" required>
                            <mat-option value="valid">Valid option</mat-option>
                        </mat-select> -->
                        <mat-error *ngIf="selectFormControl.hasError('required')">You must make a selection</mat-error>
                    </mat-form-field>
                </div>
            </div>

        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <div class="form-group" fxLayout="row" fxLayoutAlign="space-around center">

                <button mat-raised-button color="primary" [disabled]="loading||form.invalid || !this.form" (click)="addCoauthor()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1" mat-dialog-close></span>
                    {{savebutton}}
                </button>
                <button mat-raised-button color="warn" class="btn btn-link"
                    (click)="closedialog()" mat-dialog-close>{{backbutton}}</button>
            </div>
        </mat-dialog-actions>
    </form>
</section>