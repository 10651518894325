<mat-sidenav-container class="backgroud-color-white">
  <!-- Sidenav for mobile -->
  <mat-sidenav #sidenav class="app-sidenav background-color-light-teal">
    <mat-nav-list>
      <div fxLayout="column" fxLayoutGap="10px">
        <a class="padding-left mat-h3 color-primary color-hover" (click)="sidenav.close()" [routerLink]="['/home']"
          routerLinkActive="is-active-sm">Home
        </a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/about-this-journal']"
          routerLinkActive="is-active-sm">About Journal</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/aims-and-scope']"
          routerLinkActive="is-active-sm">Aims & Scope</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/Editorial-Board']"
          routerLinkActive="is-active-sm">Editorial Board</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/abstracting/indexing']"
          routerLinkActive="is-active-sm">Abstracting / Indexing</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/submission-guidelines']"
          routerLinkActive="is-active-sm">Submission Guidelines</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/archives']"
          routerLinkActive="is-active-sm">Archives</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/blog']"
          routerLinkActive="is-active-sm">Blogs</a>

        <!-- <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/contact-the-journal']"
          routerLinkActive="is-active-sm">Contact Journal</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/latest-articels']"
          routerLinkActive="is-active-sm">Latest
          Articles</a>
        <a class="padding-left mat-h3 color-hover" (click)="sidenav.close()" [routerLink]="['/popular-articels']"
          routerLinkActive="is-active-sm">Popular Articles</a> -->

        <!-- <a class="padding-left mat-h3 color-hover">Submit Article</a> -->
      </div>
    </mat-nav-list>
  </mat-sidenav>

  <!-- sidenav for desktop -->
  <mat-sidenav-content class="shadow" fxLayout="column">
    <div fxLayout="column" class="backgroud-color-white header">
      <div class="top-header" fxLayout="row" fxLayoutAlign="space-between " fxLayout.lt-sm="column"
        fxLayoutGap.lt-sm="10px">
        <!-- Logo and Journal -->
        <div [routerLink]="['/home']" fxLayout="row" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="center center"
          fxLayoutGap="10px">
          <!-- Logo -->
          <img src="assets/images/home/logo.png" alt="" class="logo " [ngClass.lt-sm]="'logo-sm'"
            [ngClass.sm]="'logo-sm'">
          <!-- Journal Name -->
          <p class="mat-headline color-primary title padding-top-10" [ngClass.lt-sm]="' title font-size-sm'"
            [ngClass.sm]="' title font-size-sm'">
            Annals <i style="margin-right: 4px;">of </i> Evidence-<br fxHide fxShow.lt-sm fxShow.sm>Based Medicine
          </p>
        </div>

        <!-- Social and menus -->
        <div fxLayoutGap="5px" fxLayoutAlign="end center" fxLayoutGap.lt-sm="6px" fxLayoutAlign.lt-sm="center center"
          fxFlex class="padding-top-10">
          <!-- Responsive menu -->
          <div fxHide.gt-md fxShow.lt-md fxShow.md fxHide>
            <button mat-button color="primary" (click)="sidenav.toggle()"
              class="button-free-sm color-primary button-hover-color">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <!-- Social  -->
          <div class="icon-height" fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-sm="10px" fxLayoutGap.sm="5px">
            <a class="fa fa-facebook f-color f-color-hover" href="https://www.facebook.com/TheRUJM" target="_blank">
            </a>
            <a class="fa fa-youtube-play  y-color  y-color-hover "
              href="https://www.youtube.com/channel/UCJ2hC8xvQhqAlyh0X7Uwl1A" target="_blank"></a>
            <a class="fa fa-linkedin l-color  l-color-hover " href="https://www.linkedin.com/in/The-RUJM"
              target="_blank"></a>
            <a class="fa fa-twitter t-color  l-color-hover " href="https://twitter.com/The_RUJM" target="_blank"></a>
            <a class="fa fa-envelope e-color  e-color-hover " href="mailto: support@annalsebm.org" target="_blank"></a>
          </div>
          <!-- Extra Menus -->
          <div fxLayoutGap.gt-sm="5px" fxLayoutGap.lt-sm="5px" fxLayoutGap.sm="5px">
            <!-- Submit Article button -->
            <button mat-button color="primary" class="button-free color-primary button-hover-color"
              [ngClass.lt-md]="'button-free-sm color-primary button-hover-color'" (click)="submitArticle()" fxShow.gt-sm
              fxHide.lt-sm fxHide.sm fxHide.md>
              Submit Article
            </button>
            <!--  SignIn/SignUp In website -->
            <button mat-button color="primary" class="button-free color-primary button-hover-color"
              [ngClass.lt-sm]="'button-free-login color-primary button-hover-color'"
              *ngIf="!this.userinfo || this.userinfo== null" [routerLink]="['/login']" fxHide.lt-sm>
              Sign In / Sign Up
            </button>
            <!-- SignIn/SignUp In responsive -->
            <button mat-button color="primary" class="button-free color-primary button-hover-color"
              [ngClass.lt-sm]="'button-free-login color-primary button-hover-color'"
              *ngIf="!this.userinfo || this.userinfo== null" [routerLink]="['/login']" fxHide.gt-sm>
              <mat-icon>person</mat-icon>
            </button>
            <!-- Dashboard -->
            <button mat-button color="primary" *ngIf="this.userinfo || this.userinfo!= null"
              class="button-free color-primary button-hover-color"
              [ngClass.lt-sm]="'button-free-sm color-primary button-hover-color'" [routerLink]="['/dashboard']"
              fxHide.lt-sm fxHide.sm fxHide.md>
              {{this.fullName}}
            </button>
            <!-- Responsive right menu -->
            <button mat-button color="primary" *ngIf="this.userinfo || this.userinfo!= null"
              [matMenuTriggerFor]="beforeMenu" [ngClass.lt-sm]="'button-free-sm color-primary button-hover-color'"
              [ngClass.sm]="'button-free-sm color-primary button-hover-color'" fxHide.gt-sm>
              {{this.userName}}
            </button>
            <!-- Website Right side menu -->
            <a mat-button color="primary" class="button-free menu-btn color-primary button-hover-color btn-menu"
              [matMenuTriggerFor]="beforeMenu" (click)="showFiller = !showFiller"
              *ngIf="this.userinfo || this.userinfo!= null" fxHide.lt-sm fxHide.sm>
              <mat-icon class="material-icons">
                menu
              </mat-icon>
            </a>
            <!-- Mat menu  -->
            <mat-menu #beforeMenu="matMenu" class="display_menu" [ngClass.lt-sm]="'display_menu-sm'" xPosition="before">
              <!-- Dashboard -->
              <a mat-button class="menu_content" routerLink="/dashboard">
                <mat-icon class="material-icons">
                  dashboard
                </mat-icon>
                MY DASHBOARD
              </a>
              <mat-divider class="divider"></mat-divider>
              <!-- My profile -->
              <a mat-button class="menu_content" routerLink="/myprofile">
                <mat-icon class="material-icons">
                  person
                </mat-icon>
                MY PROFILE
              </a>

              <mat-divider class="divider"></mat-divider>
              <!-- Change Password -->
              <a mat-button class="menu_content" routerLink="/change-password">
                <mat-icon class="material-icons">
                  lock
                </mat-icon>
                CHANGE PASSWORD
              </a>

              <!-- <mat-divider class="divider"></mat-divider> -->
              <mat-divider class="divider" *ngIf="Admin"></mat-divider>
              <!-- Editorial Board -->
              <a mat-button class="menu_content" routerLink="/admin/editorialBoard-list" *ngIf="Admin" fxHide.lt-md
                fxHide.md fxShow>
                <mat-icon class="material-icons">
                  add_circle </mat-icon>
                EDITORIAL BOARD MEMBER
              </a>
              <!-- Editorial Board in small screen-->
              <a mat-button class="menu_content" routerLink="/pages/error" *ngIf="Admin" fxShow.lt-md fxShow.md fxHide>
                <mat-icon class="material-icons">
                  add_circle </mat-icon>
                EDITORIAL BOARD MEMBER
              </a>

              <mat-divider class="divider" *ngIf="Admin"></mat-divider>
              <!-- Publish an Issue -->
              <a mat-button class="menu_content" routerLink="/admin/coverImage-List" *ngIf="Admin" fxHide.lt-md
                fxHide.md fxShow>
                <mat-icon class="material-icons">
                  add_photo_alternate </mat-icon>
                PUBLISH AN ISSUE
              </a>
              <!--Publish an Issue-->
              <a mat-button class="menu_content" routerLink="/pages/error" *ngIf="Admin" fxShow.lt-md fxShow.md fxHide>
                <mat-icon class="material-icons">
                  add_photo_alternate </mat-icon>
                PUBLISH AN ISSUE
              </a>

              <mat-divider class="divider" *ngIf="Admin"></mat-divider>
              <!--  SUBMIT A BLOG -->
              <a mat-button class="menu_content" routerLink="/admin/blogs-list" *ngIf="Admin" fxHide.lt-md fxHide.md>
                <mat-icon class="material-icons">
                  message </mat-icon>
                SUBMIT A BLOG
              </a>
              <!--SUBMIT A BLOG-->
              <a mat-button class="menu_content" routerLink="/pages/error" *ngIf="Admin" fxShow.lt-md fxShow.md fxHide>
                <mat-icon class="material-icons">
                  message </mat-icon>
                SUBMIT A BLOG
              </a>

              <mat-divider class="divider" *ngIf="Admin"></mat-divider>
              <!-- REVIEW COMMENTS -->
              <a mat-button class="menu_content" routerLink="/admin/review-comment" *ngIf="Admin">
                <mat-icon class="material-icons">
                  verified</mat-icon>
                REVIEW COMMENTS
              </a>

              <mat-divider class="divider" *ngIf="Admin"></mat-divider>
              <!-- INVOICE for Admin -->
              <a mat-button class="menu_content" routerLink="/admin/add-invoice" *ngIf="Admin">
                <mat-icon class="material-icons">
                  receipt_long</mat-icon>
                INVOICE
              </a>

              <mat-divider class="divider" *ngIf="!Admin"></mat-divider>
              <!-- Invoice for authors -->
              <a mat-button class="menu_content" routerLink="/invoice" *ngIf="!Admin">
                <mat-icon class="material-icons">
                  receipt_long</mat-icon>
                INVOICE
              </a>

              <mat-divider class="divider" *ngIf="Admin"></mat-divider>
              <!-- Promo Code Admin -->
              <a mat-button class="menu_content" routerLink="/admin/promo-code" *ngIf="Admin">
                <mat-icon class="material-icons">
                  card_giftcard</mat-icon>
                Promo Code
              </a>

              <mat-divider class="divider"></mat-divider>
              <!--SIGN OUT -->
              <a mat-button class="menu_content" (click)="logout()">
                <mat-icon class="material-icons">
                  logout
                </mat-icon>
                SIGN OUT
              </a>
              <mat-divider class="divider"></mat-divider>
            </mat-menu>
          </div>
        </div>
      </div>

      <!-- Second Header -->
      <div class="menuheader background-color-light-teal" fxLayout="row" fxLayoutAlign="space-between center"
        fxLayoutGap="10px" fxHide.lt-md fxHide.md>
        <a class="mat-h3 color-hover color-primary" [routerLink]="['/home']" routerLinkActive="is-active">Home
        </a>
        <a class="mat-h3 color-hover color-primary" [routerLink]="['/about-this-journal']"
          routerLinkActive="is-active">About Journal</a>
        <a class="mat-h3 color-hover color-primary" [routerLink]="['/aims-and-scope']" routerLinkActive="is-active">Aims
          & Scope</a>
        <a class="mat-h3 color-hover color-primary" [routerLink]="['/Editorial-Board']"
          routerLinkActive="is-active">Editorial Board</a>
        <a class="mat-h3 color-hover color-primary" routerLinkActive="is-active"
          [routerLink]="['/abstracting/indexing']">
          Abstracting/Indexing</a>
        <a class="mat-h3 color-hover color-primary" [routerLink]="['/submission-guidelines']"
          routerLinkActive="is-active">Submission Guidelines</a>
        <a class="mat-h3 color-hover color-primary" routerLinkActive="is-active" [routerLink]="['/archives']">Archives
        </a>
        <a class="mat-h3 color-hover color-primary" routerLinkActive="is-active" [routerLink]="['/blog']">Blogs
        </a>
        <!-- <a class="mat-h3 color-hover color-primary" [routerLink]="['/contact-the-journal']"
          routerLinkActive="is-active">Contact Journal</a>
        
        <a class="mat-h3 color-hover color-primary" [routerLink]="['/latest-articels']"
          routerLinkActive="is-active">Latest
          Articles</a>
        <a class="mat-h3 color-hover color-primary" [routerLink]="['/popular-articels']"
          routerLinkActive="is-active">Popular Articles</a> -->

        <!-- <a class="mat-h3 color-hover color-primary" [routerLink]="['/Gettingstarted']"
          routerLinkActive="is-active">Submit Article</a> -->
      </div>
    </div>
    <!-- Content for website -->
    <!-- <div class="content-router content" [ngClass.sm]="'content1'"  [ngClass.md]="'content2'" #main>

      <router-outlet></router-outlet>

    </div> -->
  </mat-sidenav-content>
</mat-sidenav-container>