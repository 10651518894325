import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GetDataById } from '../../model/GetDataById';
import { AuthenticationService } from '../../service/author/authentication.service';
import { GetElementByIDService } from '../../service/author/get-element-by-id.service';
import { SuggestReviewerService } from '../../service/dashboard/suggest-reviewer.service';
import { SnackbarService } from '../../service/snackbar.service';
import { CONSTANT } from '../../utils/constant';

@Component({
  selector: 'app-suggestedreviewer',
  templateUrl: './suggestedreviewer.component.html',
  styleUrls: [ './suggestedreviewer.component.css' ]
})
export class SuggestedreviewerComponent implements OnInit {

  SuggestReviewerFormGroup: FormGroup;

  hidecon = true;
  hide = true;
  hideold = true;
  userinfo: any;
  public txtPassword;
  public txtC_password;
  changeEmailid: any;
  show_textbox=false;
  shows=[
    'I do not have time right now.',
    'There is a conflict of interest.',
    'It does not fall within my area of expertise.',
    'Other'
  ];
 

  

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private snackBar: MatSnackBar,
    private suggestReviewerService: SuggestReviewerService,
    private matSnack: SnackbarService) { }


  ngOnInit(): void {

    this.SuggestReviewerFormGroup = this.formBuilder.group({
      reason:new FormControl(''),
      reviewerDetails:new FormControl('')
      // firstName: new FormControl('', ),
      // lastName: new FormControl('', ),
      // email: new FormControl('', ),
      // reason: new FormControl('',),
      // suggestemail: new FormControl('', ),
      // otherCheckBox: new FormControl(''),
      // manuscript: new FormControl('', Validators.required)
    });

  }

  get f() { return this.SuggestReviewerFormGroup.controls; }

  Send() {
    this.suggestReviewerService.suggestReviewer(this.f.reason.value,this.f.reviewerDetails.value)
      .pipe(first())
      .subscribe(
        data => {
          // console.log(data, "Suggest ")
          this.matSnack.withoutButton("Invitation for suggest new reviewer is done.", "")
        },

        error => {

        });
  }


}
