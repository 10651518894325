import { Injectable } from '@angular/core';
import * as S3 from 'aws-sdk/clients/s3';
import { environment } from '../../../../environments/environment';
import { CONSTANT } from '../../utils/constant';
import { AuthenticationService } from '../author/authentication.service'

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private authenticationService: AuthenticationService) {

  }

  //Upload Evolution files
  uploadEvolutionS3(file) {

    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: CONSTANT.accessKeyId,
        secretAccessKey: CONSTANT.secretAccessKey,
        region: CONSTANT.region
      }
    );

    var articleId = localStorage.getItem('articleId')
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    localStorage.setItem("currentTimeInSeconds", JSON.stringify(currentTimeInSeconds))
    const params = {
      Bucket: CONSTANT.Bucket,
      Key: `evolution/${articleId}/` + currentTimeInSeconds,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {

      if (err) {
        return false;
      }
      return true;
    });



  }


  //Upload COI responsibility files
  uploadcoiS3(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: CONSTANT.accessKeyId,
        secretAccessKey: CONSTANT.secretAccessKey,
        region: CONSTANT.region
      }
    );

    var articleId = localStorage.getItem('articleId')
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    localStorage.setItem("currentTimeInSeconds", JSON.stringify(currentTimeInSeconds))
    const params = {
      Bucket: CONSTANT.Bucket,
      Key: `coi-responsibility/${articleId}/` + currentTimeInSeconds,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {

      if (err) {
        return false;
      }
      return true;
    });



  }

  //Upload Supplementary material files
  uploadSupplementryS3(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: CONSTANT.accessKeyId,
        secretAccessKey: CONSTANT.secretAccessKey,
        region: CONSTANT.region
      }
    );

    var articleId = localStorage.getItem('articleId')
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    localStorage.setItem("currentTimeInSeconds", JSON.stringify(currentTimeInSeconds))
    const params = {
      Bucket: CONSTANT.Bucket,
      Key: `supplementary-material/${articleId}/` + currentTimeInSeconds,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {

      if (err) {
        return false;
      }
      return true;
    });



  }

  //Upload Non Supplementary material files
  uploadNonSupplementryS3(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: CONSTANT.accessKeyId,
        secretAccessKey: CONSTANT.secretAccessKey,
        region: CONSTANT.region
      }
    );

    var articleId = localStorage.getItem('articleId')
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    localStorage.setItem("currentTimeInSeconds", JSON.stringify(currentTimeInSeconds))
    const params = {
      Bucket: CONSTANT.Bucket,
      Key: `non-supplementary-material/${articleId}/` + currentTimeInSeconds,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {

      if (err) {
        return false;
      }
      return true;
    });



  }

  uploadEditorialMemberProfileS3(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: CONSTANT.accessKeyId,
        secretAccessKey: CONSTANT.secretAccessKey,
        region: CONSTANT.region
      }
    );

    var userId = '6bdd77d8-9176-4d90-9214-58ee63a626a7'
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    localStorage.setItem("currentTimeInSeconds", JSON.stringify(currentTimeInSeconds))
    const params = {
      Bucket: CONSTANT.Bucket,
      Key: `editorial-member-profile/${userId}/` + currentTimeInSeconds,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {

      if (err) {
        return false;
      }
      return true;
    });



  }


  uploadArchiveCoverS3(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: CONSTANT.accessKeyId,
        secretAccessKey: CONSTANT.secretAccessKey,
        region: CONSTANT.region
      }
    );

    var userId = '6bdd77d8-9176-4d90-9214-58ee63a626a7'
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    localStorage.setItem("currentTimeInSeconds", JSON.stringify(currentTimeInSeconds))
    const params = {
      Bucket: CONSTANT.Bucket,
      Key: `archive-cover/${userId}/` + currentTimeInSeconds,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {

      if (err) {
        return false;
      }
      return true;
    });



  }


  uploadInvoiceReceiptS3(file) {
    const contentType = file.type;
    const bucket = new S3(
      {
        accessKeyId: CONSTANT.accessKeyId,
        secretAccessKey: CONSTANT.secretAccessKey,
        region: CONSTANT.region
      }
    );

    var invoiceId = localStorage.getItem('invoiceId')
    var currentTimeInSeconds = Math.floor(Date.now() / 1000);
    localStorage.setItem("currentTimeInSeconds", JSON.stringify(currentTimeInSeconds))
    const params = {
      Bucket: CONSTANT.Bucket,
      Key: `invoice-receipt/${invoiceId}/` + currentTimeInSeconds,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    bucket.upload(params, function(err, data) {

      if (err) {
        return false;
      }
      return true;
    });



  }
}
