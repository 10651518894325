import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Articlecontributions } from '../../model/articlecontributions';
import { RestmanagerService } from '../../utils/restmanagerService';

@Injectable({
  providedIn: 'root'
})
export class ArticlecontributionsService {
  private header = new HttpHeaders({ 'content-type': 'application/json' });
  _apiEndPoint: string = '';
  private restmanagerService: RestmanagerService;
  constructor(private _http: HttpClient, restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }
  AddContributions1(articleId: string, contributions: any) {
    return this._http.post<Articlecontributions>(`${environment.apiUrl}/api/Article/Add-Contributions`, {
      articleId,
      contributions,

    })
      .pipe(map(res => {
        return res;
      }));
  }
  AddContributions(body) {
    return this.restmanagerService.add<Articlecontributions>(`api/Article/Add-Contributions`, body);
  }
  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  getContributions<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  addAuthorNotes(ArticleId: string, ModelNote: any) {
    return this._http.post<any>(`${environment.apiUrl}/api/Article/addnote`, {
      ArticleId,
      ModelNote,

    })
      .pipe(map(res => {
        return res;
      }));
  }

  getAuthorNotes<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

}
