import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, pairwise, share, takeUntil, throttleTime } from 'rxjs/operators';
import { GetDataById } from 'src/app/shared/model/GetDataById';
import { administrative } from 'src/app/shared/model/Home-Page/Admistrative/admistrative';
import { User } from 'src/app/shared/model/user';
import { AuthenticationService } from 'src/app/shared/service/author/authentication.service';
import { DataService } from 'src/app/shared/service/author/DataService.service';
import { GetElementByIDService } from 'src/app/shared/service/author/get-element-by-id.service';


enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden'
}

enum Direction {
  Up = 'Up',
  Down = 'Down',
  Original = 'Original',
  None = 'None'
}

/*
*Sidenav component
*/
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css'],
  animations: [
    trigger('scrollAnimation', [
      state(VisibilityState.Visible, style({
        transform: 'translateY(0)', opacity: 1
      })),
      state(VisibilityState.Hidden, style({
        transform: 'translateY(0)', opacity: 0 // adjust this to the height of your header
      })),
      transition(`${VisibilityState.Visible} => ${VisibilityState.Hidden}`, animate('500ms')),
      transition(`${VisibilityState.Hidden} => ${VisibilityState.Visible}`, animate('250ms'))
    ])
  ]
})

/*
*class: Sidenav
*Reference link: 
*/
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
  fragment;
  routerScroll: any;
  showFiller = false;
  Admin = false;

  private isVisible = true;

  currentUser: User;
  userinfo = JSON.parse(localStorage.getItem('currentUser'));
  currentAdmin: administrative
  adminInfo = JSON.parse(localStorage.getItem('currentAdmin'));
  userName: any;
  userinfoid: any;
  data_profile: any;
  fullName: any;
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }
  constructor(public route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private router: Router,
    private dataService: DataService,
    private getDataService: GetElementByIDService) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {

    this.routerScroll = this.route.fragment.subscribe(fragment => {
      this.fragment = fragment;
    });
    this.isAdmin()
    if (this.userinfo != null) {
      this.fullName = this.userinfo.fullName
    }
    this.dataService.nameUpdate.subscribe((data) => {
      // alert("hello")

      if (data || data != null) {
        this.getDataByid()
      }
    });
  }
  ngAfterViewChecked(): void {
    try {
      if (this.fragment) {
        document.querySelector('#' + this.fragment).scrollIntoView();
      }
    } catch (e) { }
  }

  private destroy$: Subject<boolean> = new Subject<boolean>();
  isHeader2Visible = VisibilityState.Hidden;
  slideHeader2InAtPosition = 200;
  slideHeaderInAtPosition = 30;

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
    // create an observable stream of scroll positions and map them to UP / DOWN directions
    const content = document.querySelector('.content-router');
    const scroll$ = fromEvent(content, 'scroll').pipe( // if the scroll events happen on your window you could use 'window' instead of 'content' here
      throttleTime(10),
      map(() => content.scrollTop), // if you used 'window' above replace 'content.scrollTop' with 'window.pageYOffset'
      pairwise(),
      map(([y1, y2]): Direction => {
        // console.log(this.slideHeader2InAtPosition);
        return (y2 <= this.slideHeader2InAtPosition ? Direction.Up : (y2 > this.slideHeaderInAtPosition ? Direction.Down : Direction.None));
      }),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    );

    // subscribe to the UP / DOWN scroll direction stream and set the header state accordingly
    scroll$.subscribe(dir => {
      if (dir === Direction.Down) {
        this.isHeader2Visible = VisibilityState.Visible;
      } else {
        this.isHeader2Visible = VisibilityState.Hidden;
      }
    });
  }

  getDataByid() {
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.userinfoid = this.userinfo.id;
    this.getDataService.getDataByid<GetDataById>(`api/Registration/getUser?id=${this.userinfoid}`)
      .subscribe((data: any) => {

        this.data_profile = data as GetDataById[];

        this.fullName = this.data_profile.first_Name + " " + this.data_profile.last_Name

      });
  }

  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

  isAdmin() {
    if (this.currentUser != null) {
      if (this.currentUser.role == "Administrator") {
        this.Admin = true;
      }
    }
    if (this.currentUser != null) {
      var splitWord = this.userinfo.fullName.split(' ')
      var FirstWord = splitWord[0].split('')
      var firstkeyword = FirstWord[0]
      var SecondWord = splitWord[1].split('')
      var secondkeyword = SecondWord[0]
      this.userName = firstkeyword + secondkeyword
    }
  }

  //Redirect to Blog Page
  goBlog() {
    localStorage.removeItem('blogTypecategory')
    localStorage.removeItem('blogRecentlist')
    localStorage.removeItem('test1')
    localStorage.removeItem('test2')
    this.router.navigate(['/blog']);
  }

  submitArticle() {
    localStorage.removeItem('articleId');
    this.router.navigate(['/submission/Gettingstarted']);
  }
}


