import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { User } from '../../model/user'
import { administrative } from '../../model/Home-Page/Admistrative/admistrative';
import { SocialAuthService } from 'angularx-social-login';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  private currentAdminSubject: BehaviorSubject<administrative>;
  public currentAdmin: Observable<administrative>;
  public currentUser: Observable<User>;
  constructor(private http: HttpClient, private socialAuthService: SocialAuthService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currentAdminSubject = new BehaviorSubject<administrative>(JSON.parse(localStorage.getItem('currentAdmin')));
    this.currentAdmin = this.currentAdminSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentAdminValue(): administrative {
    return this.currentAdminSubject.value;
  }


  registerUser(email: string, firstname: string, lastName: string, password: string, city: string, state: string, country: string, zip: string, institute: string, department: string, degree: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/Registration/UserCreation`, { email, firstname, lastName, password, city, state, country, zip, institute, department, degree })
      .pipe(map(res => {
        return res;
      }));
  }

  login(email: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/Login/UserLogin`, { email, password })
      .pipe(map(user => {

        user.authdata = user.token
        localStorage.setItem('currentUser', JSON.stringify(user));

        this.currentUserSubject.next(user);
        return user;
      }));
  }

  Externallogin(Provider: string, IdToken: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/Login/ExternalLogin`, { Provider, IdToken })
      .pipe(map(user => {

        user.authdata = user.token
        localStorage.setItem('currentUser', JSON.stringify(user));

        this.currentUserSubject.next(user);
        return user;
      }));
  }


  logout() {

    // this.socialAuthService.signOut()
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    localStorage.clear();
  }

  changePassword(id: string, tempPassword: string, newPassword: string,) {
    return this.http.post<any>(`${environment.apiUrl}/api/Registration/ChangeTempPassword`, { id, tempPassword, newPassword, })
      .pipe(map(res => {
        return res;
      }));
  }

  updateUser(id: string, email: string, first_name: string, last_Name: string, Orcid: string, city: string, state: string, country: string, zip: string, institute: string, department: string, degree: string, personal_categories: string, personal_keywords: string,Boxpromotion:any,BoxPersonalDetails:any) {
    return this.http.post<any>(`${environment.apiUrl}/api/Registration/UpdateAuthor`, { id, email, first_name, last_Name, Orcid, city, state, country, zip, institute, department, degree, personal_categories, personal_keywords,Boxpromotion,BoxPersonalDetails })
      .pipe(map(res => {
        return res;
      }));
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/registration/forgot-password`, { email })
      .pipe(map(res => {
        return res;
      }));
  }

  sendOtp(email: string, UserId: String) {
    return this.http.post<any>(`${environment.apiUrl}/api/registration/sendOtpVerification`, { email, UserId })
      .pipe(map(res => {
        return res;
      }));
  }

  otpVerify(email: string, OTP: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/registration/otpVerification`, { email, OTP })
      .pipe(map(res => {
        return res;
      }));

  }
  changeForgotpassword(email: string, Password: string) {
    return this.http.post<any>(`${environment.apiUrl}/api/Registration/changePassword`, { email, Password })
      .pipe(map(res => {
        return res;
      }));
  }
}
