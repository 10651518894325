import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Invoice } from '../../model/invoice';
import { InvoiceService } from '../../service/admin/invoice.service';
import { InvoiceComponent } from '../invoice/invoice.component';
import { ShareInvoiceComponent } from '../invoice/share-invoice/share-invoice.component';

export interface AddInvoice {
  date_invoice: string;
  date_publish: string;
  volume: number;
  issue: number;
  manuscriptID: string;
  title: string;
  Cor_author: string;
  amount: number;
  paymentStatus: string;
}
const ELEMENT_DATA: AddInvoice[] = [
  {
    date_invoice: 'Sep 20, 2021', date_publish: 'Sep 20, 2021', volume: 1, issue: 1, manuscriptID: 'AEBM-2021-0001', title: 'Prevalence and Outcomes Associated with Vitamin D Deficiency among Indexed Hospitalizations with Cardiovascular Disease and Cerebrovascular Disorder—A Nationwide Study Prevalence and Outcomes Associated with Vitamin D Deficiency among Indexed Hospi',
    Cor_author: 'Krunal Soni (krunalsoni@gmail.com)', amount: 230, paymentStatus: 'Paid'
  },
];
@Component({
  selector: 'app-author-invoice',
  templateUrl: './author-invoice.component.html',
  styleUrls: [ './author-invoice.component.css' ]
})
export class AuthorInvoiceComponent implements OnInit {
  invoiceList: any;
  userinfo: any;

  constructor(private router: Router, private getInvoiceListService: InvoiceService,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.getAuthorInvoiceList()
    localStorage.removeItem('authorinvoice')
  }
  displayedColumns: string[] = [ 'date_invoice', 'date_publish', 'volume', 'issue', 'manuscriptID', 'title', 'Cor_author', 'amount', 'paymentStatus', 'receipt', 'action','share' ];
  dataSource = new MatTableDataSource<Invoice>([]);;

  // Edit Invoice Click Event
  pay(element) {

    localStorage.setItem('authorinvoice', JSON.stringify(element));
    this.router.navigateByUrl('/payInvoice');
  }
  //Get Invoice List api Call
  getAuthorInvoiceList() {

    this.getInvoiceListService.getAuthorInvoiceList<Invoice>(`api/Article/AuthorInvoice?userId=${this.userinfo.id}`)
      .subscribe((data: Invoice[]) => {

        this.invoiceList = data as Invoice[];
        this.dataSource.data = this.invoiceList
      })


  }

  //Preview button click Event
  preview(element) {
    localStorage.setItem('articleId', element.articleId)

    var manuscriptId = element.articleDisplayId.substring(5) + '-' + element.title.replace(/\s+/g, '-')
    const host: string = location.origin;
    const url: string = host + '/#' + String(this.router.createUrlTree([ '/articles', `${manuscriptId}` ], { state: element }));
    window.open(url, '_blank');

  }

  download(obj) {
    const dialogRef = this.dialog.open(InvoiceComponent, {
      height: '100%',
      width: '60%',
      data: obj
    });
  }
  share(obj) {
    const dialogRef = this.dialog.open(ShareInvoiceComponent, {
      width: '50%',
      data: obj

    });
  }

}
