import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { GetDataById } from '../../model/GetDataById';
import { AuthenticationService } from '../../service/author/authentication.service';
import { GetElementByIDService } from '../../service/author/get-element-by-id.service';
import { CONSTANT } from '../../utils/constant';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: [ './change-password.component.css' ]
})
export class ChangePasswordComponent implements OnInit {

  ChangepasswordFormGroup: FormGroup;

  hidecon = true;
  hide = true;
  hideold = true;
  userinfo: any;
  public txtPassword;
  public txtC_password;
  changeEmailid: any;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private getDataService: GetElementByIDService,
    private snackBar: MatSnackBar) { }

  //Check password
  checkPasswords(): ValidatorFn {
    //see that the argument is a FormControl
    return (control: FormControl): ValidationErrors => {
      //the formGroup is control.parent
      const group = control.parent as FormGroup;
      //but we must sure that is defined
      if (!group) return null;

      let pass = group.get("newpassword").value;
      let confirmPass = group.get("renewpassword").value;

      return confirmPass ? pass === confirmPass ? null : { mismatch: true } : null;
    };
  }
  ngOnInit(): void {

    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.ChangepasswordFormGroup = this.formBuilder.group({
      // password: new FormControl('', Validators.required),
      newpassword: new FormControl('', [ Validators.required,
      Validators.pattern(CONSTANT.passwordPattern) ]),
      renewpassword: new FormControl('', [ Validators.required,
      this.checkPasswords() ]),

    });
    this.txtPassword = this.ChangepasswordFormGroup.get('newpassword');
    this.txtC_password = this.ChangepasswordFormGroup.get('renewpassword');

    this.getDataByid()
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [ 'green-snackbar' ]
    });
  }

  get f() { return this.ChangepasswordFormGroup.controls; }

  ChangePassword() {

    if (this.ChangepasswordFormGroup.invalid) {
      return;
    }

    this.authenticationService.changeForgotpassword(this.changeEmailid, this.f.newpassword.value)
      .pipe(first())
      .subscribe(
        data => {

          this.router.navigateByUrl('/myprofile');
          this.openSnackBar('Change Password Successfully', '')
        },

        error => {

        });
  }

  getDataByid() {

    this.getDataService.getDataByid<GetDataById>(`api/Registration/getUser?id=${this.userinfo.id}`)
      .subscribe((data: any) => {
        this.changeEmailid = data.email
        // console.log(this.changeEmailid)
      });
  }

}
