import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatSortModule } from '@angular/material/sort';

import { AuthorsRoutingModule } from './authors-routing.module';
import { LayoutComponent } from './layout.component';
import { ListComponent } from './list.component';
import { AddEditComponent } from './add-edit.component';
import { MaterialModule } from '../layout/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AgmCoreModule } from '@agm/core';
import { UppercaseInputDirective } from '../helpers/capitalpipe.directive';
import { DeleteComponent } from './delete/delete/delete.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        AuthorsRoutingModule,
        MaterialModule,
        FlexLayoutModule,
        DragDropModule,
        MatDialogModule,
        MatSortModule,
        AutocompleteLibModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDf7ka3vS6VLLwWX7JvUbSocUpFQUbWg8Q',
            libraries: [ 'places' ]
        })
    ],
    declarations: [
        LayoutComponent,
        ListComponent,
        AddEditComponent,
        UppercaseInputDirective,
        DeleteComponent

    ],
    exports: [
        ListComponent,
        AddEditComponent,
        LayoutComponent,
        MatSortModule

    ],
    entryComponents: [ AddEditComponent ]
})
export class AuthorsModule { }