import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';

/*
*Sidenav component
*/
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})

/*
*class: Sidenav
*Reference link: 
*/
export class SideNavComponent implements OnInit {
  showFiller = false;
  showFiller1 = false;
  showFiller2 = false;
  showFiller3 = false;

  constructor(public route: ActivatedRoute) { }

  ngOnInit(): void {
  }

}
