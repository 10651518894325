import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../service/author/authentication.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: [ './forgot-password.component.css' ]
})
export class ForgotPasswordComponent implements OnInit {

  ForgotpasswordFormGroup: FormGroup;
  hide = true;
  error: any;


  constructor(private formBuilder: FormBuilder, private authenticationService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.ForgotpasswordFormGroup = this.formBuilder.group({
      email: new FormControl('', Validators.required),
    });
  }

  get lf() { return this.ForgotpasswordFormGroup.controls; }
  forgotPassword() {

    // stop here if form is invalid
    if (this.ForgotpasswordFormGroup.invalid) {
      return;
    }
    // console.log('Send otp')

    // Api called  login
    this.authenticationService.forgotPassword(this.lf.email.value)

      .pipe(first())
      .subscribe(
        data => {
          localStorage.setItem('forgot-email', this.lf.email.value)

          localStorage.setItem('forgotuserid', data.userid)

          this.router.navigateByUrl('/pages/otp-verify');

        },
        error => {
          this.error = error.error.message;
          // console.log(this.error)
        });
  }

}
