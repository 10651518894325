import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Addcoauthor } from '../../model/addcoauthor';
import { Articleauthorlist } from '../../model/articleauthorlist';
import { RestmanagerService } from '../../utils/restmanagerService';

@Injectable({
  providedIn: 'root'
})
export class ArticleauthorlistService {

  _apiEndPoint: string = '';
  private restmanagerService: RestmanagerService;
  author = [];

  constructor(restmanagerService: RestmanagerService, private _http: HttpClient) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }


  getDatabyid<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  getArticleAuthorlist<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  getEmailsubscribeauthor<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }
  // getArticleAuthorlist2(articleId) {
  //   return this.restmanagerService.getWithParametersdata<Articleauthorlist>(`api/Article/GetArticleAuthorsList`, { articleId: articleId })
  //     .pipe(map(data => {
  //       return data;
  //     }));
  // }

  Sendreminder(id: string, articleId: string) {
    return this._http.post<Articleauthorlist>(`${environment.apiUrl}/api/Registration/RemindToSubscriber`, {
      id,
      articleId
    })
      .pipe(map(res => {
        return res;
      }));
  }

  // Delete dependent api
  deleteAuthorfromlist(articleId: string, authorId: string) {
    return this._http.post<Articleauthorlist>(`${environment.apiUrl}/api/Article/DeleteAuthor`, {
      articleId,
      authorId
    })
      .pipe(map(res => {
        return res;
      }));
  }

  AddCoauthor(articleId) {
    return this._http.post<Addcoauthor>(`${environment.apiUrl}/api/Registration/`, {
      articleId
    })
      .pipe(map(res => {
        return res;
      }));
  }

  getUsers() {
    var articleID = localStorage.getItem('articleId')
    return this._http.get(`${environment.apiUrl}/api/Article/GetArticleAuthorsList?articleId=${articleID}`);
  }


  addauthorinlist(articleId: string, authorId: string) {
    return this._http.post<Articleauthorlist>(`${environment.apiUrl}/api/Article/AddArticleAuthor`, {
      articleId,
      authorId
    })
      .pipe(map(res => {
        return res;
      }));
  }

  correspondingAuthor<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }

  getCorrespondingAuthor<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }

  Authorordering(articleId: string, userIds: string) {
    return this._http.post<Articleauthorlist>(`${environment.apiUrl}/api/Article/Authorordering`, {
      articleId,
      userIds
    })
      .pipe(map(res => {
        return res;
      }));
  }
}
