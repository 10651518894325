<app-sticky-shared></app-sticky-shared>
<app-jump-to-top></app-jump-to-top>
<!-- Advertisement -->
<div class="advertisement1" fxShow.gt-md fxHide.lt-md>
  <!-- Advertisement Goes here... -->
</div>
<div id="top" class=" padding-left padding-top primary-font" style="padding-bottom:10px;" fxLayout="row"
  [ngClass.lt-md]="'padding-sm'" [ngClass.md]="'padding-sm'" *ngIf="this.errorShow == false">
  <!-- Blog Left side -->
  <div fxFlex.gt-md="73" class="" fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayout.md="column" fxLayoutAlign.md="space-around stretch"
    fxLayoutAlign.lt-sm="space-around stretch">
    <div class="article-minwidth">
      <mat-tab-group>
        <mat-tab label="Blog">
          <!-- Left side content -->
          <div class="content-padding right-padding" fxLayout="column" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutAlign=" start" fxLayoutGap="10px">
              <div>
                <span>Published Date: {{this.publishDate | date: 'MMM d, y'}}</span> |
              </div>
              <div>
                <span>Views: {{this.Viewcount}}</span>
              </div>
            </div>

            <!-- Blog Type -->
            <div class="color-primary">
              <b>{{this.blogTypeName}}</b>
            </div>

            <!-- Blog Title -->
            <div>
              <p class="mat-h2 link-color article-title text-justify">
                {{this.blogTitle}}
              </p>
            </div>

            <!-- Blog Author -->
            <div fxLayout="row" fxLayout.lt-sm="column">
              <div fxLayout="row" fxLayoutGap="2px">
                <span>{{this.blogAuthor}}</span>&nbsp;
              </div>
            </div>

            <!--Blog Content  -->
            <div class="padding-top1 text-justify headings" [innerHTML]="this.blogContent">

            </div>

            <!-- Comments -->
            <div>
              <div fxLayout="row" fxLayoutAlign="center center" style="padding-top: 50px;">
                <div class="divider">
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                  <h2 class="text article-title font-weight mat-h3 color-primary">
                    COMMENTS
                  </h2>
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                </div>
              </div>

              <!-- Comment Content -->
              <div fxLayout="column">
                <form [formGroup]="addCommentFormGroup">
                  <div fxLayoutAlign="start center">
                    <mat-form-field appearance="outline" class="comment-textarea">
                      <mat-label>Leave comment Here</mat-label>
                      <textarea [(ngModel)]="comment_content" formControlName="comment" matInput
                        placeholder="Leave comment Here" class="comment-textarea" maxlength="250"></textarea>
                      <!-- <textarea formControlName="comment" matInput placeholder="Leave comment Here"
                        class="comment-textarea" maxlength="250"></textarea> -->
                    </mat-form-field>
                  </div>
                </form>

                <div fxLayoutAlign="end start">
                  <!-- <button mat-raised-button color="primary" (click)="AddBlogComment()">SUBMIT</button> -->
                  <button mat-raised-button (click)="AddBlogComment()" color="primary"
                    *ngIf="this.userinfo">SUBMIT</button>
                  <button mat-raised-button (click)="AddBlogCommentWithoutuser()" *ngIf="!this.userinfo"
                    color="primary">SUBMIT</button>
                </div>

              </div>
              <div class="chat-bubble chat-bubble--left" *ngFor="let post of this.comments">
                <div fxLayout="row" fxLayoutGap="10">
                  <p class="color-primary">
                    {{post.fullName|titlecase}}
                  </p>
                  <p class="date">
                    {{post.createDate | date:'MMM d, y'}}
                  </p>
                </div>
                {{post.blogComment}}
              </div>
            </div>

            <!-- Similar Blogs -->
            <div fxLayout="row" fxLayoutAlign="center center">
              <div class="divider">
                <div class="line">
                  <mat-divider></mat-divider>
                </div>
                <h2 class="text article-title font-weight mat-h3 color-primary">
                  SIMILAR BLOGS
                </h2>
                <div class="line">
                  <mat-divider></mat-divider>
                </div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutGap="20px" fxFlex="75">
              <div *ngFor="let item of CategoryBlogData" fxLayoutGap="20px" fxLayoutAlign="center">
                <mat-card fxLayout="column" fxLayoutAlign="center" style="width:100%;height:fit-content"
                  class="class-hover cursor-poiner">
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="color-button1 "
                    [ngClass.lt-sm]="'sm-firstLine'">
                    <p class="font-weight2">{{item.blogTypeName}}</p>
                    <p class="font-weight2">{{item.publishDate|date:'MMM d, y'}}</p>
                  </div>
                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxLayoutAlign.lt-sm="start"
                    fxLayoutGap="10px" style="padding: 16px;">
                    <div fxLayout="column" fxLayoutAlign=" start" fxLayoutAlign.lt-sm="start start"
                      fxFlex="80" fxFlex.lt-sm="100" fxLayoutGap="3%">
                      <div fxLayout="column" fxLayoutGap="5px">
                        <p class="article-title-list color-primary black-hover" (click)="BlogPreview(item)">
                          {{item.blogTitle}}
                        </p>
                      </div>
                      <div fxLayout="column" fxLayoutGap="10px">
                        <p class="font-weight2">{{item.blogAuthor|titlecase}}</p>
                        <!-- <p class="font-weight2">Volume {{item.volume}}, Issue {{item.issue}} </p> -->
                        <mat-chip-list>
                          <mat-chip class="mat-chips" *ngFor="let item of item.blogCategory.split(',')">
                            {{item | titlecase}}
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </div>
                    <!-- <div class="mediaBloghome" fxLayoutAlign="center center" fxFlex="20" fxFlex.lt-sm="100">
                      <img [src]="item.blogCoverPhotoUrl" alt="">
                      <div [innerHTML]="item.media| safe: 'html'"></div>
                    </div> -->
                    <div class="mediaArticlevolume"
                      [ngClass.lt-md]="{'mediaArticlevolumeResponsive': true, 'mediaArticlevolume': false}"
                      [ngClass.md]="{'mediaArticlevolumeResponsive': true, 'mediaArticlevolume': false}" fxFlex="25"
                      fxFlex.lt-sm="100">
                      <div [innerHTML]="item.blogMedia| safe: 'html'"></div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>

            <!-- Trending Blogs -->
            <div fxLayout="row" fxLayoutAlign="center center">
              <div class="divider">
                <div class="line">
                  <mat-divider></mat-divider>
                </div>
                <h2 class="text article-title font-weight mat-h3 color-primary">
                  TRENDING BLOGS
                </h2>
                <div class="line">
                  <mat-divider></mat-divider>
                </div>
              </div>
            </div>

            <div fxLayout="column" fxLayoutGap="20px" fxFlex="75">
              <div *ngFor="let item of RecentBlogData" fxLayoutGap="20px" fxLayoutAlign="center">
                <mat-card fxLayout="column" fxLayoutAlign="center" style="width:100%;height:fit-content"
                  class="class-hover cursor-poiner">
                  <div fxLayout="row" fxLayoutAlign="space-between center" class="color-button1 "
                    [ngClass.lt-sm]="'sm-firstLine'">
                    <p class="font-weight2">{{item.blogTypeName}}</p>
                    <p class="font-weight2">{{item.publishDate|date:'MMM d, y'}}</p>
                  </div>
                  <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between" fxLayoutAlign.lt-sm="start"
                    fxLayoutGap="10px" style="padding: 16px;">
                    <div fxLayout="column" fxLayoutAlign=" start" fxLayoutAlign.lt-sm="start start"
                      fxFlex="80" fxFlex.lt-sm="100" fxLayoutGap="3%">
                      <div fxLayout="column" fxLayoutGap="5px">
                        <p class="article-title-list color-primary black-hover" (click)="BlogPreview(item)">
                          {{item.blogTitle}}
                        </p>
                      </div>
                      <div fxLayout="column" fxLayoutGap="10px">
                        <p class="font-weight2">{{item.blogAuthor|titlecase}}</p>
                        <!-- <p class="font-weight2">Volume {{item.volume}}, Issue {{item.issue}} </p> -->
                        <mat-chip-list>
                          <mat-chip class="mat-chips" *ngFor="let item of item.blogCategory.split(',')">
                            {{item | titlecase}}
                          </mat-chip>
                        </mat-chip-list>
                      </div>
                    </div>
                    <!-- <div class="mediaBloghome" fxLayoutAlign="center center" fxFlex="20" fxFlex.lt-sm="100">
                      <img [src]="item.blogCoverPhotoUrl" alt="">
                      <div [innerHTML]="item.media| safe: 'html'"></div>
                    </div> -->
                    <div class="mediaArticlevolume"
                      [ngClass.lt-md]="{'mediaArticlevolumeResponsive': true, 'mediaArticlevolume': false}"
                      [ngClass.md]="{'mediaArticlevolumeResponsive': true, 'mediaArticlevolume': false}" fxFlex="25"
                      fxFlex.lt-sm="100">
                      <div [innerHTML]="item.blogMedia| safe: 'html'"></div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>
        </mat-tab>
        <!-- Small SCreen show Media -->
        <mat-tab label="Media" *ngIf="isMobile()" fxLayoutAlign="center center">
          <div [innerHTML]="this.blogMedia| safe: 'html'" style="min-width: 310px;padding-left:0px;margin-left: 0px;">
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>


  <!-- Right Side -->
  <div class="padding-left content-padding" fxLayout="column" class="left" fxFlex="29" fxHide.lt-md
    style="min-width: 100%" fxLayoutGap="20px" fxHide.md >
    <div>
      <mat-tab-group>
        <mat-tab label="Media">

          <div class="first-line-margin text-justify mediablog" fxLayout="column" fxLayoutAlign="center center"
            fxLayoutGap="10px">
            <!-- <div class="nospace-around text-justify" [innerHTML]="this.blogMedia"></div> -->

            <div class="mediaBlog" [ngClass.lt-md]="{'mediaBlogResponsive': true, 'mediaBlog': false}"
              [ngClass.md]="{'mediaBlogResponsive': true, 'mediaBlog': false}" fxFlex="25" fxFlex.lt-sm="100">
              <div [innerHTML]="this.blogMedia| safe: 'html'"></div>
            </div>
            <!-- <img [src]="this.blogCoverPhotoUrl" alt=""> -->
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <div>
      <mat-tab-group>
        <mat-tab label="Categories">
          <div class="keyword-tab">
            <mat-chip-list>
              <span *ngFor="let item of this.categories;let i = index">
                <mat-chip color="primary" class="mat-chips" (click)="getCategoryBlog(item)">{{item}}
                </mat-chip>
              </span>
            </mat-chip-list>
          </div>
        </mat-tab>
        <!-- Keywords -->
        <mat-tab label="Keywords">
          <div class="keyword-tab">
            <mat-chip-list>
              <span *ngFor="let item of this.keywords;let i = index">
                <mat-chip color="primary" class="mat-chips" (click)="getKeywordBlog(item)">{{item|titlecase}}
                </mat-chip>
              </span>
            </mat-chip-list>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <!-- Advertisement -->
    <div fxShow.gt-md>
      <!-- Advertisement Goes here... -->
    </div>
  </div>
</div>

<!-- Article not found show -->
<div class="padding-center" [ngClass.lt-md]="'padding-top-sm'" id="top" fxLayout="column" fxLayoutAlign="center center"
  fxLayoutGap="10px" *ngIf="this.errorShow == true">
  <p class="mat-headline color-primary">Blog Not Found</p>
  <p class="color-primary" routerLink="/home">Go to Home Page</p>
</div>
<app-footer></app-footer>