import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html',
  styleUrls: [ './blank-layout.component.css' ]
})
export class BlankLayoutComponent implements OnInit {
  sidebaropen = true;
  constructor() { }

  ngOnInit(): void {
  }
  sideBarToggler(event: Event) {
    this.sidebaropen = !this.sidebaropen;
  }
}
