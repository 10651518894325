import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MaterialModule } from './shared/layout/material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { HeaderComponent } from './shared/layout/header/header.component';
import { SideNavComponent } from './shared/layout/side-nav/side-nav.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgDynamicBreadcrumbModule } from "ng-dynamic-breadcrumb";
import { AngularFileUploaderModule } from "angular-file-uploader";


import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { CommonLayoutComponent } from './shared/layout/common-layout/common-layout.component';
import { EditorModule } from './shared/service/editor/editor.module';
import { QuillModule } from 'ngx-quill';
import { AlertComponent } from './shared/service/crud/alert/alert.component';
import { MatSortModule } from '@angular/material/sort';

import { MatRadioModule } from '@angular/material/radio';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgOtpInputModule } from 'ng-otp-input';
import { CountdownModule } from 'ngx-countdown';

import { HttpConfigInterceptor } from '../app/shared/helpers/httpconfig.interceptor';


import { BlankLayoutComponent } from './shared/layout/blank-layout/blank-layout.component';
import { AuthorsModule } from './shared/CRUD/authors.module';
import { AddEditComponent } from './shared/CRUD/add-edit.component';
import { AutoLogoutService } from './shared/service/author/Autologout.service';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { SocialLoginModule } from 'angularx-social-login';
import { SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { UpdateProfileComponent } from './components/website/updateProfile/updateProfile.component';
import { ChangePasswordComponent } from './shared/component/change-password/change-password.component';
import { ForgotPasswordChangeComponent } from './shared/component/forgot-password-change/forgot-password-change.component';
import { ForgotPasswordComponent } from './shared/component/forgot-password/forgot-password.component';
import { OtpVerifyComponent } from './shared/component/otp-verify/otp-verify.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SuggestedreviewerComponent } from './shared/component/suggestedreviewer/suggestedreviewer.component';
import { SessionExpireComponent } from './shared/component/session-expire/session-expire.component';
import { ResponsiveHeaderComponent } from './shared/layout/responsive-header/responsive-header.component';
import { ScreenSizeComponent } from './shared/component/screen-size/screen-size.component';
import { AuthorInvoiceComponent } from './shared/component/author-invoice/author-invoice.component';
import { InvoiceComponent } from './shared/component/invoice/invoice.component';
import { EditCommentComponent } from './shared/component/edit-comment/edit-comment.component';
import { ArticlePreviewComponent } from './components/PaperSubmission/article-preview/article-preview.component';
import { ShareInvoiceComponent } from './shared/component/invoice/share-invoice/share-invoice.component';
import { InvoiceByColleagueComponent } from './shared/component/invoice-by-colleague/invoice-by-colleague.component';
import { PayInvoiceComponent } from './shared/component/pay-invoice/pay-invoice.component';








@NgModule({
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  declarations: [
    AppComponent,
    HeaderComponent,
    SideNavComponent,
    AlertComponent,
    CommonLayoutComponent,
    BlankLayoutComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    OtpVerifyComponent,
    ForgotPasswordChangeComponent,
    SuggestedreviewerComponent,
    SessionExpireComponent,
    ResponsiveHeaderComponent,
    ScreenSizeComponent,
    AuthorInvoiceComponent,
    PayInvoiceComponent,
    InvoiceComponent,
    EditCommentComponent,
    ArticlePreviewComponent,
    ShareInvoiceComponent,
    InvoiceByColleagueComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FlexLayoutModule,
    HttpClientModule,
    MatSortModule,
    NgDynamicBreadcrumbModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    QuillModule.forRoot(),
    MatAutocompleteModule,
    AuthorsModule,
    EditorModule,
    MatRadioModule,
    AngularFileUploaderModule,
    FormsModule,
    FileUploadModule,
    AutocompleteLibModule,
    NgOtpInputModule,
    CountdownModule,
    ScrollingModule,
    ScrollToModule.forRoot(),
    SocialLoginModule,
    NgxSpinnerModule
  ],
  exports: [ MatSortModule ],
  providers: [
    // FakeBackendInterceptor,

    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true },
    AutoLogoutService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '534774924882-78o6ogarkhs2ovj1pprm0r0dnnrhq9k6.apps.googleusercontent.com'
            )
          }
        ]
      } as SocialAuthServiceConfig,
    }
  ],
  bootstrap: [ AppComponent ],
  entryComponents: [ AddEditComponent ]

})
export class AppModule { }
