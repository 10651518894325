<div class="background top-padding" [ngClass.md]="'padding-top'">
    <div fxLayout="row" fxLayoutAlign="space-around center">
        <mat-card fxFlex.gt-md="50%" fxFlex.lt-md="100%">
            <mat-card-title class="title">
                <span fxLayoutAlign="center">Profile Details</span>
            </mat-card-title>
            <mat-card-content fxLayout="column">
                <form [formGroup]="registrationFormGroup" fxLayout="column" fxLayoutGap="20px">

                    <!-- Personal Details -->
                    <div fxLayout="column" fxLayoutGap="10px" fxLayoutGap.lt-md="20px">
                        <div fxLayoutAlign="start">
                            <span class="font-bold">Personal Details</span>
                        </div>

                        <mat-card>
                            <div fxLayout="row" fxLayoutAlign="space-around center" fxLayout.lt-md="column"
                                fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="10px">


                                <!-- First Name -->
                                <mat-form-field appearance="outline">
                                    <mat-label>First Name</mat-label>
                                    <input matInput formControlName="f_name" type="text" id="f_name"
                                        [(ngModel)]="f_name" placeholder="Enter Your First Name" maxlength="256"
                                        required>
                                    <mat-error>First Name required</mat-error>
                                </mat-form-field>

                                <!-- Last Name -->
                                <mat-form-field appearance="outline">
                                    <mat-label>Last Name</mat-label>
                                    <input matInput formControlName="l_name" type="text" id="l_name"
                                        [(ngModel)]="l_name" placeholder="Enter Your Last Name" maxlength="256"
                                        required>
                                    <mat-error>Last Name required</mat-error>
                                </mat-form-field>
                            </div>

                            <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="10px"
                                fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch">
                                <!-- Degree Name -->
                                <mat-form-field appearance="outline">
                                    <mat-label>Degree</mat-label>
                                    <input matInput formControlName="degree" type="text" id="degree"
                                        [(ngModel)]="degree" placeholder="MPH, MBBS" maxlength="256" required>
                                    <mat-error>Degree required</mat-error>
                                </mat-form-field>

                                <!-- ORCID Name -->
                                <mat-form-field appearance="outline">
                                    <mat-label>ORCID iD</mat-label>
                                    <input matInput formControlName="ORCID" type="text" id="ORCID" maxlength="256"
                                        [(ngModel)]="orcid" placeholder="Enter Your ORCID">
                                    <mat-error>ORCID iD required</mat-error>
                                    <mat-hint>Ex.  XXXX-XXXX-XXXX-XXXX</mat-hint>
                                </mat-form-field>
                            </div>


                            <!-- Specialty field -->
                            <!-- <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Specialty</mat-label>
                                <input matInput formControlName="Specialty" type="text" id="Specialty"  required>
                                <mat-error> Specialty required
                                </mat-error>
                            </mat-form-field>
                        </div> -->

                            <!-- EMail field -->
                            <div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Email/Username</mat-label>
                                    <input matInput formControlName="email" type="text" id="email" [(ngModel)]="email"
                                        placeholder="abc@gmail.com" maxlength="256" required>
                                    <mat-error> Email/Username required
                                    </mat-error>
                                </mat-form-field>
                            </div>

                        </mat-card>
                    </div>


                    <!-- Affiliation Details -->
                    <div fxLayout="column" fxLayoutGap="10px">
                        <div fxLayoutAlign="start">
                            <span class="font-bold">Affiliation Details
                                <span class="color-red">*</span>
                            </span>
                        </div>

                        <mat-card fxLayout="column" fxLayoutGap.lt-md="20px">

                            <!-- Department Name -->
                            <div fxLayoutAlign.lt-sm="start stretch">
                                <mat-form-field appearance="outline">
                                    <mat-label>Department Name</mat-label>
                                    <input matInput type="text" formControlName="department" id="department"
                                        [(ngModel)]="department"
                                        placeholder="Ex. . Department of Internal Medicine and Neurology"
                                        class="form-control" maxlength="256" required>
                                    <mat-hint> Ex. : write in full - <b>Department of Internal Medicine and Neurology</b>
                                        (Do not Write
                                        Division)</mat-hint>
                                    <mat-error>Department Name Required</mat-error>
                                </mat-form-field>
                            </div>

                            <!-- Institution field -->
                            <div>
                                <mat-form-field appearance="outline">
                                    <mat-label>Institution Name</mat-label>
                                    <input matInput formControlName="institution" type="text" id="institution"
                                        [(ngModel)]="institution" placeholder="Ex. New York Institute of Medicine"
                                        maxlength="256" required>
                                    <mat-error> Institution Name required
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="this.locality == null" fxLayout="row" fxLayoutAlign="space-around center"
                                fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch">
                                <!-- City -->
                                <mat-form-field appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" type="text" id="city" [(ngModel)]="city"
                                        placeholder="New Jersey" #search required>
                                    <mat-error>City required</mat-error>
                                </mat-form-field>

                                <!-- State -->
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <input matInput formControlName="state" type="text" id="state" [(ngModel)]="state"
                                        placeholder="New York" required>
                                    <mat-error>State required</mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="this.locality != null" fxLayout="row" fxLayoutAlign="space-around center"
                                fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch">
                                <!-- City -->
                                <mat-form-field appearance="outline">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="city" type="text" id="city" [(ngModel)]="locality"
                                        placeholder="New Jersey" #search required>
                                    <mat-error>City required</mat-error>
                                </mat-form-field>

                                <!-- State -->
                                <mat-form-field appearance="outline">
                                    <mat-label>State</mat-label>
                                    <input matInput formControlName="state" type="text" id="state" [(ngModel)]="city"
                                        placeholder="New York" required>
                                    <mat-error>State required</mat-error>
                                </mat-form-field>
                            </div>

                            <div *ngIf="this.locality == null" fxLayout="row" fxLayoutAlign="space-around center"
                                fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch">
                                <!-- Country -->
                                <mat-form-field appearance="outline">
                                    <mat-label>Country</mat-label>
                                    <input matInput formControlName="country" type="text" id="country" placeholder="USA"
                                        [(ngModel)]="country" required>
                                    <mat-error>Country required</mat-error>
                                </mat-form-field>

                                <!-- Zip code -->
                                <!-- <mat-form-field appearance="outline" fxHide>
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput formControlName="zip" type="text" id="zip" maxlength="6"
                                        placeholder="07304" [(ngModel)]="zip">
                                    <mat-error>Zip Code required</mat-error>
                                </mat-form-field> -->
                            </div>
                            <div *ngIf="this.locality != null" fxLayout="row" fxLayoutAlign="space-around center"
                                fxLayoutGap="10px" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch">
                                <!-- Country -->
                                <mat-form-field appearance="outline">
                                    <mat-label>Country</mat-label>
                                    <input matInput formControlName="country" type="text" id="country" placeholder="USA"
                                        [(ngModel)]="country" required>
                                    <mat-error>Country required</mat-error>
                                </mat-form-field>

                                <!-- Zip code -->
                                <mat-form-field appearance="outline">
                                    <mat-label>Zip Code</mat-label>
                                    <input matInput formControlName="zip" type="text" id="zip" maxlength="6"
                                        placeholder="07304" [(ngModel)]="postalCode" required>
                                    <mat-error>Zip Code required</mat-error>
                                </mat-form-field>
                            </div>

                        </mat-card>
                    </div>

                    <div fxLayout="column">
                        <div fxLayoutAlign="start">
                            <span class="font-bold"> Areas of Interest or Expertise
                                <span class="color-red">*</span>
                            </span>
                        </div>

                        <mat-card>
                            <!-- categories -->
                            <div class="chip">
                                <mat-form-field class="example-chip-list no-padding " appearance="outline"
                                    [ngClass]="{'mat-form-field-outline': categories.length === 0}">
                                    <mat-label [ngClass]="{'warn': categories.length === 0}">Select Your Specialties /
                                        Categories<span style="color:red"> *</span></mat-label>
                                    <mat-chip-list #chipList [ngClass]="{'warn-bg': categories.length === 0}">
                                        <mat-chip *ngFor="let category of categories" class="mat-chips"
                                            [selectable]="selectable" [removable]="removable"
                                            (removed)="remove(category)">
                                            {{category}}
                                            <mat-icon class="chip-clear-color" matChipRemove *ngIf="removable">cancel
                                            </mat-icon>
                                        </mat-chip>
                                        <input #categoryInput [matAutocomplete]="auto" [formControl]="categoryCtrl"
                                            [ngClass]="{'warn-bg': categories.length === 0}"
                                            [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="add($event)">
                                    </mat-chip-list>
                                    <!-- <mat-hint>Enter Categories separated by a comma.</mat-hint> -->
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                                        <mat-option *ngFor="let category of filteredCategory | async"
                                            [value]="category">
                                            {{category}}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-hint>Ex. Neurology, Internal Medicine, Epidemiology/Public Health... (Maximum
                                        5)</mat-hint>
                                </mat-form-field>
                                <p class="mat-error error-font" *ngIf="categories.length === 0">Please Enter at least
                                    one Category</p>
                            </div>

                            <!--Keyword Autocomplete-->
                            <div [ngClass.lt-sm]="'top-padding'">
                                <mat-form-field class="example-chip-list" appearance="outline"
                                    [ngClass]="{'mat-form-field-outline': keywords.length === 0}">
                                    <mat-label [ngClass]="{'warn': keywords.length === 0}">Select Your Internets /
                                        Keywords<span style="color:red"> *</span></mat-label>
                                    <mat-chip-list #chipList_keyword aria-label="keyword selection"
                                        [formControl]="keywordCtrl">
                                        <mat-chip *ngFor="let keyword of keywords" class="mat-chips"
                                            [selectable]="selectable1" [removable]="removable1"
                                            (removed)="remove_keyword(keyword)">
                                            {{keyword|titlecase}}
                                            <mat-icon matChipRemove class="chip-clear-color" *ngIf="removable1">cancel
                                            </mat-icon>
                                        </mat-chip>
                                        <input matInput #keywordInput [matChipInputFor]="chipList_keyword"
                                            [ngClass]="{'warn-bg': keywords.length === 0}"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            (matChipInputTokenEnd)="add_keyword($event)">
                                    </mat-chip-list>
                                    <mat-hint>Ex. Stroke, Epilepsy... (Maximum 5)</mat-hint>
                                </mat-form-field>
                                <p class="mat-error error-font" *ngIf="keywords.length === 0">Please Enter at least
                                    one Keyword</p>
                            </div>
                        </mat-card>
                    </div>

                    <div>
                        <mat-checkbox class="example-margin" color="primary" formControlName="checkbox1"
                            [(ngModel)]="checkbox1" required>
                            {{checkbox1_content}}<span style="color:red"> *</span>
                        </mat-checkbox>
                    </div>

                    <div>
                        <mat-checkbox class="example-margin" color="primary" formControlName="checkbox2"
                            [(ngModel)]="checkbox2" required>
                            I acknowledge that my personal information will be accessed and used in accordance with our
                            <a class="primary-color" [routerLink]="['/privacy-policy']" mat-dialog-close>Privacy
                                Policy</a> to have
                            better user experience. We will not share Your data with third party for marketing or other
                            benefits.<span style="color:red"> *</span>
                        </mat-checkbox>
                    </div>


                    <!-- Login button -->
                    <!-- <div fxLayoutAlign="center" fxLayoutGap="12px" [style.margin-top]="'20px'">
                    <button mat-button mat-raised-button color="primary" [disabled]="registrationFormGroup.invalid" 
                    (click)="login()"  fxFlex="100px">Login</button>
                </div>          -->

                    <div fxLayoutAlign="center" fxLayoutGap="12px" [style.margin-top]="'20px'">
                        <button mat-button mat-raised-button color="primary"
                            [disabled]="registrationFormGroup.invalid ||!this.registrationFormGroup|| this.categories.length === 0 || this.keywords.length === 0"
                            (click)="save()" fxFlex="100px">
                            Save
                        </button>
                    </div>

                </form>
            </mat-card-content>
            <!-- <mat-card-actions align="center">
                <div fxLayoutAlign="center">
                    
                </div>
            </mat-card-actions> -->

        </mat-card>
    </div>
</div>