import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Donation } from '../../model/donation';
@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(private _http: HttpClient) { }
  AddDonation(
    amount: string,
    tax: string,
    total: string,
    sourceId: string,
    cardBrand: string,
    last4: string,
    expMonth: string,
    expYear: string,
    Email: string,
    fullName: string,
    firstName: string,
    lastName: string,
    PhoneNumber: string,
    PostalCode: string,
    PaymentFor: string,
    BillingAddress: string,
    city: string,
    state: string,
    Country: string,
    ArticleTransactionId: string
  ) {
    return this._http.post<Donation>(`${environment.apiUrl}/api/SquarePayment/Payment`, {
      amount,
      tax,
      total,
      sourceId,
      cardBrand,
      last4,
      expMonth,
      expYear,
      Email,
      fullName,
      firstName,
      lastName,
      PhoneNumber,
      PostalCode,
      PaymentFor,
      BillingAddress,
      city,
      state,
      Country,
      ArticleTransactionId
    })
      .pipe(map(res => {
        return res;
      }));
  }
  AddInvoice(
    amount: string,
    discountAmount:string,
    promocodeId:string,
    isPromoCodeApply:boolean,
    tax: string,
    total: string,
    sourceId: string,
    cardBrand: string,
    last4: string,
    expMonth: string,
    expYear: string,
    Email: string,
    fullName: string,
    firstName: string,
    lastName: string,
    PostalCode: string,
    PaymentFor: string,
    BillingAddress: string,
    city: string,
    state: string,
    Country: string,
    ArticleTransactionId: string, 
    billindDept:string,
    billingInstitute:string,
    isAcceptTermsAndCondition:boolean,

  ) {
    return this._http.post<Donation>(`${environment.apiUrl}/api/SquarePayment/Payment`, {
      amount,
      discountAmount,
      promocodeId,
      isPromoCodeApply,
      tax,
      total,
      sourceId,
      cardBrand,
      last4,
      expMonth,
      expYear,
      Email,
      fullName,
      firstName,
      lastName,
      PostalCode,
      PaymentFor,
      BillingAddress,
      city,
      state,
      Country,
      ArticleTransactionId,
      billindDept,
      billingInstitute,
      isAcceptTermsAndCondition,

    })
      .pipe(map(res => {
        return res;
      }));
  }
}
