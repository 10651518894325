import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Addcoauthor } from '../../model/addcoauthor';

@Injectable({
  providedIn: 'root'
})
export class AddcoauthorService {

  constructor(private _http: HttpClient) { }
  AddCoauthor(articleId: string, email: string, firstname: string, lastname: string, institute: string, department: string, city: string, state: string, country: string, degree: string) {
    return this._http.post<Addcoauthor>(`${environment.apiUrl}/api/Registration/Add-Coauthor`, {
      articleId,
      email,
      firstname,
      lastname,
      institute,
      department,
      city,
      state,
      country,
      degree

    })
      .pipe(map(res => {
        return res;
      }));
  }
  UpdateCoauthor(userId: string, firstname: string, email: string, lastname: string, city: string, state: string, country: string, institute: string, department: string, degree: string) {
    return this._http.post<Addcoauthor>(`${environment.apiUrl}/api/Registration/UpdateAuthorData`, {
      userId,
      firstname,
      lastname,
      email,
      city,
      state,
      country,
      institute,
      department,
      degree

    })
      .pipe(map(res => {
        return res;
      }));
  }



}
