
<app-header (toggleSideBarForMe)="sideBarToggler($event)"></app-header>
 <mat-drawer-container class="content"  [ngClass.md]="'content2'" [ngClass.lt-sm]="'content1'"  [ngClass.gt-lg]="'content3'">
    <mat-drawer [mode]="isLargeScreen() ? 'side' : 'over'" [opened]="sidebaropen">
        <app-side-nav position="start"></app-side-nav>
    </mat-drawer>

    <mat-drawer-content >
        <app-ng-dynamic-breadcrumb style="font-weight:500;letter-spacing: 1px;font-size: 20px !important;"></app-ng-dynamic-breadcrumb>
        <router-outlet></router-outlet>
    </mat-drawer-content>

</mat-drawer-container> 
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="primary" type="ball-scale-multiple" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>