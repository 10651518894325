import { Component } from '@angular/core';

/*
*Layout component
*/
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',

})

/*
*class: Layout
*Reference link: 
*/
export class LayoutComponent { }