import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [ './app.component.css' ]
})
export class AppComponent implements OnInit {
  sidebaropen = true;
  title = 'AEBM';
  ngOnInit() { }

  sideBarToggler(event: Event) {
    this.sidebaropen = !this.sidebaropen;
  }
}
