import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { first } from 'rxjs/operators';
import { BlogService } from '../../service/admin/blog.service';
import { SnackbarService } from '../../service/snackbar.service';

@Component({
  selector: 'app-edit-comment',
  templateUrl: './edit-comment.component.html',
  styleUrls: [ './edit-comment.component.css' ]
})
export class EditCommentComponent implements OnInit {
  local_data: any;
  action: any;
  editCommentForm: FormGroup
  title: any;
  comment: any;
  constructor(
    public dialogRef: MatDialogRef<EditCommentComponent>, private matSnack: SnackbarService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: EditCommentComponent,
    private formBuilder: FormBuilder,
    private GetBlogService: BlogService
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }

  ngOnInit(): void {
    this.editCommentForm = this.formBuilder.group({
      title: new FormControl(''),
      comment: new FormControl('', Validators.required),
    })

    if (this.local_data.articleId != undefined) {
      this.title = this.local_data.articleTitle
      this.comment = this.local_data.comment
    }
    if (this.local_data.blogId != undefined) {
      this.title = this.local_data.blogTitle
      this.comment = this.local_data.blogComment
    }
  }

  get formcontrol() {

    return this.editCommentForm.controls;
  }

  //Update Comment Api
  changeComment() {
    if (this.local_data.articleId != undefined) {
      this.GetBlogService.UpdateArticleComment(this.local_data.articleCommentId, this.formcontrol.comment.value)
        .pipe(first())
        .subscribe(
          data => {
            // this.openSnackBar('Thank you, Your comment is submitted for Review.', '')
            this.matSnack.paperShowNotification('Comment Changed', "")
            this.dialogRef.close({ data: this.local_data });

          },
          error => {
          }
        );
    }
    if (this.local_data.blogId != undefined) {
      this.GetBlogService.UpdateBlogComment(this.local_data.blogCommentId, '', this.formcontrol.comment.value)
        .pipe(first())
        .subscribe(
          data => {
            // this.openSnackBar('Thank you, Your comment is submitted for Review.', '')
            this.matSnack.paperShowNotification('Comment Changed', "")
            this.dialogRef.close({ data: this.local_data });

          },
          error => {
          }
        );
    }
  }
}
