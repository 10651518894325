import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { RecentArticle } from '../../model/Home-Page/recentArticle';
import { RestmanagerService } from '../../utils/restmanagerService';
@Injectable({
  providedIn: 'root'
})
export class RecentArticlesService {



  api: string = '';
  _apiEndPoint: string = '';
  private restmanagerService: RestmanagerService;


  constructor(private _http: HttpClient, restmanagerService: RestmanagerService) {
    this._apiEndPoint = environment.Url;
    this.restmanagerService = restmanagerService;


  }

  getArticle<T>(apiURL: string): Observable<T[]> {
    // let parameter = new HttpParams().set('pageNo', '4').set('pageSize', '4')
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }



  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }





}

