import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { RecentArticle } from '../../model/Home-Page/recentArticle';
import { RestmanagerService } from '../../utils/restmanagerService';


@Injectable({
  providedIn: 'root'
})
export class ArticleByCategoriesService {

  api: string = '';
  _apiEndPoint: string = '';
  private restmanagerService: RestmanagerService;


  constructor(private _http: HttpClient, restmanagerService: RestmanagerService) {
    this._apiEndPoint = environment.Url;
    this.restmanagerService = restmanagerService;


  }

  getCategoryArticle<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }

  getKeywordArticle<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }

  getArticleTyped<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)

  }


}
