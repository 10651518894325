import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AuthenticationService } from '../../service/author/authentication.service';
import { CONSTANT } from '../../utils/constant';

@Component({
  selector: 'app-forgot-password-change',
  templateUrl: './forgot-password-change.component.html',
  styleUrls: [ './forgot-password-change.component.css' ]
})
export class ForgotPasswordChangeComponent implements OnInit {

  ChangepasswordFormGroup: FormGroup;

  hidecon = true;
  hide = true;
  hideold = true;
  userinfo: any;
  public txtPassword;
  public txtC_password;
  emailForgot: string;

  constructor(private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router) { }

  //Check password
  checkPasswords(): ValidatorFn {
    //see that the argument is a FormControl
    return (control: FormControl): ValidationErrors => {
      //the formGroup is control.parent
      const group = control.parent as FormGroup;
      //but we must sure that is defined
      if (!group) return null;

      let pass = group.get("newpassword").value;
      let confirmPass = group.get("renewpassword").value;

      return confirmPass ? pass === confirmPass ? null : { mismatch: true } : null;
    };
  }
  ngOnInit(): void {

    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.ChangepasswordFormGroup = this.formBuilder.group({
      newpassword: new FormControl('', [ Validators.required,
      Validators.pattern(CONSTANT.passwordPattern) ]),
      renewpassword: new FormControl('', [ Validators.required,
      this.checkPasswords() ]),

    });

    this.txtPassword = this.ChangepasswordFormGroup.get('newpassword');
    this.txtC_password = this.ChangepasswordFormGroup.get('renewpassword');
    this.emailForgot = localStorage.getItem('forgot-email')
  }
  get f() { return this.ChangepasswordFormGroup.controls; }
  ChangePassword() {
    if (this.ChangepasswordFormGroup.invalid) {

      return;
    }

    // this.router.navigateByUrl('/login');
    this.authenticationService.changeForgotpassword(this.emailForgot, this.f.newpassword.value)
      .pipe(first())
      .subscribe(
        data => {

          this.router.navigateByUrl('/login');

        },

        error => {

        });

  }

}
