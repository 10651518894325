import { Component, OnInit } from '@angular/core';

/*
*Website Layout component
*/
@Component({
  selector: 'app-website-layout',
  templateUrl: './website-layout.component.html',
  styleUrls: ['./website-layout.component.css']
})

/*
*class: Website Layout
*Reference link: 
*/
export class WebsiteLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
