import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Articlecategories } from '../../model/articlecategories';

@Injectable({
  providedIn: 'root'
})
export class ArticlecategoriesService {

  _apiEndPoint: string = '';

  constructor(private _http: HttpClient) {
    this._apiEndPoint = environment.Url;
  }

  //Get speciality api
  getArticleCategories<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }
  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

  AddArticleCategories(articleId: string, categories: string) {
    return this._http.post<Articlecategories>(`${environment.apiUrl}/api/Article/Add-ArticleCategories`, {
      articleId,
      categories

    })
      .pipe(map(res => {
        return res;
      }));
  }

  getUsers() {
    return this._http.get(`${environment.apiUrl}/api/Article/GetCategoriesList`);
  }

}
