import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-editor-popup',
  templateUrl: './editor-popup.component.html',
  styleUrls: ['./editor-popup.component.css']
})
export class EditorPopupComponent implements OnInit {
  url;
  constructor(public dialogRef: MatDialogRef<EditorPopupComponent>,) { }

  ngOnInit(): void {
  }
  onSelectFileprofie(event) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]);

      reader.onload = (event) => {
        // called once readAsDataURL is completed
        this.url = event.target.result;
      }
    }
  }

  doAction() {
    var blob = this.dataURItoBlob(this.url);
    var image = new File([blob], 'image.png');
    this.dialogRef.close(this.url);

  }


  dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.toString().split(',')[1]);

    //var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    var blob = new Blob([ab], { type: 'image/png' }); //or mimeString if you want
    return blob;
  }
}
