import { Component, OnInit } from '@angular/core';


/*
*Common layout component
*/
@Component({
  selector: 'app-common-layout',
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.css']
})

/*
*class: Common layout
*Reference link: 
*/
export class CommonLayoutComponent implements OnInit {
  sidebaropen = true;
  title = 'projectMain';
  constructor() { }

  ngOnInit(): void {
  }
  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width > 720) {
      return true;
    } else {
      return false;
    }
  }
  sideBarToggler(event: Event) {
    this.sidebaropen = !this.sidebaropen;
  }
}
