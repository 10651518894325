import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { InvoiceService } from '../../service/admin/invoice.service';
import { Invoice } from '../../model/invoice';
import { Articleauthorlist } from '../../model/articleauthorlist';
import { ArticleauthorlistService } from '../../service/author/articleauthorlist.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: [ './invoice.component.css' ]
})
export class InvoiceComponent implements OnInit {
  local_data: any;
  action: any;
  receiptdata: any;
  invoiceList: any;
  userinfo: any;
  dataByid: any;
    coFirstname: any;
    coLastName: any;
    coDegree: any;
    coDepartment: any;
    coCity: any;
    coState: any;
    coCountry: any;
    coEmail: any;
    coInstitute: any;
    invoiceinfo: any;
    firstName: any;
    lastName: any;
    amount: any;
    department: any;
    institute:any;
    transactionDetails:any;
    city_author:any;
    state_author:any;
    volume:any;
    issue:any;
    manuscript:any;
    PromoCodeAmount:any;
    datePublish:any;
    dateInvoiced:any;
    Tax: any;
    authorEmail:any
    totalPrice: any;
    country_author: any;
    promoCode: any;
    authorDegree: any;
    authorName: any;
    Name: any;
    manuscripttitle: any;
  status: any;
  BillingName: any;
  BillingDepartment: any;
  BillingInstitute: any;
  BillingAddress: any;
  BillingCity: any;
  BillingCountry: any;
  BillingState: any;
  BillingCardNumber: any;
  BillingCVV: any;
  BillingMM: any;
  BillingYY: any;
  BillingTransacationId: any;
  BillingPostal: any;

  constructor(
    public dialogRef: MatDialogRef<InvoiceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: InvoiceComponent,
    private _sanitizer: DomSanitizer, 
    private getInvoiceListService: InvoiceService,
    private GetArticleAuthorListService: ArticleauthorlistService,
    private spinner: NgxSpinnerService,

  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

  }

  ngOnInit(): void {
    this.invoiceinfo =this.local_data;
    // console.log(this.local_data,"invoice info")
    this.getAuthorInvoice();
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    // console.log(this.userinfo);
    this.receiptdata = this._sanitizer.bypassSecurityTrustHtml(`${this.local_data.receipt}`)
    this.getAuthorInvoiceList()

  }

  ngAfterViewInit() {
    //  Scrolls to top of Page after page view initialized
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  download() {

    var data = document.getElementById('contentToConvert');

    html2canvas(data, {
      logging: false,
      scale: 3
    }).then(function(canvas) {
      var pdf = new jspdf('p', 'mm', 'a4');//A4 paper, portrait
      var ctx = canvas.getContext('2d'),
        a4w = 195.9, a4h = 244.4,//A4 size, 215.9mm x 279.4mm, 10 mm margin on each side, display area 190x277
        imgHeight = Math.floor(a4h * canvas.width / a4w),//Convert pixel height of one page image to A4 display scale

        renderedHeight = 0;

      // var logo = document.getElementById("logo");//Icon placed in header
      while (renderedHeight < canvas.height) {
        var page = document.createElement("canvas");
        page.width = canvas.width;
        page.height = Math.min(imgHeight, canvas.height - renderedHeight);//Maybe less than one page
        // console.log(page.height)
        //Trim the specified area with getImageData and draw it into the canvas object created earlier
        page.getContext('2d').putImageData(ctx.getImageData(0, renderedHeight, canvas.width, Math.min(imgHeight, canvas.height - renderedHeight)), 0, 0);
        //Add an image to the page with a 10 mm / 20 mm margin
        pdf.addImage(page.toDataURL('image/jpeg', 1.0), 'JPEG', 10, 20, a4w, Math.min(a4h, a4w * page.height / page.width));
        //Add header logo
        // pdf.setTextColor(16, 5, 166);
        // pdf.setFontSize(14)
        // pdf.text(10, 10, 'Research Update Journal of Medicine');
        // pdf.addImage(logo, 'PNG', 5, 5);
        // const addFooters = doc => {
        //   const pageCount = doc.internal.getNumberOfPages()

        //   for (var i = 1; i <= pageCount; i++) {
        //     doc.setFontSize(8)
        //     doc.setPage(i)
        //     doc.text('Page No.' + String(i), 210, 274, {
        //       align: 'right'
        //     })
        //   }
        // }
        // console.log(renderedHeight)
        // console.log(imgHeight)
        renderedHeight += imgHeight;
        if (renderedHeight < canvas.height) {
          pdf.addPage();//Add an empty page if there is more to follow

        }

        // addFooters(pdf)
        //   delete page;
      }

      pdf.save('Invoice-Receipt.pdf');
    });
    // html2canvas(data).then(canvas => {
    //   const imgWidth = 190;
    //   const pageHeight = 277;
    //   const imgHeight = canvas.height * imgWidth / canvas.width;
    //   const heightLeft = imgHeight;
    //   const contentDataURL = canvas.toDataURL('image/png');
    //   const pdf = new jspdf('p', 'mm', 'letter');
    //   const position = 0;
    //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
    //   pdf.save('invoice.pdf');
    // });
    // this.dialogRef.close();
  }
    //Get Invoice List api Call
    getAuthorInvoiceList() {

      this.getInvoiceListService.getAuthorInvoiceList<Invoice>(`api/Article/AuthorInvoice?userId=${this.userinfo.id}`)
        .subscribe((data: Invoice[]) => {
  
          this.invoiceList = data as Invoice[];
         
          var userId= this.userinfo.id;
          this.getDataByid(userId)
        })
  
  
    }
    getDataByid(authorId) {
      // var authorId = this.correspondingAuthordata.corospondingAuthorId
      this.GetArticleAuthorListService
        .getDatabyid<Articleauthorlist>(`api/Article/GetAuthorDatabyId?id=${authorId}`)
        .subscribe((data: any) => {
          this.dataByid = data as Articleauthorlist[]
          this.coFirstname = this.dataByid.firstName
          this.coLastName = this.dataByid.lastName
          this.coDegree = this.dataByid.degree
          this.coDepartment = this.dataByid.department
          this.coCity = this.dataByid.city
          this.coState = this.dataByid.state
          this.coCountry = this.dataByid.country
          this.coEmail = this.dataByid.email
          this.coInstitute = this.dataByid.institutionName
  
  
        });
    }
        // Get Invoice List api Call
        getAuthorInvoice() {
          var transactionId= this.invoiceinfo.articleTransactionId
          this.spinner.show()
          this.getInvoiceListService.getInvoiceData<any>(`api/article/PaymentInvoice?articleTransactionId=${transactionId}`)
            .subscribe((data) => {
              this.transactionDetails = data;
              this.authorEmail = this.transactionDetails.authorEmail
              this.Name = this.transactionDetails.authorName
              this.authorDegree = this.transactionDetails.degree
              this.department = this.transactionDetails.department
              this.institute = this.transactionDetails.institute
              this.city_author = this.transactionDetails.city
              this.state_author = this.transactionDetails.state
              this.country_author = this.transactionDetails.country
              this.manuscript = this.transactionDetails.articleDisplayId
              this.manuscripttitle = this.transactionDetails.title
              this.datePublish = this.transactionDetails.publishDate
              this.volume = this.transactionDetails.volume
              this.issue = this.transactionDetails.issue
              this.dateInvoiced = this.transactionDetails.dateofInvoice
              this.PromoCodeAmount = this.transactionDetails.promoCodeAmount
              this.promoCode = this.transactionDetails.promoCode
              this.Tax = this.transactionDetails.tax
              this.amount = this.transactionDetails.amount
              this.totalPrice = this.transactionDetails.total
              this.status = this.transactionDetails.status
              this.BillingName = this.transactionDetails.billingName
              this.BillingDepartment = this.transactionDetails.billingDepartment
              this.BillingInstitute = this.transactionDetails.billingInstitute
              this.BillingAddress= this.transactionDetails.billingAddressLine
              this.BillingCity= this.transactionDetails.billingCity
              this.BillingCountry= this.transactionDetails.billingCountry
              this.BillingState= this.transactionDetails.billingState
              this.BillingCardNumber= this.transactionDetails.billingCardNumber
              // this.BillingCVV= this.transactionDetails.billingInstitute
              this.BillingMM= this.transactionDetails.billingMonth
              this.BillingYY= this.transactionDetails.billingYear
              this.BillingPostal= this.transactionDetails.billingPostal
              this.BillingTransacationId= this.transactionDetails.billingTransactionId
              // console.log( data,"tranasction Details")
              this.spinner.hide()
            })
        }
}
