import { MapsAPILoader } from '@agm/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { identity, Observable } from 'rxjs';
import { first, map, startWith } from 'rxjs/operators';
import { GetDataById } from 'src/app/shared/model/GetDataById';
import { ArticlecategoriesService } from 'src/app/shared/service/author/articlecategories.service';
import { AuthenticationService } from 'src/app/shared/service/author/authentication.service';
import { DataService } from 'src/app/shared/service/author/DataService.service';
import { GetElementByIDService } from 'src/app/shared/service/author/get-element-by-id.service';
import { SnackbarService } from 'src/app/shared/service/snackbar.service';
import PlaceResult = google.maps.places.PlaceResult;

@Component({
  selector: 'app-updateProfile',
  templateUrl: './updateProfile.component.html',
  styleUrls: [ './updateProfile.component.css' ]
})
export class UpdateProfileComponent implements OnInit {
  registrationFormGroup: FormGroup;
  userId: string;

  visible = true;
  selectable = true;
  removable = true;
  selectable1 = true;
  removable1 = true;
  id;
  separatorKeysCodes: number[] = [ ENTER, COMMA ];
  categoryCtrl = new FormControl();
  filteredCategory: Observable<string[]>;
  categories: string[] = [];
  allcategoris: string[] = [];
  keywordCtrl = new FormControl();
  filteredKeywords: Observable<string[]>;
  keywords: string[] = [];
  allKeywords: string[] = [];
  addArticlekeywordFormGroup: FormGroup

  addOnBlur = true;
  isRequired = true;


  @ViewChild('categoryInput') categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  @ViewChild('keywordInput') keywordInput: ElementRef<HTMLInputElement>;

  AllArticleCategories;
  addArticleCategoriesFormGroup: FormGroup
  userData: string;
  data_profile;
  data: unknown[];
  data2: unknown[];
  checkbox1_content = "We respect your privacy. Please tick the box if you do not wish to receive news, promotions and special offers about our products and services.";
  checkbox2_content = "	I acknowledge that my personal information will be accessed, used and otherwise processed in accordance with the Publisher's Data User Privacy Policy and the Aries Privacy Policy.";


  articleCategory;
  articleCategorylist: any;
  f_name: GetDataById[];
  l_name: any;
  degree: any;
  orcid: any;
  email: any;
  department: any;
  institution: any;
  city: any;
  state: any;
  country: any;
  zip: any;
  category: any;
  userinfo;
  userinfoid: any;
  checkbox1: any;
  checkbox2: any;
  @ViewChild("search") public searchElementRef: ElementRef;
  public selectedAddress: PlaceResult;
  public street_number: string;
  public route2: string;
  public locality: string;
  public sublocality: string;
  // public country: string;
  // public city: string;
  public postalCode: string;
  public address1: string;

  constructor(private formBuilder: FormBuilder, private GetArticleCategoriesService: ArticlecategoriesService,
    private authenticationService: AuthenticationService, private getDataService: GetElementByIDService,
    private router: Router, private cdr: ChangeDetectorRef,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private matSnack: SnackbarService,
    private DataService: DataService) {
    this.filteredCategory = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      map((category: string | null) => category ? this._filter(category) : this.allcategoris.slice()));

    this.filteredKeywords = this.keywordCtrl.valueChanges.pipe(
      map((keyword: string | null) => keyword ? this.filter_keyword(keyword) : this.allKeywords.slice()));

  }

  ngOnInit(): void {

    this.registrationFormGroup = this.formBuilder.group({
      f_name: new FormControl('', Validators.required),
      l_name: new FormControl('', Validators.required),
      degree: new FormControl('', Validators.required),
      ORCID: new FormControl(''),
      email: new FormControl('', Validators.required),
      department: new FormControl('', Validators.required),
      institution: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      categoryCtrl: new FormControl(''),
      keywordCtrl: new FormControl(''),
      checkbox1: new FormControl('', Validators.required),
      checkbox2: new FormControl('', Validators.required),

    });

    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.getDataByid();
    this.addArticleCategoriesFormGroup = this.formBuilder.group({
      categoris: new FormControl(''),
    })

    this.addArticlekeywordFormGroup = this.formBuilder.group({
      keyword: new FormControl(''),
    })

    this.getCategories();

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        // componentRestrictions: { country: 'US' },
        types: [ '(cities)' ]  // geocode (cities) (regions) establishment address 
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          this.getAddressComponentByPlace(place);
        });
      });
    });
  }
  ngAfterContentChecked() {
    this.cdr.detectChanges();
    // call or add here your code
  }
  remove(fruit: string): void {
    const index = this.categories.indexOf(fruit);

    if (index >= 0) {
      this.categories.splice(index, 1);
    }
  }

  //remove chip
  remove_keyword(keyword: string): void {
    const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);
    }
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our category
    if ((value || '').trim()) {
      this.categories.push(value.trim());
    }



    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.categoryCtrl.setValue(null);
  }


  //add chip
  add_keyword(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our keyword
    if ((value || '').trim() && this.keywords.length < 10) { // <-- THIS length condition
      this.keywords.push(value.trim());
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.keywordCtrl.setValue(null);
  }
  selected(event: MatAutocompleteSelectedEvent): void {
    let index = this.categories.indexOf(event.option.viewValue);

    if (index == -1) {

      this.categories.push(event.option.viewValue);
    } else {
      this.matSnack.withoutButton("This category is already added", "")
    }
    // this.categoris.push(event.option.viewValue);
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue('');
  }

  selected_keyword(event: MatAutocompleteSelectedEvent): void {
    if (this.keywords.length < 10) { // <-- THIS length condition
      this.keywords.push(event.option.viewValue);
      this.keywordInput.nativeElement.value = '';
      this.keywordCtrl.setValue(null);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    console.log(this.allcategoris.filter(category => category.toLowerCase().indexOf(filterValue) === 0))
    return this.allcategoris.filter(category => category.toLowerCase().indexOf(filterValue) === 0);
  }

  private filter_keyword(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allKeywords.filter(keyword => keyword.toLowerCase().indexOf(filterValue) === 0);
  }

  get f() { return this.registrationFormGroup.controls; }

  getCategories() {
    this.GetArticleCategoriesService.getUsers().subscribe(
      (val: any[]) => {
        this.allcategoris = val.map(user => user.categoryName);
        this.categoryCtrl.setValue(null); //use this to apply changes instantly
      }
    )
  }


  joinsymptomps(arrayValue) {
    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function(item) {
      return item;
    }).join(", ");
  }

  getDataByid() {
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.userinfoid = this.userinfo.id;
    this.getDataService.getDataByid<GetDataById>(`api/Registration/getUser?id=${this.userinfoid}`)
      .subscribe((data: any) => {

        this.data_profile = data as GetDataById[];
        this.f_name = this.data_profile.first_Name
        this.l_name = this.data_profile.last_Name
        this.degree = this.data_profile.degree
        this.degree = this.data_profile.degree
        this.orcid = this.data_profile.orcid
        this.email = this.data_profile.email
        this.department = this.data_profile.department
        this.institution = this.data_profile.institute
        this.city = this.data_profile.city
        this.state = this.data_profile.state
        this.country = this.data_profile.country
        null
        if (this.data_profile.personal_categories != null) {
          this.categories = this.data_profile.personal_categories.split(',')
        }

        if (this.data_profile.personal_keywords != null) {
          this.keywords = this.data_profile.personal_keywords.split(',')
        }

        this.checkbox1 = this.data_profile.boxpromotion
        this.checkbox2 = this.data_profile.boxPersonalDetails
        this.DataService.setLatestnameDate(this.data_profile);
      });
  }

  save() {
    this.userinfo = JSON.parse(localStorage.getItem('currentUser'));
    this.userinfoid = this.userinfo.id;
    this.authenticationService.updateUser(this.userinfoid,
      this.f.email.value,
      this.f.f_name.value,
      this.f.l_name.value,
      this.f.ORCID.value,
      this.f.city.value,
      this.f.state.value,
      this.f.country.value,
      null,
      this.f.institution.value,
      this.f.department.value,
      this.f.degree.value,
      this.joinsymptomps(this.categories),
      this.joinsymptomps(this.keywords),
      this.f.checkbox1.value,
      this.f.checkbox2.value,


    )
      .pipe(first())
      .subscribe(
        data => {

          this.getDataByid()
          // console.log(data, "data")
          this.router.navigateByUrl('/dashboard')
        },
        error => {
        }
      );

  }
  getAddressComponentByPlace(place) {
    let components = place.address_components;
    let city = null;
    let postalCode = null;
    let route2 = null;
    let locality = null;
    let street_number = null;
    let address1 = null;
    let country = null

    for (var i = 0, component; component = components[ i ]; i++) {

      if (component.types[ 0 ] == 'street_number') {
        street_number = component[ 'long_name' ];
      }
      if (component.types[ 0 ] == 'route') {
        route2 = component[ 'long_name' ];
      }
      address1 = street_number + ' ' + route2;
      if (component.types[ 0 ] == 'locality') {
        locality = component[ 'short_name' ];
      }
      if (component.types[ 0 ] == 'administrative_area_level_1') {
        city = component[ 'long_name' ];
      }

      if (component.types[ 0 ] == 'country') {
        country = component[ 'short_name' ];
      }
      if (component.types[ 0 ] == 'postal_code') {
        postalCode = component[ 'short_name' ];
      }
    }

    this.route2 = route2;
    this.street_number = street_number;
    this.locality = locality;
    this.city = city;
    this.country = country;
    this.postalCode = postalCode;
    this.address1 = address1;
  }
}
