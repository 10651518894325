import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
@Directive({
  selector: '[uppercase]',
  
})
export class UppercaseInputDirective {
  result:any;

  @Input('uppercase') string;
  // @Input() uppercase:string;

  constructor(public el: ElementRef) { }

 @HostListener ('blur') onBlur(){
   let value:string =this.el.nativeElement.value;
   let words = value.split(" ");
  //  console.log(words,"split word")
   for (let index = 0; index < words.length; index++) {
     let word = words[index];
     if (index > 0 && this.isPreposition(word)) {
       words[index] = word.toLowerCase();
     }
     else {
       words[index] = this.toTitleCase(word);
     }
   }

    this.el.nativeElement.value = words.join(" ");

 }

 private toTitleCase(word: string): string {
   return word.substr(0, 1).toUpperCase() + word.substr(1).toLowerCase();
 }

 private isPreposition(word: string): boolean {
   let lowerCaseWords = ["a","an","of", "the"]; // add more words to exclude here

   return lowerCaseWords.includes(word.toLowerCase());

 }   
 }


