<div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
  <form [formGroup]="emailform">
    <div>
      <mat-form-field class="example-container" appearance="outline">
        <input matInput type="email" placeholder="pat@example.com" formControlName="email" class="form-control"
          [ngClass]="{'is-invalid':emailform.errors && emailform.touched}" [(ngModel)]="subemail" [value]="subemail">
        <mat-error>Please Subscribe your Email-id</mat-error>
      </mat-form-field>
    </div>
    <div>
      <button mat-flat-button color="primary" (click)="authorEmail()">Search</button>
    </div>
  </form>
</div>
<br>
<a mat-raised-button color="primary" (click)="openDialog('Add',{})">Add Authors</a>
<br>
<br>


<!-- Mat table -->
<section style="width: 100%;">

  <p *ngIf="this.authors === 0" class="no-data">There is no author added yet!</p>
  <p class="color-primary mat-small" fxLayoutAlign="end end">*In one day one reminder send to particular Corresponding
    Author </p>
  <div>
    <div>
      <table mat-table class="mat-elevation-z8 move" [dataSource]="this.authors" #table cdkDropList
        [cdkDropListData]="this.authors" (cdkDropListDropped)="drop($event)">


        <ng-container matColumnDef="rank" fxFlex="10">
          <th mat-header-cell *matHeaderCellDef class="content-center"> Order </th>
          <td mat-cell *matCellDef="let author" class="content-center">
            <mat-icon>
              <span class="material-icons-outlined">
                drag_indicator
              </span>
            </mat-icon>
          </td>
        </ng-container>



        <ng-container matColumnDef="corresponding">
          <th mat-header-cell *matHeaderCellDef fxflex="10" class="content-center"> Corresponding Author</th>
          <td mat-cell *matCellDef="let author" class="content-center">
            <mat-radio-group [(ngModel)]="this.correspondingAuthordata.corospondingAuthorId">
              <mat-radio-button (click)="$event.stopPropagation()" (change)="radioChange($event)"
                [disabled]="this.userInfo.id != author.authorId" [value]="author.authorId">
              </mat-radio-button>
            </mat-radio-group>
          </td>
        </ng-container>


        <ng-container matColumnDef="Authors_Details">
          <th mat-header-cell *matHeaderCellDef class="content-center"> Author's Details</th>
          <td mat-cell *matCellDef="let author">
            <b>{{author.firstName}} {{author.lastName}}, {{author.degree}}</b><br>
            <p>
              {{author.department}}, {{author.institute}},<br>
              {{author.city}}, {{author.state}}, {{author.country}}<br>
              <span class="highlight">{{author.email}}</span>
            </p>

          </td>
        </ng-container>


        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef class="content-center"> Action </th>
          <td mat-cell *matCellDef="let author; let i = index;" class="content-center">
            <div fxLayout="column" fxLayoutAlign="center " fxLayoutGap="5px">
              <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">
                <button mat-raised-button color="primary" class="push-right"
                  (click)="edit('update', author)">Edit</button>
                <button mat-raised-button color="primary" class="push-right"
                  *ngIf="this.authors[i].isAuthorShipAccepted == 'Yes'">Approved</button>
                <button mat-raised-button color="warn" class="push-right" (click)="sendreminder(author.authorId)"
                  *ngIf="this.authors[i].isAuthorShipAccepted == 'No'">Reminder</button>
                <button mat-icon-button (click)="deleteAuthor(author)" [disabled]="author.isDeleting">
                  <span *ngIf="author.isDeleting"></span>
                  <span *ngIf="!author.isDeleting">
                    <mat-icon class="material-icons">
                      delete
                    </mat-icon>
                  </span>
                </button>
              </div>
              <!-- <div fxLayout="row" fxLayoutAlign="end end">
                <p class="mat-small color-primary" *ngIf="this.authors[i].isReminderSend == 'Yes'">*Reminder Send</p>
              </div> -->
            </div>

          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag cdkDragLockAxis="y" [cdkDragData]=row>
        </tr>
      </table>

    </div>
  </div>
  <br>


</section>

<!-- <table class="table"  >
    <thead>
        <tr class="t_body">
            <th width="10%" style="text-align: start;">Position</th>
            <th width="5%" style="text-align: start;"><mat-checkbox [disabled]="true"></mat-checkbox></th>
            <th width="85%" style="text-align: start;">Author Details</th>  
        </tr>
    </thead>
    <tbody cdkDropList 
    #authorsList="cdkDropList" 
    [cdkDropListData]="authors"
    (cdkDropListDropped)="onDrop($event)">
        <tr  class="movie-block" *ngFor="let author of authors" cdkDrag>
            <td>
                {{author.id}}
            </td>
            <td>
                <mat-checkbox class="example-margin"></mat-checkbox>
            </td>
            <td style="text-align: start;"><b>{{author.f_name}}{{author.l_name}} {{author.affiliation}} </b><br>
               {{author.other}}, {{author.department}}, {{author.city}},  {{author.state}},{{author.country}}<br>
               <span class="highlight">{{author.email}}</span>
            </td>
            <td style="text-align: start;">
                <button mat-raised-button color="accent" routerLink="/edit/{{author.id}}">
                    <mat-icon class="material-icons">
                        mode_edit
                    </mat-icon>
                    Edit
                </button>
               
            </td>
             <td>
                <button mat-icon-button color="warn" (click)="deleteAuthor(author.id)" [disabled]="author.isDeleting">
                    <span *ngIf="author.isDeleting"></span>
                    <span *ngIf="!author.isDeleting">
                        <mat-icon class="material-icons">
                            delete
                        </mat-icon>
                    </span>
                </button>
            </td> 
        </tr>
    </tbody>
</table>

 -->