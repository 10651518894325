import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { catchError, first } from 'rxjs/operators';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AddEditComponent } from './add-edit.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { MatRadioChange } from '@angular/material/radio';
import { author } from 'src/app/shared/service/class';
import { Articleauthorlist } from 'src/app/shared/model/articleauthorlist';
import { ArticleauthorlistService } from 'src/app/shared/service/author/articleauthorlist.service';
import { AddcoauthorService } from 'src/app/shared/service/author/addcoauthor.service';
import { DeleteComponent } from 'src/app/shared/CRUD/delete/delete/delete.component';
/*
*List component
*/
@Component({
  selector: 'app-list',
  templateUrl: 'list.component.html',
  styleUrls: [ 'list.component.css' ]

})

/*
*class: List
*Reference link: 
*/
export class ListComponent implements OnInit {

  subemail;
  resultsLength = 0;
  isRateLimitReached = false;
  authors;
  authorData: any = [];
  new_author: any = [];
  dataSource: MatTableDataSource<author>;
  // isLoadingResults = true;
  displayedColumns: string[] = [ 'rank', 'corresponding', 'Authors_Details', 'Action' ];
  selection = new SelectionModel<author>(true, []);
  articleId: string;
  authorsubscribeemail;
  local_data: any;
  Articleauthorlist: Articleauthorlist[]
  subscribers;
  authorName: any;
  emailform: FormGroup
  authorUserid: any;
  questions: any;
  finduserId: any;
  dataByid: Articleauthorlist[];
  @ViewChild(MatTable) table: MatTable<any>;
  filter: any;
  correspondingauthorData: any;
  correspondingAuthordata: any;
  userInfo: any;

  constructor(private dialog: MatDialog, private snackBar: MatSnackBar,
    private GetArticleAuthorListService: ArticleauthorlistService, private fb: FormBuilder,
    private addcoAuthorService: AddcoauthorService,
    private changeDetectorRefs: ChangeDetectorRef) {
    // this.authorService.getAll().subscribe(data => {
    this.authorData = this.authors;
    this.dataSource = new MatTableDataSource<author>(this.authorData);


    // })
  }

  ngOnInit() {

    // this.authorService.getAll()
    //   .pipe(first())
    //   .subscribe(authors => this.authors = authors);
    this.userInfo = JSON.parse(localStorage.getItem('currentUser'))
    this.emailform = this.fb.group({
      email: new FormControl(''),
    })
    // this.getCategories()
    this.getauthorlist()
    this.getauthorsubsribeemail()

    this.getCorrespodingAuthor()
  }
  selected: string;
  radioChange($event: MatRadioChange) {
    this.correspondingauthorData = $event.value
    this.correspondingAuthor(this.correspondingauthorData)
  }
  get f() { return this.emailform.controls; }

  joinsymptomps(arrayValue) {
    var StringValue = arrayValue
    return Array.prototype.map.call(StringValue, function(item) {
      return item;
    }).join(";");
  }

  drop(event: CdkDragDrop<string[]>) {
    const previousIndex = this.authors.findIndex(row => row === event.item.data);
    moveItemInArray(this.authors, previousIndex, event.currentIndex);
    // this.authors = this.authors.slice();
    this.table.renderRows();
    localStorage.setItem('authorlist', this.joinsymptomps(this.authors.map(({ authorId }) => authorId)))

  }

  fileNameDialogRef: MatDialogRef<AddEditComponent>;

  //Add edit dialog open
  openDialog(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddEditComponent, {
      height: '90%',
      width: '60%',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'Add') {
        this.addRowData(result);
        this.getauthorlist()
      }
    });
  }

  addRowData(row_obj) {
    this.authors.push = row_obj
    this.getauthorlist()
  }
  edit(action, obj) {
    obj.action = action;
    const dialogRef = this.dialog.open(AddEditComponent, {
      height: '90%',
      width: '60%',
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.event == 'update') {
          this.updateRowData(result);
        }

      }
    });
  }
  updateRowData(row_obj) {
    this.authors = this.authors.filter((value, key) => {
      if (value.authorId == row_obj.authorId) {
        value = row_obj;
      }
      return true;
    });
    this.getauthorlist()
  }

  primaryClick(author) {
    this.authorData = author;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [ 'redNoMatch' ]

    });
  }


  getauthorlist() {
    var articleId = localStorage.getItem('articleId')
    this.GetArticleAuthorListService
      .getArticleAuthorlist<Articleauthorlist>(`api/Article/GetArticleAuthorsList?articleId=${articleId}`)
      .subscribe((data: any) => {
        if (data || data != null) {
          this.authors = data as Articleauthorlist[]
          localStorage.setItem('authorlist', this.joinsymptomps(this.authors.map(({ authorId }) => authorId)))
          this.changeDetectorRefs.detectChanges();
        }
        else {
        }
      });
  }

  getauthorsubsribeemail() {
    var articleId = localStorage.getItem('articleId')
    this.GetArticleAuthorListService
      .getEmailsubscribeauthor<Articleauthorlist>(`api/Article/ListOfEmailSubscriberAuthor`)
      .subscribe((data: any) => {
        if (data || data != null) {
          this.subscribers = data
          this.authorName = this.subscribers.map(({ emailid }) => emailid)
          this.authorUserid = this.subscribers.map(({ userId }) => userId)
          // this.authorEmail()
        }
        else {
        }
      });
  }

  authorEmail() {
    const array = this.authorName;
    // console.log(this.authorName)  
    const sameCaseArray = array.map(value => value.toLowerCase());

    var n = sameCaseArray.includes(this.f.email.value); // true
    // console.log(n)
    if (n == true) {
      // console.log(this.f.email.value)
      var EmailData = this.getEmailDataByFilter(this.f.email.value)
      this.finduserId = EmailData.userId
      this.getDataByid()
      this.openSnackBar('Author add successfully', '')
      this.emailform.reset();
    }
    else if (n == false) {
      this.openSnackBar('Author is not available', '')
    }

    var articleId = localStorage.getItem('articleId')
    this.GetArticleAuthorListService.addauthorinlist(articleId, this.finduserId)
      .pipe(first())
      .subscribe(
        data => {
          this.getauthorlist()
          window.location.reload()
        },
        error => {
        }
      );

  }

  getEmailDataByFilter(emailid) {
    return this.subscribers.find(x => x.emailid === emailid);
  }



  getDataByid() {
    this.GetArticleAuthorListService
      .getDatabyid<Articleauthorlist>(`api/Article/GetAuthorDatabyId?id=${this.finduserId}`)
      .subscribe((data: any) => {
        if (data || data != null) {
          this.dataByid = data as Articleauthorlist[]
          this.addRowData1()
        }
        else {
        }
      });
  }

  addRowData1() {
    this.authors.push(
      this.dataByid,

    );
    this.table.renderRows();
    // console.log(this.authors)
    this.new_author = this.dataByid
    // console.log("new",this.new_author)

  }


  sendreminder(event) {
    var articleId = localStorage.getItem('articleId')
    this.GetArticleAuthorListService.Sendreminder(event, articleId)
      .pipe(first())
      .subscribe(
        data => {

          this.openSnackBar('Reminder send successfully', '')
        },
        error => {
        }
      );
  }


  //delete author
  // deleteAuthor(index, authorId) {
  //   var articleId = localStorage.getItem('articleId')
  //   this.GetArticleAuthorListService.deleteAuthorfromlist(articleId, authorId)
  //     .pipe(first())
  //     .subscribe(
  //       data => {
  //         this.getauthorlist()
  //         this.authors.splice(index, 1);
  //         this.openSnackBar('Author delete successfully', '')
  //       },
  //       error => {
  //       }
  //     );

  // }
  deleteAuthor(obj) {

    const dialogRef = this.dialog.open(DeleteComponent, {
      data: obj
    });

    dialogRef.afterClosed().subscribe(result => {
      this.authors.splice(result, 1);

      this.getauthorlist()
    });
  }


  searchEmail() {
  }

  correspondingAuthor(authorId) {
    var articleId = localStorage.getItem('articleId')
    this.GetArticleAuthorListService.correspondingAuthor<Articleauthorlist>(`api/Article/AddCorospondingAuthorToArticle?articleId=${articleId}&authorId=${authorId}`)
      .subscribe((data) => {

        // this.correspondingAuthordata = data as Articleauthorlist[]

      })
  }
  getCorrespodingAuthor() {
    var articleId = localStorage.getItem('articleId')
    this.GetArticleAuthorListService.getCorrespondingAuthor<Articleauthorlist>(`api/Article/GetCorrospondingAuthor?articleId=${articleId}`)
      .subscribe((data) => {

        this.correspondingAuthordata = data as Articleauthorlist[]

      })

  }

}
