import { Component, ElementRef, Inject, NgZone, OnInit, Optional, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { first, map, startWith } from 'rxjs/operators';
import { authorService } from '../service/crud/author.service';
import { AlertService } from '../service/crud/alert.service';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs/internal/Observable';
import { MatChipInputEvent } from '@angular/material/chips/chip-input';
// import { ErrorStateMatcher } from '@angular/material/core';
// import { LoaderService } from '../service/loader/loader.service';
// import { MapsAPILoader } from '@agm/core';
import PlaceResult = google.maps.places.PlaceResult;
import { CONSTANT } from '../utils/constant';
import { AddcoauthorService } from '../../shared/service/author/addcoauthor.service'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ArticleauthorlistService } from '../service/author/articleauthorlist.service';
import { Articleauthorlist } from '../model/articleauthorlist';
import { ArticlecontributionsService } from '../../shared/service/author/articlecontributions.service'
import { stringify } from '@angular/compiler/src/util';
import { MapsAPILoader } from '@agm/core';

interface special {
  value: string;
  viewValue: string;
}

/*
*Add-edit component
*/



@Component({
  selector: 'add-edit.component',
  templateUrl: 'add-edit.component.html'
})




/*
*class: Add-edit
*Reference link: 
*/
export class AddEditComponent implements OnInit {


  form: FormGroup;
  id: string;
  isSelected: boolean = false;
  others: Boolean = undefined;
  isAddMode: boolean;
  loading = false;
  submitted = false;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ ENTER, COMMA ];
  filteredDept: Observable<string[]>;
  depts: string[] = [];

  // Select form control (city,state,country)
  selectFormControl = new FormControl('', [
    Validators.required
  ]);

  // // mat select error
  // matcher = new MyErrorStateMatcher();

  // department array

  alldepts: string[] = [
    'Allergy and immunology',
    'Anesthesiology',
    'Dermatology',
    'Diagnostic radiology',
    'Emergency medicine',
    'Family medicine',
    'Internal medicine',
    'Medical genetics',
    'Neurology',
    'Nuclear medicine',
    'Obstetrics and gynecology',
    'Ophthalmology',
    'other',
    'Pathology',
    'Pediatrics',
    'Physical Medicine and rehabilitation',
    'Preventive medicine',
    'Psychiatry',
    'Radiation oncology',
    'Surgery',
    'Urology',
  ];
  public selectedAddress: PlaceResult;
  public street_number: string;
  public route2: string;
  public locality: string;
  public sublocality: string;
  public country: string;
  public city: string;
  public postalCode: string;
  public address1: string;
  addauthorheading = (CONSTANT.addauthorheading)
  firstnamelabel = (CONSTANT.firstnamelabel)
  firstnamerequired = (CONSTANT.firstnamerequired)
  lastnamelabel = (CONSTANT.lastnamelabel)
  lastnamerequired = (CONSTANT.lastnamerequired)
  degreelabel = (CONSTANT.degreelabel)
  degreehint = (CONSTANT.degreehint)
  degreerequired = (CONSTANT.degreerequired)
  emaillabel = (CONSTANT.emaillabel)
  emailrequired = (CONSTANT.emailrequired)
  institutelabel = (CONSTANT.institutelabel)
  instituterequired = (CONSTANT.instituterequired)
  departmentlabel = (CONSTANT.departmentlabel)
  departmenthint = (CONSTANT.departmenthint)
  departmentrequired = (CONSTANT.departmentrequired)
  citylabel = (CONSTANT.citylabel)
  cityrequired = (CONSTANT.cityrequired)
  statelabel = (CONSTANT.statelabel)
  staterequired = (CONSTANT.staterequired)
  countrylabel = (CONSTANT.countrylabel)
  countryrequired = (CONSTANT.countryrequired)
  savebutton = (CONSTANT.save)
  backbutton = (CONSTANT.back)
  action: string;
  local_data: any;
  authors;
  @ViewChild("search") public searchElementRef: ElementRef;

  articleId: string;
  txtEmail: any;
  // constructor
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: authorService,
    private alertService: AlertService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private GetCoauthorService: AddcoauthorService,
    private GetArticleAuthorListService: ArticleauthorlistService,
    public dialogRef: MatDialogRef<AddEditComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: AddEditComponent
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;
  }




  // ngoninit
  ngOnInit() {
    this.articleId = localStorage.getItem('authorId')
    // this.id = this.route.snapshot.params['id'];
    // this.isAddMode = !this.id;
    // validators

    this.form = this.formBuilder.group({

      f_name: [ '', Validators.required ],
      l_name: [ '', Validators.required ],
      degree: [ '', Validators.required ],
      email: new FormControl('', [ Validators.required, Validators.pattern(CONSTANT.emailPattern) ]),
      affiliation: [ '' ],
      orcid: [ '' ],
      department: [ '' ],
      city: [ '', Validators.required ],
      state: [ '', Validators.required ],
      country: [ '', Validators.required ],

    });
    this.txtEmail = this.form.get('email');
    // add or edit mode
    // if (!this.isAddMode) {
    //   this.userService.getById(this.id)
    //     .pipe(first())
    //     .subscribe(x => this.form.patchValue(x));
    // }

    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        // componentRestrictions: { country: 'US' },
        types: [ '(cities)' ]  // geocode (cities) (regions) establishment address 
      });

      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          this.getAddressComponentByPlace(place);
        });
      });
    });
  }

  deptCtrl = new FormControl(null, [
    Validators.required,
    forbiddenNamesValidator(this.alldepts)
  ]);

  // autocomplete and department autocomplete
  @ViewChild('deptInput') deptInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  // chip add
  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our dept
    if ((value || '').trim() && this.depts.length < 1) {
      this.depts.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.deptCtrl.setValue(null);
  }

  // remove chip
  remove(dept: string): void {
    const index = this.depts.indexOf(dept);
    if (index >= 0) {
      this.depts.splice(index, 1);
    }
  }

  // select autocomplete
  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.depts.length < 1) {
      this.depts.push(event.option.viewValue);
      this.isSelected = true;

      if (event.option.value == "other") {
        const selectedValue = event.option.value;
        this.others = true;
        console.log(selectedValue)
      } else {
        this.others = false;
      }
    }
  }

  // filter autocomplete option
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.alldepts.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  // submit button
  // onSubmitAuthor() {
  //   this.submitted = true;

  //   // reset alerts on submit
  //   this.alertService.clear();

  //   // stop here if form is invalid
  //   if (this.form.invalid) {
  //     console.log('invalid');
  //     return;
  //   }

  //   // loading
  //   this.loading = true;
  //   if (this.isAddMode) {
  //     this.createUser();
  //     console.log('create');
  //   }
  //   else {
  //     this.updateUser();
  //     console.log('update');
  //   }
  // }

  // create author
  // private createUser() {

  //   this.userService.create(this.form.value)
  //     .pipe(first())
  //     .subscribe({

  //       next: () => {
  //         this.alertService.success('User added', { keepAfterRouteChange: true });
  //         this.router.navigate(['/coauthor'], { relativeTo: this.route });
  //       },
  //       error: error => {
  //         this.alertService.error(error);
  //         this.loading = false;
  //       }
  //     });
  // }

  // update author
  // private updateUser() {
  //   this.userService.update(this.id, this.form.value)
  //     .pipe(first())
  //     .subscribe({
  //       next: () => {
  //         this.alertService.success('User updated', { keepAfterRouteChange: true });
  //         this.router.navigate(['/coauthor'], { relativeTo: this.route });
  //       },
  //       error: error => {
  //         this.alertService.error(error);
  //         this.loading = false;
  //       }

  //     });
  // }

  addCoauthor() {
    var articleId = localStorage.getItem('articleId')
    if (this.local_data.authorId == null) {
      this.GetCoauthorService.AddCoauthor(articleId, this.f.email.value, this.f.f_name.value, this.f.l_name.value, this.f.affiliation.value, this.f.department.value, this.f.city.value, this.f.state.value, this.f.country.value, this.f.degree.value)
        .pipe(first())
        .subscribe(
          data => {
            this.getauthorlist()
          },
          error => {
          }
        );
    }
    if (this.local_data.authorId != null) {
      var authorId = this.local_data.authorId
      this.GetCoauthorService.UpdateCoauthor(authorId, this.f.f_name.value, this.f.email.value, this.f.l_name.value, this.f.city.value, this.f.state.value, this.f.country.value, this.f.affiliation.value, this.f.department.value, this.f.degree.value)
        .pipe(first())
        .subscribe(
          data => {
            this.getauthorlist()
          },
          error => {
          }
        );
    }
  }
  getauthorlist() {
    var articleId = localStorage.getItem('articleId')
    this.GetArticleAuthorListService
      .getArticleAuthorlist<Articleauthorlist>(`api/Article/GetArticleAuthorsList?articleId=${articleId}`)
      .subscribe((data: any) => {
        if (data || data != null) {
          this.authors =
            this.dialogRef.close({ event: this.action, data: this.local_data });
          window.location.reload()
        }
        else {
        }
      });
  }

  closedialog() {
    this.dialogRef.close({ event: 'Cancel' });
  }

  getAddressComponentByPlace(place) {
    let components = place.address_components;
    let city = null;
    let postalCode = null;
    let route2 = null;
    let locality = null;
    let street_number = null;
    let address1 = null;
    let country = null

    for (var i = 0, component; component = components[ i ]; i++) {

      if (component.types[ 0 ] == 'street_number') {
        street_number = component[ 'long_name' ];
      }
      if (component.types[ 0 ] == 'route') {
        route2 = component[ 'long_name' ];
      }
      address1 = street_number + ' ' + route2;
      if (component.types[ 0 ] == 'locality') {
        locality = component[ 'short_name' ];
      }
      if (component.types[ 0 ] == 'administrative_area_level_1') {
        city = component[ 'long_name' ];
      }
      if (component.types[ 0 ] == 'country') {
        postalCode = component[ 'short_name' ];
      }
      if (component.types[ 0 ] == 'country') {
        country = component[ 'short_name' ];
      }
    }

    this.route2 = route2;
    this.street_number = street_number;
    this.locality = locality;
    this.city = city;
    this.country = country;
    this.postalCode = postalCode;
    this.address1 = address1;
  }
}

// forbidden validator for autocomplete

export function forbiddenNamesValidator(Services: any[]): ValidatorFn {
  return (control: AbstractControl): { [ key: string ]: any } | null => {
    const index = Services.findIndex(Service => {
      return new RegExp("^" + Service.name + "$").test(control.value);
    });
    return index < 0 ? { forbiddenNames: { value: control.value } } : null;
  };


}

/** dropdown Error when invalid control is dirty, touched, or submitted. */
// export class MyErrorStateMatcher implements ErrorStateMatcher {
//   isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
//     const isSubmitted = form && form.submitted;
//     return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
//   }
// }

