import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from "@angular/forms";
import { ArticleEditor } from "../class/articleEditor";
import { MatDialog } from '@angular/material/dialog';
import { EditorPopupComponent } from './editor-popup/editor-popup.component'
// import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import { Editor } from 'ngx-editor';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {
  quillConfiguration = ArticleEditor;

  // editor: Editor;
  html: '';

  ngOnInit(): void {
    // this.editor = new Editor();
  }

  // make sure to destory the editor
  ngOnDestroy(): void {
    // this.editor.destroy();
  }
  htmlContent = "";
  // Editor = ClassicEditor;
  optionsNews = {
    toolbar: {
      items: [
        'heading', '|',
        'fontfamily', 'fontsize', '|',
        'alignment', '|',
        'fontColor', 'fontBackgroundColor', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'subscript', 'superscript', '|',
        'link', '|',
        'outdent', 'indent', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'code', 'codeBlock', '|',
        'insertTable', '|',
        'uploadImage', 'blockQuote', '|',
        'undo', 'redo'
      ],
      shouldNotGroupWhenFull: true
    }
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '500px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',


    // uploadUrl: 'v1/image',
    // upload: (file: File) => { ... }
    uploadWithCredentials: true,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['fontName',
        'fontSize',
        'textColor',
        'backgroundColor',
      ]
    ]
  };

  constructor(public dialog: MatDialog) { }

  // ngOnInit() {
  // document.execCommand("styleWithCSS", false, null);
  // }

  // @Input() value: any;
  // @ViewChild('editor') editor: ElementRef;
  // @Output() valueChange = new EventEmitter();
  // img


  // ngOnChanges(changes: any) {
  //   try {
  //     if (this.editor.nativeElement.innerHTML != this.value) {
  //       this.editor.nativeElement.innerHTML = this.value;
  //     }
  //   } catch (err) {

  //   }
  // }
  // execCommandWithArg(a, b) {
  //   b = prompt('Enter the image URL', '');
  //   document.execCommand(a, false, b);
  // }
  // execCommandWithArgLink(a, b) {
  //   b = prompt('Enter a URL', 'http://');
  //   document.execCommand(a, false, b);

  // }
  // execComFormateh1(a, b) {
  //   document.execCommand(a, false, '<h1>');
  // }
  // execComFormateh2(a, b) {
  //   document.execCommand(a, false, '<h2>');
  // }
  // execCom(a, b = null) {
  //   document.execCommand(a, false, b);
  // };

  // onInput(newValue) {
  //   this.valueChange.emit(newValue);
  //   console.log(newValue)
  // }

  // //image upload 
  // // b = this.imgupload();
  // imgupload() {
  //   const dialogRef = this.dialog.open(EditorPopupComponent, {
  //     width: '360px',
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     this.img = result
  //     // console.log(result)
  //   });
  // }


}

