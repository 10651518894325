<app-sticky-shared></app-sticky-shared>

<!-- <div id="top" class="padding" [ngClass.lt-md]="'padding-top-sm'">
    <p class="font-georgia  mat-headline color-blue" fxLayoutAlign="center">Pay Invoice </p>
</div> -->


<div id="top" fxLayout="column" fxLayoutAlign="center center" class="padding">


    <div fxLayout="column" fxLayoutGap="10px">
        <!-- Payment Details -->
        <mat-card fxLayout="column" style="width:100%">
            <div class="padding" [ngClass.lt-md]="'padding-top-sm'">
                <p class="font-georgia  mat-headline color-blue" fxLayoutAlign="center">Pay Invoice </p>
            </div>
            <div fxFlex fxLayoutAlign="end">
                <form [formGroup]="formDetails">

                    <!-- Author Details -->
                    <div>
                        <!-- Name<span class="required"> *</span> -->
                        <div>
                            <p>Author's Details</p>
                        </div>
                        <div fxLayoutGap="10px">
                            <!-- First Name -->
                            <mat-form-field appearance="outline" fxFlex="20">
                                <mat-label>Corresponding Author Name</mat-label>
                                <input formControlName="authorname" matInput placeholder="First Name"
                                    [(ngModel)]="this.Name" required [disabled]="this.invoiceinfo.authorEmail">
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="20">
                                <mat-label>Corresponding Author Degree</mat-label>
                                <input formControlName="degree" matInput placeholder="First Name"
                                    [(ngModel)]="this.authorDegree" required>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex="60">
                                <mat-label>Corresponding Author Email</mat-label>
                                <input formControlName="email" matInput placeholder="First Name"
                                    [(ngModel)]="this.authorEmail" required>
                            </mat-form-field>
                    
                           
                        </div>

                        <div fxLayoutGap="10px">
                            <!-- Department -->
                            <mat-form-field appearance="outline" fxFlex="45">
                                <mat-label>Department</mat-label>
                                <input formControlName="Department" matInput placeholder="Department"
                                    [(ngModel)]="this.department" required>
                            </mat-form-field>
                           
                        </div>

                        <div fxLayoutGap="10px">

                            <!-- Institute -->
                            <mat-form-field appearance="outline" fxFlex="55">
                                <mat-label>Institute</mat-label>
                                <input formControlName="Institute" matInput placeholder="Institute"
                                    [(ngModel)]="this.institute" required>
                            </mat-form-field>

                            <!-- Author City -->
                            <mat-form-field appearance="outline" fxFlex="30">
                                <mat-label>City</mat-label>
                                <input formControlName="City_author" matInput placeholder="City"
                                    [(ngModel)]="this.city_author" required>
                            </mat-form-field>

                            <!-- Author State -->
                            <mat-form-field appearance="outline" fxFlex="30">
                                <mat-label>State</mat-label>
                                <input formControlName="State_author" matInput placeholder="State"
                                    [(ngModel)]="this.state_author" required>
                            </mat-form-field>

                        
                            <!-- Author Country -->
                            <mat-form-field appearance="outline" fxFlex="30">
                                <mat-label>Country</mat-label>
                                <input formControlName="Country_author" matInput placeholder="Country"
                                    [(ngModel)]="this.country_author" required>
                            </mat-form-field>
                           
                        </div>
                    </div>

                    <div>
                        <mat-divider></mat-divider>
                    </div>

                    <!-- Manuscript Details -->
                    <div class="padding-top">
                        <div>
                            <p>Manuscript's Details</p>
                        </div>
                        <div fxLayoutGap="10px" fxLayout="column">
                            <!-- Manuscript ID -->
                            <mat-form-field appearance="outline" fxFlex="20">
                                <mat-label>Manuscript Number</mat-label>
                                <input formControlName="manuscriptId" matInput placeholder="Manuscript Number"
                                    [(ngModel)]="this.manuscript" required>
                            </mat-form-field>

                            <!-- Manuscript Title -->
                            <mat-form-field appearance="outline" fxFlex="20">
                                <mat-label>Manuscript Title</mat-label>
                                <input formControlName="manuscriptTitle" matInput placeholder="Manuscript Title"
                                    [(ngModel)]="this.manuscripttitle" required>
                            </mat-form-field>

                            <!-- Date Published -->
                            <mat-form-field appearance="outline" >
                                <mat-label>Date Published
                                </mat-label>
                                <input matInput [matDatepicker]="picker1" (click)="picker1.open()"
                                    formControlName="ManuscriptpublishDate" [(ngModel)]="this.datePublish" required
                                    disabled>
                                <mat-datepicker-toggle matSuffix [for]="picker1" disabled> </mat-datepicker-toggle>
                                <mat-datepicker #picker1 disabled></mat-datepicker>
                                <mat-error>Date Published required</mat-error>
                            </mat-form-field>

                            <div fxLayoutGap="10px">
                            <!-- Volume -->
                            <mat-form-field appearance="outline" fxFlex="30">
                                <mat-label>Volume</mat-label>
                                <input formControlName="Volume" matInput placeholder="Volume"
                                    [(ngModel)]="this.volume" required>
                                <!-- <mat-hint>example@example.com</mat-hint> -->
                            </mat-form-field>

                                                        
                            <!-- Issue -->
                            <mat-form-field appearance="outline" fxFlex="30">
                                <mat-label>Issue</mat-label>
                                <input formControlName="Issue" matInput placeholder="Issue"
                                    [(ngModel)]="this.issue" required>
                                <!-- <mat-hint>example@example.com</mat-hint> -->
                            </mat-form-field>
                            </div>

                        </div>

                    </div>

                    <div>
                        <mat-divider></mat-divider>
                    </div>

                    <div class="padding-top">
                        <p>Billing Details</p>
                    </div>
                    <!-- Invoice Details -->
                    <div  fxLayout="column" fxLayoutGap="10px">
                    <!-- Invoice ID -->
                    <mat-form-field appearance="outline" fxFlex="20">
                        <mat-label>Invoice Number</mat-label>
                        <input formControlName="manuscriptId" matInput placeholder="Manuscript Number"
                            [(ngModel)]="this.manuscript" required>
                    </mat-form-field>                        
                        <!-- Date Invoiced -->
                        <mat-form-field appearance="outline" >
                            <mat-label>Date Invoice
                            </mat-label>
                            <input matInput [matDatepicker]="picker1" (click)="picker1.open()"
                                formControlName="dateInvoiced" [(ngModel)]="this.dateInvoiced" required
                                disabled>
                            <mat-datepicker-toggle matSuffix [for]="picker1" disabled> </mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled></mat-datepicker>
                            <mat-error>Date Published required</mat-error>
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width:250px;">
                            <mat-label>Amount</mat-label>
                            <input type="number" min="1" formControlName="amount" [(ngModel)]="this.amount" matInput
                                placeholder="1" required class="example-right-align">
                            <span matSuffix>.00 USD</span>
                        </mat-form-field>

                        <div fxLayout="row" fxLayoutGap="10px">
                            <mat-form-field appearance="outline">
                                <mat-label>Promo Code Amount</mat-label>
                                <input type="number" min="1" formControlName="promoCodeAmount" 
                                    [(ngModel)]="this.PromoCodeAmount" matInput
                                    placeholder="1" required class="example-right-align">
                                <span matSuffix>.00 USD</span>
                            </mat-form-field>

                            <mat-form-field appearance="outline">
                                <mat-label>Promo Code</mat-label>
                                <input formControlName="PromoCode" [(ngModel)]="this.promoCode" matInput
                                   required>
                            </mat-form-field>
                        </div>

                        
                        <mat-form-field appearance="outline" style="width:250px;">
                            <mat-label>Tax</mat-label>
                            <input type="number" min="1" formControlName="tax" [(ngModel)]="this.Tax" matInput
                                placeholder="1" required class="example-right-align">
                            <span matSuffix>.00 USD</span>
                            <mat-hint>3% Tax</mat-hint>
                        </mat-form-field>



                    </div>
                    <div>
                        <div>
                            <mat-form-field appearance="outline">
                                <mat-label>Total</mat-label>
                                <input type="number" min="1" formControlName="total" [(ngModel)]="this.totalPrice"
                                    matInput placeholder="1" required class="example-right-align">
                                <span matSuffix>.00 USD</span>
                            </mat-form-field>
                            <!-- USD -->
                        </div>
                    </div>

                    <mat-divider></mat-divider>

                    <div class="padding-top">
                        Credit/Debit Card Details
                        <div class="padding-top">
                            <!-- Full Name <span class="required"> *</span> -->
                            <div>
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Full Name</mat-label>
                                    <input formControlName="card_name" matInput placeholder="Enter Name" required>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutGap="20px">
                            <!-- Enter Card Holder's Department -->
                            <div fxFlex="50">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Enter Card Holder's Department</mat-label>
                                    <input formControlName="billing_department" matInput placeholder="Enter Card Holder's Department" >
                                </mat-form-field>
                            </div>
                            <div fxFlex="50">
                                <mat-form-field appearance="outline" class="example-full-width">
                                    <mat-label>Enter Card Holder's Institution</mat-label>
                                    <input formControlName="billing_Institution" matInput placeholder="Enter Card Holder's Institution" >
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="p-padding" fxLayout="row" fxLayoutGap="10px">
                            <!-- Address line  -->
                            <mat-form-field appearance="outline" class="example-full-width">
                                <mat-label>Address Line</mat-label>
                                <input formControlName="Street1" maxlength="256" #search matInput
                                    placeholder="1234 Main Street" [(ngModel)]="address1" required>
                                <mat-error>Address Line required</mat-error>
                            </mat-form-field>
                        </div>
                            <div class="p-padding" fxLayout="row" fxLayoutGap="10px">
                            <!-- City -->
                            <div>
                                <!-- City <span class="required"> *</span> -->
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>City</mat-label>
                                        <input matInput formControlName="City" type="text" id="City"
                                            placeholder="New Jersey" [(ngModel)]="locality" required>
                                        <mat-error>City required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <!-- State -->
                            <div>
                                <!-- State <span class="required"> *</span> -->
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>State</mat-label>
                                        <input matInput formControlName="State" type="text" id="State"
                                            placeholder="New York" [(ngModel)]="city" required>
                                        <mat-error>State required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>

                            <!-- Country -->
                            <div>
                                <!-- Country <span class="required"> *</span> -->
                                <div>
                                    <mat-form-field appearance="outline">
                                        <mat-label>Country</mat-label>
                                        <input matInput formControlName="Country" type="text" id="Country"
                                            placeholder="USA" [(ngModel)]="country" required>
                                        <mat-error>Country required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="column" fxLayoutGap="20px">
                            <div class="p-padding" fxLayout="row" fxLayoutGap="10px">

                                <!-- Credit Card Number -->
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <!-- <p>Card Number<span class="required"> *</span></p> -->
                                    <div id="sq-card-number"></div>
                                    <p id="error_card" class="error" style="display:none"></p>
                                </div>

                                <!-- Security Code -->
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <!-- <p>CVV<span class="required"> *</span></p> -->
                                    <div id="sq-cvv"></div>
                                    <p id="error_cvv" class="error" style="display:none"></p>
                                </div>
                            </div>
                            <div class="p-padding" fxLayout="row" fxLayoutGap="10px">

                                <!-- Expiration Date -->
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <!-- <p>Expiration Date<span class="required"> *</span></p> -->
                                    <div id="sq-expiration-date"></div>
                                    <p id="error_exp" class="error" style="display:none"></p>
                                </div>

                                <!-- Postal Code -->
                                <div fxLayout="column" fxLayoutGap="5px">
                                    <!-- <p>Postal Code<span class="required"> *</span></p> -->
                                    <div id="sq-postal-code"></div>
                                    <p id="error_postal" class="error" style="display:none"></p>
                                </div>
                            </div>
                            <div class="p-padding">

                            </div>


                            <input type="hidden" id="card-nonce" name="nonce">
                            <div fxLayout="column">
                                <p>I have read the description mentioned above and I agree to pay non-refundable the invoice
                                    <span class="required"> *</span>
                                </p>
                                <div fxLayout="row">
                                    <mat-form-field appearance="outline" fxFlex="50">
                                        <mat-select>
                                            <mat-option value="yes">Yes</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>


                    </div>

                    <div fxLayoutAlign="center center">
                        <button mat-raised-button color="primary" (click)="requestCardNonce($event)">SUBMIT</button>
                        <button class="display-none" id=outside>outside</button>
                    </div>

                </form>
            </div>

        </mat-card>
    </div>


</div>
<app-footer></app-footer>