export const CONSTANT = {
    noReplyMail: 'editor@annalsebm.org',
    s3path: 'https://aebm-files.s3.us-east-2.amazonaws.com',
    // accessKeyId: 'AKIA2X5IE4BRW2BIXTMI',
    accessKeyId: 'AKIA2X5IE4BR3DZTD3V4',
    // secretAccessKey: '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',
    secretAccessKey:'kbnrBpSUmJotLz71v+AyXOEKWgoH00FE3JeAo4Vv',
    region: 'us-east-2',
    Bucket: 'aebm-files',
    // Website Home Page
    websiteName: 'Annals of Evidence Based Medicine',
    issn: 'Open access (ISSN:)',
    volume: 'Volume:',
    issue: 'Issue:',
    frequency: 'Frequency:',
    frequency_content: '6 times per year',
    FEATUREDARTICLES: 'FEATURED ARTICLES',
    ArticleType: 'ARTICLE TYPE',
    blogUpdates: 'Blog & Updates',
    // ArticleList 
    BrowseArticles: 'Browse Articles',
    Articles: 'Articles',
    // About Journal
    aboutJournal: 'About Journal',
    aboutJournal1:'is an international, peer-reviewed, open-access, scientific journal of the',
    aboutJournal2:'There are no article processing charges (APC) payable by authors or institutions for the accepted articles. Articles are freely available immediately after publication, without paying a subscription charge.',
    openAccess1:'The authors retain copyright and articles are licensed under an open access Creative Commons Attribution-NonCommercial 4.0 International License ',
    openAccess2:'which permits unrestricted non-commercial use, distribution, and reproduction in any medium, provided the original work is properly cited.',
    commercialPurpose:' The authors should obtain permission from us and a fee may apply. In exceptional circumstances, articles may be licensed differently at the discretion of the publisher. For commercial distribution of reprints or e-prints by a commercial organization, non-profit organization, or educational institution please contact the AEBM at',
    ReproducingPublishedMaterial:'The authors must obtain permission to reproduce any published material (figures, tables or any extract of a text) which does not fall into the public domain or for which they do not hold the copyright. Permission should be requested by the authors from the Publisher in advance in order to avoid unnecessary delays in the publication process. Please cite the content and add',
    ReproducingPublishedBold:'“Reproduced with permission from [author], [book/journal title]; published by [publisher], [year].”',
    permission1:'(1)Your own works published by other Publishers and for which you did not retain copyright.',
    permission2:'(2) Other author’s work, use of tables, graphs, charts, photographs, pathology slides, or artworks if it is unmodified or slightly modified and for which you do not hold copyright.',    
    permissionNot1:'(1) Your own works published by other Publishers and for which you retain copyright. You must cite the source of the data in the form of either ',    
    permissionNot2:'(2) Other author’s work, graphs, charts, or artworks if it is completely modified, redrawn by the authors, and changed beyond recognition does not require permission.',    
    // author guidance 
    authorGuideLine1:'The authors must ensure that research submitted to AEBM is their own original work and is not under consideration or accepted for publication elsewhere.',
    publicationEthics: [
        'Declare to your chosen journal that your manuscript is not published elsewhere',
        'Declare any conflicts of interest',
        'Check all co-authors meet criteria for authorship and ensure appropriate acknowledgements made in the manuscript',
        'Include appropriate funding statements in the manuscript',
        'Show informed consent and provide assurances that participants rights are protected',
        'Explain how research using animals in conducted responsibly',
        'Be alert to bias and follow guidelines for accurate and complete reporting of research',
        'Inform the journal if you subsequently find errors in your research',
        'Sign a copyright letter of publication to authorise Medwell Publication'
    ],
    // Reviewer Guidance
    reviewer_starting:[
        'The editors and reviewers are required to evaluate manuscripts fairly and maintain confidentiality',
        'Reviewers provide comments for the editor and for the authors. The Journal expects reviewers to treat manuscripts as confidential communications and not to use the content for their own purposes or make copies of the manuscripts. Reviewers are also expected to declare to the editor any possible conflicts of interest'
    ],
    reviewer_starting2:[
        'Reviewers should assist in improving the quality of a submitted article by reviewing the manuscript with care, consideration and objectivity, in a timely manner. Reviewers should inform the journal editor of any published or submitted content that is similar to the materials under review, or any suspected plagiarism',
        'Reviewers should declare any potential conflicts of interest relating to a specific article or author. Reviewers should respect the confidentiality of any information or material supplied during the review process'
    ],
    // Editor Guidance
    editorial_office:'The in-house staff consists of Managing Editors, Assistant Editors, Production Editors, English Editors, Copyeditors, Data Specialists, Software Engineers and Administrative Specialists. Our collaborating editors on our Editorial Board are typically employed in academic institutions or corporate research facilities located all over the world. The contact with the in-house editorial staff is mainly by e-mail and telephone. Assistant Editors process manuscripts through the peer-review and production procedures; Managing Editors have the editorial responsibility for the journals; Production Editors, English Editors, Copy editors and Data Specialists are responsible for putting accepted content into a publishable format (full-text PDF, XML and HTML versions).',
    editorial_process_para1:"Articles submitted to Medwell Publications are subject to strict peer-reviewing. Medwell Publications operates double blind peer-review (the reviewers do not know the authors' identities until the paper has been published). The online submission system of Medwell Publications; Registration, incorporates online tools for manuscript submission, peer-reviewing and editorial decision making.",
    editorial_process_para2:"Once a manuscript is submitted, the submission is received by the in-house Managing Editor who will subsequently coordinate the whole editorial process for the manuscript: peer-review, decision-making, possible authors' revision, manuscript acceptance, copy editing, English editing, proofreading and final publication. An in-house Academic Editor will be assigned to the submitted article and will send review invitations.",
    editorial_process_para3:"At least two reports per manuscript are collected for each manuscript—three if the first two differ substantially. Reviewers must hold a PhD, have not published with the authors in the previous five years, and have recent publications in the field of the submitted manuscript.",
    editorial_process_para4:"At least two reports per manuscript are collected for each manuscript—three if the first two differ substantially. Reviewers must hold a PhD, have not published with the authors in the previous five years, and have recent publications in the field of the submitted manuscript.",
    editorial_process_para5:"The Editor-in-Chief, Guest Editor, or a suitable editorial board member can make the final acceptance or rejection decision for a manuscript, usually after author revision. We typically allow no more than two rounds of major revisions.After acceptance of an article for publication, the in-house editorial staff will organize the production of the paper, which entails copy editing, English editing and final production in preparation for publication on the journal website. Medwell Publications is structured in yearly volumes and either monthly or quarterly issues. Nevertheless, articles are published online immediately after acceptance and production.",
    // Editor in chief responsibility
    editor_in_chief_para1:"The Editor-in-Chief is the head of the journal, and is mainly responsible for the scientific quality of the journal. Medwell Publications does not need the Editor-in-Chief to be actively involved in the editorial process, i.e. the editorial team will take care of the majority of contact with authors and reviewers.",
    editor_in_chief_para2:"The Editor-in-Chief is responsible for assisting the Editorial Office in the management of the journal, which entails:",
    editor_in_chief_points:[
        "Scientific decisions about the journal's scope,",
        "Inviting distinguished scientists to join the editorial board,",
        "Suggesting topics for special issues,",
        "Assisting the Guest Editors in the setup of special issues,",
        "Overviewing the editorial process for individual manuscripts (mainly by taking the final decision whether a paper can be published after peer-review and revisions)."
    ],
    editor_in_chief_para3:"The Editor-in-Chief position is honorary. As a reward for the efforts, the Editor-in-Chief can publish his/her papers free of charge. The initial term of the Editor-in-Chief position is three years and can be renewed.",
    // Editorial Board Member Responsibilities:
    Editorial_BM_para:"An Editorial Board member will be asked to review one or two manuscripts per year and may help to edit a special issue on a topic related to his or her research interests. Additionally, the Editorial Board members will be approached for input or feedback regarding new regulations relating to the journal from time to time. Editorial Board members are also encouraged to help to promote the journal among their peers or at conferences. The communication with Editorial Board members is done primarily by E-mail. The initial term for an Editorial Board membership is two years and can be renewed. An Editorial Board member may also step down from the position at any time if he or she feels overloaded by the requests from the journal's Editorial Office.",
    // Guest Editor
    guest_editor_para1:"We appreciate collaborations with scholars all over the world. Special issues are normally edited by a Guest Editor who invites colleagues from the same research field to contribute an article on a topic within their expertise. The Guest Editor works together with the Editorial Office to prepare a description and keywords for the special issue web page. We aim for at least ten articles published per special issue. Often, the Guest Editor will also write an editorial paper for the special issue. The Guest Editor usually makes decisions on the acceptance of manuscripts submitted to his or her special issue (depending on the journal's policy — in some cases they may make a recommendation to the Editor-in-Chief). Papers belonging to a special issue are published online in the journal immediately after acceptance and collected together on the special issue webpage. This means that there is no delay for authors who submit their work: it will appear shortly after acceptance, even if other papers in the special issue are still being processed.",
    guest_editor_para2:"Guest Editors should not hold conflicts of interest with authors whose work they are assessing, Ex. if they are from the same institution or collaborate closely. In this case the Editor-in-Chief or a suitable editorial board member will make final acceptance decisions for submitted papers.",
    plagarisum:'Manuscript submissions are check with anti-plagiarism software for exact of near-exact matches in the public domain to satisfy the editor that the submitted manuscript has not been plagiarised. However, when authors submit their manuscripts has not been plagiarised. However, when authors submit their manuscripts for consideration in the journal, they declare that their work is not plagiarised. While the editor makes reasonable efforts to determine the academic integrity of papers published in the journal, ultimate responsibility for the originality of submitted manuscripts thus lies with the author. Plagiarism takes place when one authors deliberately uses another',
    plagarisum2:'work without permission, credit, or acknowledgement. Authors must always remember that crediting the work of others (including your advisor',
    plagarisum3:'or your own previous work) is paramount. Authors should always place their work in the context of the advancement of the field and acknowledge the findings of other on which you have built your research About the publishing ethics guidelines if you need more in-depth or specific information on ethics, the publishing ethics guidelines has everything you need',
    // Aim and Scope
    aim: 'Aims',
    scope: 'Scope',
    researchUpdate: 'The Annals of Evidence Based Medicine',
    issnaim: '',
    aimpara1: 'is an international, peer-reviewed, open access journal on clinical and pre-clinical research. It publishes article types including Research Papers, Reviews, Editorials, Communications, etc. Our aim is to encourage scientists to publish their experimental and theoretical results in as much detail as possible. The full experimental details must be provided so that the results can be reproduced.',
    aimpara2: 'There is, in addition, a unique feature of this journal: it accepts studies showing meaningful but negative results. While there are many journals that focus on clinical studies, only a few of them actively accept negative results. As a result, most negative data end up not being in the public domain even if the data were meaningfully negative and the study well designed. By accepting those negative results, our journal encourages scientists to share those data so that they would not need to repeat the experiments that somebody else has already carried out.',
    scopeSubHeader: 'This journal covers all topics related to clinical practices. Topics of interest include (but are not limited to):',
    scopeListing: [
        'Cardiology', 'Pulmonology', 'Gastroenterology & Hepatopancreatobiliary Medicine',
        'Hematology', 'Oncology', 'Endocrinology, Diabetes and Metabolism', 'Infectious diseases',
        'Nephrology & Urology', 'Immunology', 'Dermatology', 'Psychiatry', 'Clinical Neurology',
        'Epidemiology & Public Health', 'Surgery', 'Pediatrics', 'Obstetrics & Gynecology',
        'Ophthalmology', 'Otolaryngology'
    ],
    publicationEthicsAJ:'Authors should declare that all work in their submitted piece is original and cited content from other sources appropriately to avoid plagiarism Authors must ensure their contribution does not contain any libellous matter or infringe any copyright or other intellectual property rights or any other rights of any third party The listening of authors should accurately reflect who carried out the research and wrote the article and the order authorship should be jointly determined by all of the co-authors.All authors should be aware of the submission of their paper to the journal and agree to the main authors signing an IPR form on their behalf Authors should ensure that their manuscript as submitted is not under consideration (or accepted for publication) elsewhere. Where sections of the manuscript overlap with published or submitted content, their should be acknowledged and citedAuthors should obtain permission to reproduce any content from third party sources (text and images), Unfortunately, the press is unable to publish third party content for which permission has not been obtained (excluding content covered by fair dealing).The source of funding for a research project should be listed on all funded research papers. Other sources of support including funding of open access article processing charges should also be clearly identified in the manuscript, typically in an acknowledgement Authors should declare any potential conflicts of interest relating to a specific article Authors should inform the editor or publisher if there is a significant error in their published piece and work with the editor to publish an erratum addendum or retraction where necessary. Authors have the right to appear editorial decisions',
    // Abstracting/indexing
    Abstracting_Indexing: 'Abstracting/Indexing',
    Abstracting_Indexing_sm: 'Abstracting/ Indexing',
    subHeaderAbs: 'Journal will be indexed with the following databases and archives. All articles will get a Digital Object Identifier (DOI).',
    indexingName: [
        'Google Scholar', 'CrossRef', 'OAI', 'LOCKS', 'CLOCKSS',
        'WorldCat', 'DOAJ', 'EBSCO', 'Embase', 'J-Gate', 'ProQuest',
        'Scopus', 'Web of Science'

    ],
    nlmName: [
        'PubMed', 'MEDLINE', 'PMC'
    ],
    // Editorial Board
    editorial_board: 'Editorial Board',
    Editor_in_Chief: 'Editor-in-Chief',
    section_editor: 'Section Editors',
    managing_editor: 'Managing Editors',
    reviewer_list: 'Reviewers’ List',
    subLine_editor1: 'Editorial Board members and Reviewers have agreed and adhere to the ',
    subLine_editor2: 'AEBM Editor Roles & Responsibilities guidelines and Competing interests.',
    subLine_editor3: ' ',
    website: 'Website',
    GoogleScholar: 'GoogleScholar',
    speciality: 'Specialties',
    Interests: 'Interests',
    urvish_patel: 'Urvish Patel, MD, MPH',
    urvish_section: 'Section Editor (Internal Medicine & Neurology)',
    urvish_department: 'Department of Public Health and Neurology',
    urvish_college: 'Icahn School of Medicine at Mount Sinai, New York, NY, USA',
    urvish_Specialties: ' Neurology, Internal Medicine, Neurology, Epidemiology & Biostatistics, Global Burden of Disease, Translational Medicine, Clinical Research Education',
    urvish_Interests: 'Stroke, epilepsy, cardiovascular disease',
    // Add Editorial Board
    checkbox1: 'We respect your privacy, please check this box if you wish to receive news, notification, and updates about latest research and our services.',
    checkbox2_1: "I acknowledge that my personal information will be accessed and used in accordance with our",
    checkbox2_2: "to have better user experience. We will not share your data with third party for marketing or other benefits.",
    teamList: [ 'Editor-in-Chief', 'Section Editors', 'Associate Editors', 'International Associate Editors','Statistical Editors',
    'Editorial Board Members','Early Career Editorial Board Members', 'Managing Editors','Publishing Team' ],
    websiteList: [ 'Institutional Link', 'Google Scholar', 'ORCID Link', 'PubMed', 'ResearchGate' ],
    // submission Guidelines 
    submissionHeader: 'Submission Guidelines',
    // privacy policy
    privacyPolicy: 'Privacy Policy of the Annals of Evidence Based Medicine',
    privacySubLine: 'The Annals of Evidence Based Medicine operates the www.annalsebm.org website, which provides the SERVICE.',
    privacyPara1: 'This page is used to inform website visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the Annals of Evidence Based Medicine website.',
    privacyPolicyTemplate: ' Privacy Policy Template',
    privacyPolicyGenerator: 'Privacy Policy Generator.',
    privacyPara2: 'If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy. Our Privacy Policy was created with the help of the',
    privacyPara3: 'The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is accessible at www.annalsebm.org, unless otherwise defined in this Privacy Policy.',
    privacySubHeader: 'Information Collection and Use',
    informationCollectionContent: 'For a better experience while using our Service, we may require you to provide us with certain personally identifiable information, including but not limited to your name, phone number, and postal address. The information that we collect will be used to contact or identify you.',
    logData: 'Log Data',
    logDataContent: 'We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol (“IP”) address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.',
    cookies: 'Cookies',
    cookiesPara1: 'Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer’s hard drive.',
    cookiesPara2: 'Our website uses these “cookies” to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.',
    cookiesPara3: 'For more general information on cookies, please read',
    serviceProvider: 'Service Providers',
    serviceProviderPara1: 'We may employ third-party companies and individuals due to the following reasons:',
    serviceProviderPara2: 'We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.',
    security: 'Security',
    securityPara: 'We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.',
    linktoOther: 'Links to Other Sites',
    linkPara1: 'Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.',
    linkPara2: 'Children’s Privacy',
    linkPara3: 'Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.',
    changePrivacyPolicy: 'Changes to This Privacy Policy',
    changePrivacyPolicyPara: 'We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.',
    contactUs: 'Contact Us',
    contactUssubLine: 'If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.',
    // Donation
    donationTitle: 'Support Our Mission',
    donationPara1: 'Research Update Organization [IRS 501(c)(3) registered-tax-exempt] is a non-profit educational organization, in the United States, and all gifts to us are tax deductible in the United States to the fullest extent allowed by law.',
    donationPara2: 'We appreciate your generous gift to our organization. Through your donation, we will be able to accomplish our mission and continue working towards supporting the work of medical students and physicians-in-training. Your donation is going toward clinical research education and health promotion. You can truly make a difference for us, and we are extremely grateful. If you have specific questions about how your gift will be used or our organization as whole, please don’t hesitate to contact our team through phone or email.',
    principles: 'Founding Principles',
    principleContent: [ 'Clinical Research Education & Training',
        'Clinical Research Consultation & Support',
        ' Utilization of Clinical Research for Evidence-based Public Health Awareness' ],
    missionPara: 'To advance the knowledge of medicine and clinical research to promote the best possible care for patients with adult and childhood-onset medical disorders by:',
    missionPoints: [ 'Making current and latest medical research available free of cost to the community, students, and physicians',
        ' Encouraging students, residents, and fellows to embrace and promote the research and new innovations in the field of medicine',
        'Providing educational research training to medical students, residents, and physicians',
        'Organizing health expo, health awareness meetings, and lectures with the collaboration of the local authority and physicians to promote health and value of research in health promotion',
        'Make the community aware of the latest medical discoveries and implementation of them in their life for better community health',
        ' Working in liaison with other medical professional organizations in the USA and abroad, when its necessary, for full-filling the above-mentioned purposes' ],
    paymentPara1: 'Research Update Organization [IRS 501(c)(3) registered-tax-exempt], not-for-profit organization, has not provided any goods or services, in whole or in part, to you in consideration for this voluntary cash contribution; therefore, the full amount of your gift is tax-deductible. If you wish to claim the tax deductibility of this gift in the United States, please retain this acknowledgment letter for your files. Research Update Organization',
    EinNumber: 'EIN number is 83-3619272.',
    paymentPara2: 'We appreciate your generous gift to our organization. Through your donation, we will be able to accomplish our mission and continue working towards supporting the work of medical students and physicians-in-training. Your donation is going toward clinical research education and health promotion. If you have specific questions about how your gift will be used or our organization as whole, please don’t hesitate to contact our team through phone or email.',
    urvishPost: 'Co-founder, Trustee, & Chief Education Officer',
    urvishOrganization: 'Research Update Organization',
    urvishWebsite: ' www.researchupdate.org',
    urvishPhone: '+1 (646) 685-4341',
    urvishEmail: 'urvish.patel@researchupdate.org',
    // Paper Submission 
    save: 'Save',
    saveandcontinue: 'Save and Continue',
    back: 'Back',
    getstarted: 'Get Started',
    cancel: 'Cancel',
    preview: 'PREVIEW',
    submit: 'SUBMIT',
    options: [ 'Yes', 'No' ],
    emailPattern: '^([a-zA-Z0-9_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$',
    passwordPattern: '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[a-zA-Z0-9\d$@$!%*?&].{7,}',
    documentTypeInfo: [
        { value: 'pdf', viewValue: 'application/pdf' },
        { value: 'png', viewValue: 'image/png' },
        { value: 'jpeg', viewValue: 'image/jpeg' },
        { value: 'jpg', viewValue: 'image/jpg' },
        { value: 'msword', viewValue: 'application/msword' },
        { value: 'excel', viewValue: 'application/vnd.ms-excel' },
    ],

    startedimportant: 'Please read the ',
    startedimportant2: 'and Instructions carefully before moving forward',
    startedque1: 'Have you read our Author Instructions?',
    startedans1: 'Please ensure you have read these instructions before starting your submission! We expect all submissions to studiously adhere to our formatting requirements. Articles with too many errors will either be rejected or require the purchase of our Preferred Editing service.',
    startedque2: 'Do not write your article on the Cure websit!',
    startedans2: 'Please write and format your article offline before starting your submission.',
    startedque3: 'Know your responsibilities as the submitting author:',
    startedans3_1: `The submitting author is the only account with edit access and if the article requirements our Preferred Editing service, payment must br made via the submitting author's account.`,
    startedans3_2: 'The submitting author is responsible for editing all author names and affiliations.',
    startedans3_3: `If you are submitting on behalf of an author you must sign in with the author's accountbefore continuing.`,
    startedque4: 'We no longer accept the following article submissions:',
    startedans4_1: 'COVID-19 editorials and review articles.',
    startedans4_2: 'Dentistry articles(all article types).',
    startedans4_3: 'Medical student or resident burnout articles(all types).',
    startedans4_4: 'Survey-based methodology articles concerning general knowledge,awareness or attitudes without a clear scientific impact.',
    startedpublish: 'Thanks for choosing to publish with AEBM!',

    articletypeheading: 'What type of article are you submitting?',
    articletypepara1: `From original research to case reports.we've got you covered. we'll provide you with the appropriate templates based on the selected article type.`,
    articletypepara2_1: 'Make sure you select the correct article type!',
    articletypepara2_2: 'Articles submitted as the incorrect type will require the purchase of our Preferred Editing room_service after resubmission as the correct article type.',
    articletypepara2_3: 'Which article type should I select?',
    articletypepublish: 'Submitting to a competition? Your article type selection may be limited due to competition rules.',

    categoriesheading: 'You can select up to five categories.',

    titleheading: `What's the title of your article?`,
    titlepara1_1: 'The study title should be entered in the Title case. (Ex. Association of Sugary Beverage Consumption With Mortality Risk in US Adults.) ',
    titlepara1_2: 'Failure to do so will hurt your chances of receiving free publication. Title should be limited to one sentence only.Do not include a period at the end of the title.',

    heighlightsheading: 'Add one to two point for each',
    heighlightseditor1: 'Prior Knowledge about this subject',
    heighlightseditor2: 'Finding of this study',
    heighlightseditor3: 'Impact of this study in clinical practice',

    abstractheading: `It's time to add the abstract.`,
    abstracteditorinfo1: 'Copy and paste your abstract below. Only,original article may include ',
    abstracteditorinfo2: 'The abstract is limited to 300 words.',

    keywordheading: `Let's add some keywords.`,
    keywordpara1_1: `You can select up to ten keywords.`,
    keywordpara1_2: 'Show me examples.',
    keywordpara2: 'Additional keywords may be added to your article by our editors.',
    keywordmaximum: 'You can maximum add 10 keywords.',

    authorheading: 'Please list your co-author',
    authorsubheading: 'Original articles are limited to 10 authors.',
    authorparahead1: 'You are responsible for ensuring that all author name and affiliations are accurate and consistently formatted.',
    authorpara1: 'Your co -author cannot edit this information. Affiliations from the same institution must be identically formate! Failure to do so will hurt your chances of receiving free publication. If two or more authors are from the same hospital or academic institution, the hospital or institution name entered in this field must be identical for those authors. If these authors are also from the same department, then the information in the department field must be  identical. This includes spelling, capitalization, spacing, etc.',
    authorparahead2: 'Changing the corresponding author, order of author, or author email addresses',
    authorpara2: 'You can revise incorrect or out-of-date email addresses via arrow button next to each co-author. Click the empty circle to the left of an author name to designate them as the author. (this designation is activated upon publication).To recorder list, just click on the left column and drag the author to th desire position.',
    authorparahead3: 'Medical student authors must include a senior author.',
    authorpara3: 'Enter senior author has completed their training. (For example, a chef resident, faculty member or private practice physician). Submission authored by medical students without a senior author will be rejected. To react',
    authorparahead4: 'You can add up 10 authors for this article. Authors cannot be added after submit your article.',
    authorpara4: 'Enter eac co-author email address below. You will not be able to change co-author email address after submitting so please ensure that there are no typos.',
    authoremailenter: 'Enter author email address',
    authorlistinfo: 'Author List Instructions:',
    authorarrangmsg: 'Choose the corresponding author and drag the rows to set the author order.',
    authorcontributions: 'Author contributions: CRediT (Contributor Roles Taxonomy)',
    authortooltipmsg: `You may add same authors' name at more than one role. Please, copy and paste the name next to their contributions.`,
    conceptualizationtooltipmsg: 'Ideas; formulation or evolution of overarching research goals and aims.',
    methodologytooltipmsg: 'Development or design of methodology; creation of models.',
    softwaretooltipmsg: 'Programming, software development; designing computer programs; implementation of the computer code and supporting algorithms; testing of existing code components.',
    validationtooltipmsg: 'Verification, whether as a part of the activity or separate, of the overall replication/reproducibility of results/experiments and other research outputs.',
    formalanalysistooltipmsg: 'Application of statistical, mathematical, computational, or other formal techniques to analyze or synthesize study data.',
    investigationtooltipmsg: 'Conducting a research and investigation process, specifically performing the experiments, or data/evidence collection.',
    resourcestooltipmsg: 'Provision of study materials, reagents, materials, patients, laboratory samples, animals, instrumentation, computing resources, or other analysis tools.',
    datacurationtooltipmsg: 'Management activities to annotate (produce metadata), scrub data and maintain research data (including software code, where it is necessary for interpreting the data itself) for initial use and later reuse.',
    draftpreparationtooltipmsg: 'Creation and/or presentation of the published work, specifically writing the initial draft (including substantive translation).',
    revieweditingtooltipmsg: 'Preparation, creation and/or presentation of the published work by those from the original research group, specifically critical review, commentary or revision – including pre- or post-publication stages.',
    visualizationtooltipmsg: 'Preparation, creation and/or presentation of the published work, specifically visualization/data presentation.',
    supervisiontooltipmsg: 'Oversight and leadership responsibility for the research activity planning and execution, including mentorship external to the core team.',
    projectadministrationtooltipmsg: 'Management and coordination responsibility for the research activity planning and execution.',
    fundingacquisitiontooltipmsg: 'Acquisition of the financial support for the project leading to this publication.',

    articleheading: '[Introduction, Methods, Results, Discussion, and Conclusion]',
    articlepara1: 'We suggest completing and preparing your article before inserting it here. While our publishing tool allows for writing and editing directly in the browser, it may not offer the same versatility as more advanced word processors. If you anticipate making substantial alterations or edits during the publication phase, we recommend doing so in your original document first, and subsequently pasting the revised content here.',
    articleparaimpo: 'Tables and figures can be added below the appropriate paragraph with in-text citation (Figure 1). Add the "Title" of the table or figure on top and "Legend (Description)" on bottom.',
    articlepara2: 'Tables and figures can be added below the appropriate paragraph with in-text citation (Figure 1). Add the "Title" of the table or figure on top and "Legend (Description)" on bottom.',
    articlepara3: ' Copy and paste your article with references in AMA format (Hyperlink). We recommend of using the citation tool (Mendeley, Zotero, or EndNote) on your original word document before copy and paste content here.',
    articlepara4: 'Write appropriate Headings [Introduction, Methods, Results, Discussion, and Conclusion] using "Heading 1" formatting.',
    articlepara5: 'Use "Heading 2" formatting for naming the sub-sections and content should be written in "Paragraph" formatting.',
    articlecommon: 'Common questions:',
    articlecommonque1: 'How to format my citations?',
    articlecommonque2: 'How about subheaders?',

    referenceheading: `Let's take care of your references.`,
    referenceline1: 'Original articles are limited to 30 references.',
    referenceline2: 'Check your references before moving forward!',
    referenceline3: 'it is your responsibility to review each converted reference for accuracy.',
    referenceerror: 'Most common reference errors:',
    referencerrorline1: 'Author names not listed in following format: "Smith D" or "SMith DH"',
    referencerrorline2: 'Article titles not listed in sentence case',
    referencerrorline3: 'Journal titles not correctly abbreviated',
    referencerrorline4: 'Issue included',
    referencerrorline5: 'Missing volume or page number',
    referencerrorline6: 'DOI missing or incorrectly entered (link must work)',

    supplementaryheading: 'Supplementary Material',
    supplementarypara1: 'Only supplemental materials such as interview questions, participant forms, surveys, questionnaires, etc. should be added as appendices.',
    supplementarypara2: 'All figure, tables or videos mentioned in your article must be inserted in the corresponding article section. Failure to do so will hurt your chances of receiving free publication.',
    supplementaryque: 'Do you want to include any supplemental information relevant to your work?',
    supplementarypublication: 'Supplementary Material (For Publication Use)',
    supplementarynotpublication: 'Supplementary Material (For Reference "Not For Publication")',

    acknowledgenentheading: 'Do you have any acknowledgements to add?',
    acknowledgenentpara1: `Any acknowledgements you enter will e displayed article's Author and Article info tab. Also, if you wish to designate multiple first authors please do so here.`,
    acknowledgenentpara2: 'Authors’ Contribution: Conceived of the idea. Reviewed the literature and collected the data. Performed bio-statistical analysis. Formulated the tables. Wrote the main draft of the manuscript. Supervised the project.',

    ethicsheading: 'Ethics Statements',
    ethicshumanque: 'Did you study human participants or human tissue?',
    ethicshumanimpo: `You must select 'Yes' for all patient-related submission, including retrospective studies, case reports and studies where IRB approval was waived.`,
    ethicshumanfield1: 'Institutional Review Board (IRB) or Ethics committee (provide full name)',
    ethicshumanfield2: 'Approval number (if applicable)',
    ethicshumanfield3: 'Statement indicating approval of research',
    ethicsanumalque: 'Did you study animal participants or human tissue?',
    ethicsanimalfield1: 'Protocol Number or NH guidelines statement',
    ethicsanimalfield2: 'Approval number (if applicable)',
    ethicsanimalfield3: 'Name of Local Animal Ethics Committee',

    coiheading: 'Conflict of Interest Disclosures',
    coiresponsibility: 'COI Responsibility',
    coiresponsibilitypara1_1: 'Cureus adheres to',
    coiresponsibilitypara1_2: `ICMJE's Form Disclosure of Potential Conflict of Interest.`,
    coiresponsibilitypara1_3: 'Please provide all relevant information. A statement based on your response will be generated and included in the published article. You may choose to',
    coiresponsibilitypara1_4: 'download a copy of the form',
    coiresponsibilitypara1_5: 'to share with your co-authors, but as the submitting and corresponding author, you alone will be responsible for including all co-author COI and disclosure statements.',
    coiresponsibilityterms: 'I understand that I alone am responsible for including all co-author COI and disclosure statements.',
    coipayment: 'Payment',
    coipaymentpara1: 'The Works Under Consideration for Publication',
    coipaymentpara2: 'Did you or your institution at any time receive payment or services from third party (government, commercial, private foundation, etc.) for any aspect of the submitted work (such as grants, data monitoring board, study design, manuscript preparation, statistical analysis, etc.)? If so, please provide details.',
    coipaymenthelp: 'Help me contact my co-authors to obtain any potential conflicts of interest.',
    coifinancial: 'Financial Activity',
    coifinancialpara1: 'Relevant Financial Activities Outside the Submitted Work',
    coifinancialpara2: 'Please indicate all financial relationship (regardless of compensation amount) associated with the submitting author and all co-authors. You should disclose interactions with any entity that could be considered broadly relevant to your work. Report all relationship that were present during the 36 months prior to publication. Remember - if there is any question, it is better to disclose a relationship than not to do so.',
    coifinancialpara3: 'For grants you have received for work outside the submitted work, only disclose support from entities that could be perceived to be affected financially by the published work, such as drug companies, or foundation supported by entities that could be perceived to have a financial stake in the outcome.',
    coifinancialpara4: 'Do you or your co-authors have any relevant financial activities to disclose?',
    coiother: 'Other Relationship',
    coiotherpara1: 'Relationships Not Covered Previously',
    coiotherpara2: 'Are there other relationships or activities that readers could perceive to have influenced, or that give the appearance of potentially influencing, what you wrote in the submitted work? If so, please provide details in the box below.',

    submitheading: 'Please, review your paper before submiting',

    addauthorheading: `Author's Details`,
    firstnamelabel: 'First Name',
    firstnamerequired: 'First Name required',
    lastnamelabel: 'Last Name',
    lastnamerequired: 'Last Name required',
    degreelabel: 'Degree',
    degreehint: 'Ex. : MD, MPH',
    degreerequired: 'Degree required',
    emaillabel: 'Email',
    emailrequired: 'Email required',
    institutelabel: 'Affiliation/Academic Institution',
    instituterequired: 'Affiliation/Academic Institution required',
    departmentlabel: 'Department',
    departmenthint: 'Hint: Ex. : write in full - Department of Internal Medicine and Neurology (Do not write division)',
    departmentrequired: 'Department required',
    citylabel: 'City',
    cityrequired: 'City required',
    statelabel: 'State',
    staterequired: 'State required',
    countrylabel: 'Country',
    countryrequired: 'Country required',

    // Artcle preview:
    Volume: 'Volume',
    Issue: 'Issue',
    Views: 'Views:',
    Date: 'Date:',
    authorAffiliations: 'Author Affiliations',
    CorrespondingAuthor: 'Corresponding Author',
    Citeas: 'Article Information/Citation',

    Highlights: 'HIGHLIGHTS',
    Highlight1: 'Prior Knowledge about this subject',
    Highlight2: 'Finding of this study',
    Highlight3: 'Impact of this study in clinical practice',

    Abstract: 'ABSTRACT',
    keywords: 'Keywords:',
    article: 'ARTICLE',
    references: 'REFERENCES',
    supllementaryMaterial: 'SUPPLEMENTARY MATERIAL',
    Acknowledgement: 'ACKNOWLEDGEMENT',
    none: 'None',
    coi: 'COI DISCLOSURE',
    ethicsStatement: 'ETHICS STATEMENT',
    humanSubject: 'Human Subject',
    protocolNumber: 'Protocol Number',
    approvalNumber: 'Protocol Number',
    statement: 'Statement',
    animalSUbject: 'Animal Subject',
    notApplicable: 'Not Applicable',
    loclAnimalComitee: 'Local Animal Committee',
    ArticleInformation: ' ARTICLE INFORMATION/CITATION',
    ArticleInformationLeft: ' Article Information/Citation',
    publicationHistory: 'Publication History',
    peerReviewBegan: 'Peer review began:',
    peerReviewConducted: 'Peer review concluded:',
    published: 'Published:',
    Copyright: 'Copyright',
    coileftSide: 'COI Disclosure',
    comments: 'comments',
    similarArticles: 'similar articles',
    trendingArticles: 'Trending Articles',
    Versions: 'Versions',
    Documents: 'Documents',
    date: 'Date',

};