import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Invoice } from '../../model/invoice';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  _apiEndPoint: string = '';

  constructor(private _http: HttpClient,) {
    this._apiEndPoint = environment.Url;
  }

  //Get Managing Editor Invoice list api
  getManagingEditInvoiceList<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`,)
  }

  //Get Author Invoice list api
  getAuthorInvoiceList<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`,)
  }

  //Get Invoice Transcation Data api
  getInvoiceData<T>(apiURL: string): Observable<T[]> {
    return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`,)
  }

  //Update Invoice 
  UpdateInvoice(ArticleTransactionId: string, DateofInvoice: string, Amount: string, Status: string, UploadReceipt: string) {
    return this._http.post<Invoice>(`${environment.apiUrl}/api/article/UpdateInvoiceDate`, {
      ArticleTransactionId,
      DateofInvoice,
      Amount,
      Status,
      UploadReceipt

    })
      .pipe(map(res => {
        return res;
      }));
  }

  //reminder send for invoice 
  Sendreminder(ArticleTransactionId: string, To: string, Subject: string, content: string) {
    return this._http.post<Invoice>(`${environment.apiUrl}/api/Article/RemindForInvoice`, {
      ArticleTransactionId,
      To,
      Subject,
      content
    })
      .pipe(map(res => {
        return res;
      }));
  }

    //Get ManuscriptWiseArticleTransactionId Data api
    getArticleTransactionId<T>(apiURL: string): Observable<T[]> {
      return this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`,)
    }

    // Send Invoice
    sendInvoice(articleTransactionId: string, from: string, to: string, cc: string,bcc: string, ) {
      return this._http.post<any>(`${environment.apiUrl}/api/Article/SendInvoiceToThirdParty`, {
        articleTransactionId,
        from,
        to,
        cc,
        bcc,
      })
        .pipe(map(res => {
          return res;
        }));
    }
}
