import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { GetDataById } from '../../model/GetDataById';
import { RestmanagerService } from '../../utils/restmanagerService';


@Injectable({
  providedIn: 'root'
})
export class GetElementByIDService {
  _apiEndPoint: string = '';
  private restmanagerService: RestmanagerService;

  constructor(restmanagerService: RestmanagerService, private _http: HttpClient) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;

  }

  getDataByid<T>(apiURL: string): Observable<T[]> {

    return this.mapAndCatchError(
      this._http.get<T[]>(`${this._apiEndPoint}/${apiURL}`)
    );
  }

  private mapAndCatchError<TData>(response: Observable<TData>): Observable<TData> {

    return response.pipe(
      map((r: TData) => {
        return r;
      }),
      catchError((error: HttpErrorResponse) => {
        return of(null);
      })
    );
  }

}
