import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ViewcountService {
  _apiEndPoint: string = '';
  constructor(private _http: HttpClient) {
    this._apiEndPoint = environment.Url;
  }
  Addcountarticle() {
    var articleId = localStorage.getItem('articleId')
    return this._http.post<any>(`${environment.apiUrl}/api/Article/addviewtoarticle?id=${articleId}`, {


    })
      .pipe(map(res => {
        return res;
      }));
  }

  AddcountBlog() {
    var blogId = localStorage.getItem('blogId')
    return this._http.post<any>(`${environment.apiUrl}/api/blog/AddViews?blogId=${blogId}`, {


    })
      .pipe(map(res => {
        return res;
      }));
  }
  AddcountDownloadarticle() {
    var articleId = localStorage.getItem('articleId')
    return this._http.post<any>(`${environment.apiUrl}/api/article/adddownloadtoarticle?id=${articleId}`, {


    })
      .pipe(map(res => {
        return res;
      }));
  }
}
