import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { RestmanagerService } from '../../utils/restmanagerService';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadevalutionService {
  _apiEndPoint: string = '';
  private restmanagerService: RestmanagerService;
  constructor(private _http: HttpClient, restmanagerService: RestmanagerService) {
    this.restmanagerService = restmanagerService;
    this._apiEndPoint = environment.Url;
  }



  AddUploadevalution(articleId: string, ReviewerId: string, DocumentType: any, Version: any, Document_path: any) {
    return this._http.post<any>(`${environment.apiUrl}/api/Article/UploadEvaulution`, {
      articleId,
      ReviewerId,
      DocumentType,
      Version,
      Document_path


    })
      .pipe(map(res => {
        return res;
      }));
  }
}
