import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditorComponent } from "./editor.component";
import { QuillModule } from "ngx-quill";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from '../../layout/material/material.module';
import { EditorPopupComponent } from './editor-popup/editor-popup.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { AngularEditorModule } from '@kolkov/angular-editor';
// import { NgxEditorModule } from 'ngx-editor';

@NgModule({
  declarations: [
    EditorComponent,
    EditorPopupComponent
  ],
  imports: [
    CommonModule,
    QuillModule,
    MaterialModule,
    FlexLayoutModule,
    AngularEditorModule,
    // CKEditorModule,
    // NgxEditorModule
  ],
  exports: [
    EditorComponent
  ]
})
export class EditorModule { }
