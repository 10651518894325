import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(private matSnackbar: MatSnackBar) { }

  showNotification(message:string,buttonText:string){
    this.matSnackbar.open(message,buttonText,{
      duration:5000,
      horizontalPosition:"center",
      verticalPosition:"top",
      panelClass:"redNoMatch"
    })
  }
  showNotification2(message:string,buttonText:string){
    this.matSnackbar.open(message,'',{
      duration:5000,
      horizontalPosition:"center",
      verticalPosition:"top",
      panelClass:"redNoMatch2"
    })
  }
  showNotification1(message:string,buttonText:string){
    this.matSnackbar.open(message,buttonText,{
      duration:5000,
      horizontalPosition:"center",
      verticalPosition:"top",
      panelClass:"redNoMatch1"
    })
  }
  paperShowNotification(message: string, action: string) {
    this.matSnackbar.open(message, '', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [ 'redNoMatch3' ]

    });
  }
  withoutButton(message: string, action: string) {
    this.matSnackbar.open(message, '', {
      duration: 1000,
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: [ 'redNoMatch3' ]

    });
  }

}
